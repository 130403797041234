// TODO: remover lista de usuários depois da FASE-2 de testes
export const ALLOWED_USERS = [
	"vitor.rios@zig.fun",
	"david@zig.fun",
	"diego.eis@zig.fun",
	"antonio.chicrala@zig.fun",
	"leandro@zig.fun",
	"fellipe.couto@zig.fun",
	"fernando.martins@zig.fun",
	"daniel.santiago@zig.fun",
	"felipe.keller@zig.fun",
	"vitor.cronemberger@zig.fun",
	"noemia.saionara@zig.fun",
	"filipe.pereira@zig.fun",
	"yuri.santos@zig.fun",
	"thaiane.lobo@zig.fun",
	"karine.mendes@zig.fun",
	"aricia.salvi@zig.fun",
	"celia.oliveira@zig.fun",
	"jessica.souza@zig.fun",
	"livia.alvim@zig.fun",
	"noemia.saionara@zigpay.com.br",
	"alana.moraes@zig.fun",
	"felipe.nascimento@zig.fun",
	"rafaela.lima@zig.fun",
	"matheus.veridiano@zig.fun",
	"priscila.castro@zig.fun",
	"murilo.dantas@zig.fun",
	"kelen.dutra@zig.fun",
	"maria.galvao@zig.fun",
	"lara.freitas@zig.fun",
	"aline.rodrigues@zig.fun",
	"marcela.santos@zig.fun",
	"pedro.lira@zig.fun",
	"danielle.santos@zig.fun",
	"amanda.anastacio@zig.fun",
	"wesley.totti@zig.fun",
	"vitor.borba@zig.fun",
	"caio.lion@zig.fun",
	"felipe.martins@zig.fun",
	"felipe.costa@zig.fun",
	"thiago.perazzo@zig.fun",
	"jonas.silva@zig.fun",
	"renata.soares@zig.fun",
	"jessica.santos@zig.fun",
	"iury.vilar@zig.fun",
	"murilo.dantas@zigpay.com.br",
	"lara.von@zig.fun",
	"vitor.borba@zigpay.com.br",
	"bruno.carvalho@zig.fun",
	"felipe.csantos@zig.fun",
];
