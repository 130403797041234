export const financialReport = {
	fileName: "faturamento-{{event}}",
	finished: {
		end: "Fechado: {{end}}",
		not: "O evento não foi fechado",
	},
	label: "Fechamento",
	reportIn: "Faturamento do evento '{{event}}'",
	tabs: {
		bonusByProduct: {
			title: "Bônus por produto",
			filename: "bonus_por_produto",
			columns: {
				product: "Produto",
				category: "Categoria",
				categoryOfProduct: "Categoria do Produto",
				value: "Bônus recebido no período",
				bonusSpentFromOtherPeriod: "Bônus recebido em outro período",
				total: "Total da categoria",
			},
			cards: {
				totalProductsValue: "Total de bônus em Produtos",
				totalTipValue: "Total de bônus em Serviço",
				totalValue: "Total",
				receivedInPeriod: "Recebido no evento",
				receivedAtAnotherPeriod: "Recebido em outros eventos",
			},
		},
		bonusReport: {
			title: "Bônus por cliente",
			filenameBonus: "bonus-por-cliente",
			filenameAnticipated: "consumacao-antecipada",
			filenameConsumption: "consumacao",
			total: "Total de {{category}}",
			description: "Aqui você pode visualizar todos os bônus dados por funcionários.",
			select: "Selecione uma categoria",
			selectNotFound: "Nenhuma categoria encontrada",
			columns: {
				client: "Funcionário Responsável",
				category: "Categoria",
				date: "Data",
				reason: "Motivo",
				receivedValue: "Recebido",
				spentValue: "Gasto no evento",
				spentValueInOtherEvents: "Gasto em outros eventos",
				givenBy: "Dado por",
				total: "Total",
			},
		},
	},
};
