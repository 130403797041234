export const precharge = {
	addNewPrecharge: {
		aliases: "Aliases",
		beginAt: "Starts at",
		bonus: "Bonus",
		childFriendly: "Suitable for children",
		cover: "Cover",
		darkMode: "Dark Mode",
		endsAt: "Ends in",
		eventName: "Event name",
		expirationDate: "Expiration date of recharges",
		logo: "Logo",
		mainImage: "Main image",
		minimumValue: "Minimum value",
		operationConfig: "Operation settings",
		preSetedValues: "Pre-selectable values",
		prechargeOperation: "Precharge operations",
		primaryColor: "Primary color",
		rechargeConfig: "Recharge settings",
		rechargeDuration: "Recharge duration",
		siteAppearance: "Site appearance",
		siteConfig: "Site settings",
		slug: "Slug",
		termsOfService: "Terms of Service",
		ticketUrl: "Ticket url",
		validRechargDate: "Refills are valid from",
	},
	card: {
		noEnd: "No end",
		noTermsOfService: "No terms of service",
	},
	createNewPrecharge: "Create precharge operation",
	empty: "There are no precharge operations registered",
	noSearchResults: "The search found no results",
	searchPlaceholder: "Search by precharge operation name",
	sideTab: {
		beginAt: "Starts at",
		childFriendly: "Suitable for children",
		darkMode: "Dark Mode",
		durability: "Recharge duration",
		edit: "Edit precharge operation",
		endsAt: "Ends in",
		expiresAt: "Expiration date of recharges",
		preChargeOperation: "Precharge operation",
		primaryColor: "Primary color",
		remove: {
			button: "Delete Precharge Operation",
			title: "Are you sure you want to delete this precharge operation?",
		},
		slug: "SLUG",
		undefined: "Undefined",
		validRecharges: "Recharges are valid from",
		withoutTerms: "No terms of service",
	},
	prechargeStore: {
		createPrechargeOperationSuccess: "Precharge operation created successfully",
		updatePrechargeOperationSuccess: "Precharge operation updated successfully",
	},
	homePage: {
		deleteConfirmation: "Click here to confirm the deletion of the operation",
		title: "Pre-Charge Websites",
		description:
			"Create a website for customers to recharge before the event starts, avoiding queues and consequently reducing the volume of operations at the event.",
		createButton: "Create recharge site",
		searchInputPlaceholder: "Search a site",
		willExpire: "Will expire in",
		hasExpired: "Expired on",
		copyLink: "Copy link to website",
		active: "Active",
		inactive: "Inactive",
		copied: "Link copied.",
	},
	formPage: {
		title: "Create recharge site",
		description:
			"Fill in the data and share the site with your customers. Allow users to recharge before the event starts, avoiding queues and consequently reducing the volume of operations at the event.",
		nextStep: "Next step",
		discard: "Discard creation",
		logoDescription: "The image must be in .jpeg or .png, with 880px by 880px",
		eventData: {
			logo: "Event logo",
			eventName: "Event name",
			eventNamePlaceholder: "Enter event name",
			email: "Contact email",
		},
		customization: {
			slug: "Website URL",
			slugPlaceholder: "Type event name",
			color: "Color of the details",
			rechargesPeriod: "Allow recharges within period of",
			endsAt: "Take the site down on",
			buffer: "Page featured photo",
			mainPageInfoTitle: "Homepage title",
			mainPageInfoTitleDescription:
				'Suggestion: Early recharge? "{{name}}" has! Plan ahead and avoid queues',
			mainPageInfoTitlePlaceholder: "Type here the title you want to place on the page",
			mainPageInfoSubtitle: "Supplementary text to the title",
			colorPlaceholder: "Select color",
		},
		rechargeSettings: {
			rechargesLimit: "Recharge limits",
			rechargesLimitDescription:
				"Establish the minimum amount per recharge and the maximum amount per user (sum of all recharges anticipated during the operation period).",
			minimumValue: "Minimum recharge value",
			maximumValuePerUser: "Maximum recharge value per user (operation period)",
			suggestion: "Recharge value suggestion",
			suggestionDescription:
				"On the recharge site, the user can select one of the values already suggested or enter the value he wants, respecting the minimum and maximum limits established above. Suggest 4 values to be displayed on the site.",
			preSetedValue: "Selectable values",
			bonus: "Bonus assignment",
			bonusDescription:
				"Did you know that you can gift your customers with bonuses? For each recharge value suggested above, you can link a bonus, if you wish.",
			paymentMethods: "Payment methods",
			paymentMethodsDescription:
				"Establish whether the recharge site will accept Pix only, credit card only, or both as a payment method.",
			bonusTable: {
				rechargeValue: "Recharge value",
				bonus: "Bonus",
			},
			addValue: "Add suggested value to website",
			suggestedValue: "Suggested values added",
			removeValue: "click the X to remove",
			noBonus: "No bonus",
		},
		tips: {
			yesOpt: "Yes. Add tips section on website.",
			noOpt: "No. Do not add tips section on site.",
			mainQuestion:
				"Would you like to insert a section with tips for customers to have the best possible experience at your event?",
			importantInfoQuestion:
				"Define important information about your event below, assigning them a title and text that complements it.",
			tipTitle: "Tip {{index}} - Title",
			tipTitlePlaceholder: "Type the tip title {{index}}",
			tipDescription: "Tip {{index}} - Complementary informative text to the title",
			tipDescriptionPlaceholder: "Enter the complementary text of the tip {{index}}",
			addMoreTip: "Add more tips",
			deleteTip: "Delete tip",
		},
		validations: {
			required: "Required field",
			requiredOption: "Select an option",
			invalidSlugRules: "one or more rules were not met",
			maxValuPerUserLowerThanMinValue:
				"Maximum value per user must be greater than minimum value",
			presetedValueLowerThanMinValue: "Recharge value must be greater than minimum value",
			presetedValueHigherThanMaxValuePerUser:
				"Recharge value must be less than maximum value per user",
			presetedValueAlreadyExists: "Recharge value already exists",
		},
	},
	report: {
		precharge: "Precharge",
		edit: "Edit site",
		showSite: "View site",
		costumersTitle: "{{value}} people",
		costumersDescription: "Precharged",
		ticketsDescription: "Average ticket",
		collectedDescription: "Amount collected",
		redeemedDescription: "Amount redeemed",
		maleTitle: "{{value}} men",
		femaleTitle: "{{value}} women",
		otherTitle: "{{value}} others",
		paymentMethod: "Payment method",
		value: "Value",
		prechargePerDay: "Precharges per day",
		date: "Date",
		dateHour: "Date/Time",
		name: "Name",
		document: "Document",
		email: "Email",
		bonus: "Bonus",
		cardSymmary: "Card used",
		cardBrand: "Card Brand",
		filter: {
			name: "Customer name",
			document: "Document",
			email: "Email",
			acquirerTransactionId: "Acquirer transaction",
			paymentMethod: "Payment method",
			status: "Status",
		},
		creditCard: "Credit Card",
		pix: "PIX",
		billing: "Billing",
		status: {
			pendingReview: "Pending",
			refunded: "Refunded",
			refused: "Refused",
			success: "Approved",
		},
		errors: {
			hasWhitespace: "Cannot contain spaces",
			hasUpperCase: "Cannot contain capital letters",
			hasSpecialCharacter: "Cannot contain special characters",
		},
	},
};
