export const personalizationSettings = {
	personalizationSettings: "Set up and customize your menus",
	description:
		"Here you will customize the visual part and configure information to be displayed on the menus. The customization is unique and will apply to all the menus you have.",
	yourActiveProducts: "Your active products",
	onlinePostPayment: "Pay at the table",
	delivery: "Delivery",
	dineIn: "Dine-in order",
	onlineTab: "Online tab",
	viewMenu: "Digital menu",
	title: "Title",
	placeName: "Place name",
	logo: "Logo",
	coverImg: "Cover image",
	assignedImgInYourMenu: "The assigned image will appear on your menu.",
	infoImage:
		"If you do not add an image, the product header will be the logo and place name.",
	recommendations: "Recommendations",
	highQualityImg:
		"We recommend that your logo and cover image be high quality for better application.",
	imgs: "Images",
	primaryColor: "Primary Color",
	colors: "Colors",
	contrastColor: "Contrast Color",
	secondaryColor: "Secondary Color",
	saveCustomization: "Save customization",
	serviceInformation: "Service information",
	deliverySettings: "Delivery settings",
	pickUpAddress: "Pick-up address",
	generateQrCode: "Generate QR Code",
	state: "State",
	select: "Select",
	city: "City",
	enterOrSelect: "Enter or select",
	neighborhood: "Neighborhood",
	neighborhoodName: "Enter neighborhood name",
	address: "Address",
	street: "Street name or avenue",
	number: "Number",
	complement: "Complement",
	typeOfFee: "Type of fee",
	currentHold: "Current hold",
	holdPlaceholder: "15 minutes",
	baseValue: "Base value",
	valuePerkm: "Value per Km",
	visible: "Make visible",
	averageTime: "Average waiting time",
	status: "Status (open or closed)",
	copyOrTypeLink: "Paste or type the link here",
	parameter: "Parameter",
	searchParameter: "Search parameter",
	parameterValue: "Parameter value",
	value: "Value",
	newQrCode: "New QR Code",
	importQrCode: "Import QR Code",
	downloadQrCode: "Download QR Code",
	uniqueFee: "Unique",
	basePlusKm: "Base + Variable per Km",
	perRatio: "Fee per radius",
	addMoreFee: "Add another fee",
	fromTo: "From {{beginning}} to {{end}}",
	time: "{{minutes}} minutes",
	chairNumber: "Chair number",
	tableNumber: "Table number",
	roomNumber: "Room number",
	referencePoint: "Reference point",
	savedSuccessfully: "Updates saved successfully!",
	deliveryInfo: "Request delivery information (optional)",
	selectInfo: "Select the information you need",
	radiusUntil: "Radius up to",
	complementExample: "Ex: Store A",
	chair: "chair",
	table: "table",
	room: "room",
	reference: "reference",
	menu: "Menu",
	selectProduct: "Select product",
	importSheet: "Import spreadsheet to generate QR Codes",
	importQrCodes: "Import QrCodes",
	somethingWentWrong: "Something went wrong",
	couldNotImportSheet:
		"We couldn't import your spreadsheet. Check if it follows the proposed model or ask our support for help.",
	importInstructions:
		"After importing, the QR Codes will be automatically downloaded to your downloads.",
	configSlug: "Configure your slug here",
};
