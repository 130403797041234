export const satisfactionSurvey = {
	customModal: {
		successNotification: "Link da pesquisa copiado",
	},
	formatQuestionType: {
		cleaness: "Limpeza",
		mixes: "Variedade do cardápio",
		music: "Música",
		nps: "NPS",
		price: "Preço",
		product: "Produto",
		service: "Serviço / Atendimento",
		shouldSendSmsOnCheckout:
			"Enviar SMS de NPS automaticamente para todos os clientes após o checkout",
	},
	periodData: {
		custom: "Período personalizado",
		ninety: "Últimos 90 dias",
		period: "Período",
		placeholder: "Selecione o período",
		rangeFormat: "{{sinceDay}} de {{sinceMonth}} à {{untilDay}} de {{untilMonth}}",
		sixty: "Últimos 60 dias",
		thirty: "Últimos 30 dias",
	},
	results: {
		columns: {
			birthDate: "Data de Nascimento",
			client: "Cliente",
			date: "Data",
			document: "Documento",
			averageTicket: "Ticket médio",
			phone: "Telefone",
			ratingDate: "Data da avaliação",
			comment: "Comentário",
		},
		customerAnswer_one: "{{totCustomers}} cliente respondeu a pesquisa de satisfação",
		customerAnswer_other:
			"{{totCustomers}} clientes responderam a pesquisa de satisfação",
		drawer: {
			answerNps:
				"De 1 à 10, a chance desse cliente recomendar {{name}} para amigos e familiares é",
			assessment: "Avaliação",
			consumedProducts: "Produtos consumidos",
			date: "Data",
			event: "Evento",
			stars_one: "{{value}} estrela",
			stars_other: "{{value}} estrelas",
			title: "Detalhes da experiência",
			client: "Cliente",
			cpf: "CPF",
			phone: "Telefone",
			birthDate: "Data de nascimento",
		},
		emptyStateDescription:
			"Esse local não está habilitado para receber respostas da pesquisa de satisfação. Entre em contato com o time da Zig para que você possa ativar esse serviço.",
		emptyStateTitle: "Local não habilitado",
		export: "Exportar para Excel",
		fileTitle: "Histórico de avaliações por evento",
		filename: "avaliacoes-nps",
		filterPlaceholder: "Filtre por casa",
		lastDays_one: "no último {{days}} dia",
		lastDays_other: "nos últimos {{days}} dias",
		popover: {
			answer_one: "{{totalAnswers}} avaliação",
			answer_other: "{{totalAnswers}} avaliações",
			client_one: "{{count}} cliente",
			client_other: "{{count}} clientes",
			opinion_one: "{{count}} opinião",
			opinion_other: "{{count}} opiniões",
		},
		resultsEvent: "Resultado da pesquisa por evento",
		searchClientPlaceholder: "Buscar por nome ou CPF",
	},
	searchSettings: {
		cancelButton: "Cancelar alterações",
		description:
			"Escolha quais são os parâmetros que você deseja que os seus clientes avaliem por casa.",
		emptyState: {
			description:
				"Não foi encontrado local habilitado para configurar a pesquisa de satisfação. Entre em contato com o time da Zig para que você possa ativar esse serviço.",
			title: "Nenhum local habilitado",
		},
		new: "Novo",
		saveButton: "Salvar alterações",
		successNotification: "As configurações de pesquisa foram atualizadas",
		title: "Configurações de pesquisa",
		shouldSendSmsOnCheckout:
			"Enviar SMS de NPS automaticamente para todos os clientes após o checkout",
	},
	shareSettings: {
		content:
			"É muito importante que sejam definidos os meios de ativação do cliente para que você possa ter um feedback justo da sua organização.",
		modal: {
			downloadAllQRCode: "Baixar todos QRCodes",
			link: "Copiar link",
			linkDescription: "Clique para copiar o link da pesquisa",
			qrCode: "Baixar QRCode",
			qrCodeDescription: "Clique aqui para abrir QRCode",
			rowModal: {
				link:
					'Não há nenhum local habilitado. Para gerar um link para pesquisa de satisfação, basta ativá-la na aba "Configurações de pesquisa"',
				qrCode:
					'Não há nenhum local habilitado. Para baixar o QRCode dessa pesquisa, basta ativá-la na aba "Configurações de pesquisa"',
			},
		},
		qrCodeSection: {
			description:
				"Clique no botão abaixo para baixar e imprimir o QR Code que levará o seu cliente ao link da pesquisa de satisfação.",
			downloadButton: "Baixar QR Code",
			title: "QR Code na mesa / salão",
		},
		smsSection: {
			copyButton: "Copiar link de pesquisa",
			description:
				"Copie o link abaixo e envie uma mensagem para a lista de clientes selecionada por você. Basta ir na seção de mensagens na área do cliente.",
			title: "SMS manual",
			cancelUpdates: "Cancelar alterações",
			saveUpdates: "Salvar alterações",
			dailyLimit: {
				label: "Limite de SMS diário",
				tooltip: "O valor cobrado por SMS enviado, consta no seu contrato.",
				description: "Esse será o limite para envios automáticos do link.",
			},
		},
		title: "Configurações de compartilhamento",
		description:
			"Escolha quais são os parâmetros que você deseja que os seus clientes avaliem por casa.",
		cancelButton: "Cancelar alterações",
		saveButton: "Salvar alterações",
		new: "Novo",
		successNotification: "As configurações de pesquisa foram atualizadas",
		shouldSendSmsOnCheckout:
			"Enviar SMS de NPS automaticamente para todos os clientes após o checkout",
	},
	tabs: {
		newPlaceAvailable: "Novo local disponível",
		results: "Resultados",
		searchSettings: "Configurações de pesquisa",
		shareSettings: "Configurações e compartilhamento",
		smsReport: "Relatório de investimento",
	},
	title: "Pesquisa de satisfação",
	smsReport: {
		filterByPlace: "Filtre por local",
		filterByDate: "Data do Evento",
		columns: {
			date: "Data do Evento",
			smsSentCount: "Sms enviados",
			npsAnswersCount: "Respostas",
			conversion: "Taxa de conversão por SMS",
		},
		totalizers: {
			smsSents: "Sms enviados",
			npsFormAnswers: "Total de respostas",
			checkins: "Taxa de conversão total",
		},
	},
};
