export const vendors = {
	title: "Vendors",
	searchNamePlaceholder: "Search by vendor name/login",
	header: {
		create: "Create vendor",
	},
	list: {
		vendors: "Suppliers",
		rent: "Rent",
		revenuePercentageFee: "% on sale",
		tipPercentageFee: "Service charge",
		places: "Places",
		fiscalProfile: "Tax profile",
		login: "Login",
		paymentDaysOffset: "Relay time",
		productsSpecialFee: "Special Fee",
		product: "product",
		products: "products",
		actions: {
			editVendor: "Edit vendor",
			deleteVendor: "Delete vendor",
		},
		place: "Place",
		bars: "Bars",
	},
	modal: {
		close: "Close",
		prevStep: "Previous step",
		nextStep: "Next step",
		steps: {
			dataCollection: "Vendor data",
			barSelection: "Places and bars data",
			feesCalculation: "Fees and tax profile",
			guidance: "Guide",
		},
		delete: {
			title: "Remove vendor",
			description: "Are you sure you want to permanently remove this vendor?",
			buttonConfirm: "Confirm",
		},
		mode: {
			create: "Create new vendor",
			update: "Edit Vendor",
			updateDataCollection: "Edit vendor data",
			updateLinkingBarsAndLocations: "Edit places and bars",
			updateFeesTaxProfile: "Edit rates and tax profile",
		},
		submit: {
			create: "Create vendor",
			update: "Save",
		},
		form: {
			required: "Required field",
			nonnegative: "The value must be greater than or equal to zero",
			name: {
				label: "Name",
				validation: "Name cannot be empty",
				placeholder: "Enter vendor name",
				nonempty: "Name cannot be empty",
			},
			login: {
				label: "Login",
				validation: "Login cannot be empty",
				placeholder: "Enter vendor login",
				nonempty: "Login cannot be empty",
				emailRegexValidation: "Login must not be an email",
				charactersRegexValidation:
					"Login must not contain capital letters and special characters",
			},
			password: {
				label: "Password",
				validation: "Password cannot be empty",
				placeholder: "Enter vendor password",
				required: "Password required",
				nonempty: "Password cannot be empty",
			},
			places: {
				label: "Locations",
				validation: "Select at least one location",
				placeholder: "Select the locations where the vendor will be active",
				selectAll: "Select all",
			},
			bars: {
				label: "Bars",
				validation: "Select at least one bar",
				placeholder: "Select the vendor's bars",
				noBars: "No bars selected",
			},
			dailyRentalFeeValue: {
				label: "Daily rental fee",
			},
			revenuePercentageFee: {
				label: "Revenue percentage fee",
			},
			tipPercentageFee: {
				label: "Tip percentage fee",
			},
			paymentDaysOffset: {
				label: "Payment days offset (D+)",
				maxValidation: "Must be between 0 and {{count}}",
			},
			anticipationFee: {
				label: "Anticipation fee",
			},
			fiscalProfileId: {
				label: "Fiscal profile",
				placeholder: "Select the fiscal profile",
			},
			paymentMethodFees: {
				credit: "Fee on credit payment",
				debit: "Fee on debit payment",
				pix: "Fee on payment in pix",
			},
			productFees: {
				productFee: "Special fee per product",
				productFeePlaceholder: "Search for a product",
				percent: "Percentage",
				button: "Add another product",
			},
		},
		guidance: {
			vendorData: {
				title: "Edit vendor data",
				description: "Name, login and password",
			},
			linkingBarsAndLocations: {
				title: "Edit locations and bars",
				description: "Select vendor locations and their bars",
			},
			feesTaxProfile: {
				title: "Edit fees and tax profile",
				description:
					"Daily fees, revenue percentage fee, tip percentage fee, payment day, and tax profile",
			},
		},
		productModal: {
			title: "Special products and fees",
			product: "Product",
			fee: "Fee",
			closeConfirmTitle: "Discard special rate changes",
			closeConfirmSubtitle:
				'You have made changes and they will only be saved if you click "save changes". Do you want to discard the changes you made or go back to editing rates?',
			closeConfirmCancelActionText: "Return to editing",
			closeConfirmConfirmActionText: "Discard changes",
			save: "Save changes",
			deleteConfirmTitle: "Remove special rate",
			deleteConfirmSubtitle: 'Do you want to remove special rate from "{{value}}"?',
			deleteConfirmBoldSubtitle: "This action cannot be undone",
		},
		modalPermissions: {
			single: {
				edit: "Edit {{vendorName}}",
				withdraw: "Block withdraw",
				anticipation: "Allow anticipation request",
				remove: "Remove vendor",
			},
			multiple: {
				title: "{{count}} selected vendors",
				blockWithdraw: {
					title: "Allow withdraw",
					subtitle: "Block withdraw for {{count}} vendors",
					description:
						"This action will block the withdraw for the vendors below in all places.",
					confirm: "Confirm block withdraw",
				},
				canAnticipate: {
					title: "Allow anticipation",
					subtitle: "Block anticipation for {{count}} vendors",
					description:
						"This action will block the anticipation for the vendors below in all places.",
					confirm: "Confirm block anticipation",
				},
				cancel: "Close",
				applySelection: "Apply selection",
			},
		},
	},
};
