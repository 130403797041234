/* eslint-disable max-len */
import { ConciliationTranslations } from "./conciliation-pt-br";

export const CONCILIATION_FR_FR: ConciliationTranslations = {
	pageTitle: "Conciliation via OFX",
	pageParagraph:
		"En téléchargeant l'extrait de compte de votre compte courant ou épargne au format OFX, disponible auprès des principales banques, et en l'important dans le tableau de bord, vous gagnez du temps dans la gestion financière de votre entreprise.",
	importModal: {
		title: "Importation OFX",
		accountBankNotRegisteredTitle: "Compte bancaire non enregistré",
		triggerButton: "Importer le fichier OFX",
		cancelButton: "Annuler",
	},
	uploadOfxForm: {
		inputInstruction: "Cliquez ou faites glisser le fichier OFX",
		fileType: "Fichier OFX",
		description:
			"Exportez le fichier OFX de votre compte contenant uniquement les transactions pertinentes pour éviter de générer des mouvements involontaires",
		submitButton: "Importer",
		validation: {
			fileRequired: "Le fichier OFX est obligatoire",
			fileSize: "Le fichier OFX doit être d'une taille maximale de 5 Mo",
		},
	},
	submitOfxForm: {
		nameFile: "Nom du fichier :",
		periodImported: "Période importée :",
		periodImportedValue: "{{start}} à {{end}}",
		bankName: "Institution bancaire :",
		labelCurrency: "Solde final au {{date}}",
		labelCheckValue: "Je confirme le montant de {{value}} à la fin de cet extrait",
		labelAccounts: "Sélectionnez un compte :",
		placeholderAccount: "Cliquez pour sélectionner",
		accountRequired: "Sélectionnez un compte",
		submitButton: "Confirmer le montant",
		alerts: {
			accountNotCreated: {
				title: "Compte bancaire introuvable",
				description:
					"Nous ne pouvons pas trouver cette compte dans nos données. <a>Cliquez ici</a> pour enregistrer <b>ou sélectionnez une compte spécifiée ci-dessous</b> pour continuer avec l'importation de l'OFX.",
			},
			accountNotValidated: {
				title: "Ce compte n'a pas encore été validé.",
				description:
					"Ce compte bancaire a été enregistré sous le nom {{name}} mais ses données n'ont pas encore été validées. Nous utiliserons les données exportées dans cet OFX pour effectuer la validation automatique.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Importation réussie",
			itemsImported:
				"{{send}} transactions ont été importées, dont {{new}} nouvelles transactions",
			balance: "Solde final de {{balance}} au {{date}}",
		},
		error: {
			title: "Oups... Quelque chose s'est mal passé",
			description: "Nous n'avons pas pu importer l'OFX. Veuillez réessayer plus tard.",
		},
		confirmButton: "Rapprocher les transactions",
	},
	redirectBankFlow: {
		description:
			"L'enregistrement du compte bancaire est une action nécessaire avant d'importer l'OFX.",
		redirectButton: "Enregistrer le compte",
	},
	tabs: {
		pendingConciliations: "Conciliations en attente",
		history: "Historique",
		description: {
			pendingConciliations: "Détails des importations",
			history: "Historique des conciliations",
		},
		pendingTab: {
			emptyState: {
				title: "Aucune réconciliation en attente pour le moment",
				description:
					"Importez un nouveau fichier OFX pour réconcilier de nouvelles transactions",
			},
		},
		historyTab: {
			emptyState: {
				title: "Aucune réconciliation trouvée",
			},
		},
	},
	collapse: {
		branch: "Agence",
		account: "Compte Courant",
		importedDate: "Période Importée",
		importedDateDescription: "De {{since}} à {{until}}",
		conciliationDate: "Date de Rapprochement",
		actions: {
			undoConciliation: "Annuler le Rapprochement",
		},
		unConciliatedAmount: "{{amount}} non rapprochées",
		item: {
			value: "Valeur",
			date: "Date",
			description: "Description",
			chartOfAccount: "Plan Comptable",
			paymentMethod: "Méthode de Paiement",
			status: {
				conciliated: "Rapprochée",
				refused: "Ignorée",
			},
			tooltip: {
				search: "Rechercher l'écriture",
				addAccount: "Ajouter un compte",
				ignoreTransaction: "Ignorer la transaction",
			},
			accountType: {
				CheckingAccount: "Compte Courant",
				SavingsAccount: "Compte Epargne",
			},
			paymentTypes: {
				CREDIT: "Crédit",
				DEBIT: "Débit",
				INT: "INT",
				DIV: "DIV",
				FEE: "Frais",
				SRVCHG: "Frais de service",
				DEP: "Dépôt",
				ATM: "DAB",
				POS: "TPE",
				XFER: "Virement",
				CHECK: "Chèque",
				PAYMENT: "Paiement",
				CASH: "Espèces",
				DIRECTDEP: "Dépôt direct",
				DIRECTDEBIT: "Prélèvement automatique",
				REPEATPMT: "Paiement récurrent",
				OTHER: "Autres",
			},
		},
		cancel: "Annuler",
		complete: "Conclure le Rapprochement",
	},
	transactionSuccessNotification: "Transaction mise à jour avec succès !",
	cancelButton: "Annuler",
	confirmButton: "Confirmer",
	conciliateConciliation: {
		title: "Conciliations en attente",
		description:
			"Une fois la conciliation terminée, les transactions ignorées seront <b>supprimées définitivement</b> de vos conciliations. Voulez-vous continuer?",
		error: "Vous avez encore des transactions en attente!",
	},
	ignoreTransactionModal: {
		title: "Ignorer la Transaction",
		description: "Êtes-vous sûr de vouloir ignorer la transaction <b>{{name}}</b>?",
	},
	undoIgnoreTransactionModal: {
		button: "Annuler l'ignorance",
		title: "Annuler l'Ignorance",
		description:
			"Êtes-vous sûr de vouloir annuler l'ignorance de la transaction <b>{{name}}</b>?",
	},
	undoConciliationTransactionModal: {
		button: "Annuler la réconciliation",
		title: "Annuler la Réconciliation",
		description:
			"Êtes-vous sûr de vouloir annuler la réconciliation de la transaction <b>{{name}}</b>?",
	},
	conciliationModal: {
		title: "Rechercher une opération",
		contentTitle: "Informations de l'importation",
		labelValue: "Valeur",
		labelDate: "Date",
		labelDescription: "Description",
		labelChartAccount: "Plan de compte",
		labelPaymentMethod: "Mode de paiement",
		buttonCancel: "Annuler",
		buttonConfirm: "Concilier",
		table: {
			emptyIsFetched: "Aucun compte trouvé",
			emptyInitial: {
				title: "Utilisez les recherches pour commencer",
				subtitle:
					"Recherchez et sélectionnez une opération pour réaliser la conciliation de la transaction ci-dessous.",
			},
		},
	},
};
