/* eslint-disable max-len */
import { ConciliationTranslations } from "./conciliation-pt-br";

export const CONCILIATION_EN_US: ConciliationTranslations = {
	pageTitle: "Conciliation via OFX",
	pageParagraph:
		"When you download the statement of your checking or savings account in OFX format, available from major banks, and import it into the dashboard, you save time managing your business finances.",
	importModal: {
		title: "OFX Import",
		accountBankNotRegisteredTitle: "Bank account not registered",
		triggerButton: "Import OFX file",
		cancelButton: "Cancel",
	},
	uploadOfxForm: {
		inputInstruction: "Click or drag the OFX file",
		fileType: "OFX File",
		description:
			"Export the OFX file from your account containing only the relevant transactions to avoid generating unintended movements",
		submitButton: "Import",
		validation: {
			fileRequired: "OFX file is required",
			fileSize: "OFX file must be no larger than 5 MB",
		},
	},
	submitOfxForm: {
		nameFile: "File name:",
		periodImported: "Imported period:",
		periodImportedValue: "{{start}} to {{end}}",
		bankName: "Bank institution:",
		labelCurrency: "Final balance on {{date}}",
		labelCheckValue: "I confirm the amount of {{value}} at the end of this statement",
		labelAccounts: "Select an account:",
		placeholderAccount: "Click to select",
		accountRequired: "Select an account",
		submitButton: "Confirm amount",
		alerts: {
			accountNotCreated: {
				title: "Bank account not found",
				description:
					"We could not find this account in our database. <a>Click here</a> to register <b>or select a different account</b> to proceed with the OFX import.",
			},
			accountNotValidated: {
				title: "This account has not been validated yet.",
				description:
					"This bank account was registered with the name {{name}} but its data has not been validated yet. We will use the data exported in this OFX to perform the automatic validation.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Import successful",
			itemsImported: "{{send}} transactions were imported, with {{new}} new transactions",
			balance: "Final balance of {{balance}} on {{date}}",
		},
		error: {
			title: "Oops... Something didn't go as planned",
			description: "We were unable to import the OFX. Please try again later.",
		},
		confirmButton: "Reconcile transactions",
	},
	redirectBankFlow: {
		description:
			"Registering the bank account is a necessary action before importing the OFX.",
		redirectButton: "Register account",
	},
	tabs: {
		pendingConciliations: "Pending conciliations",
		history: "History",
		description: {
			pendingConciliations: "Import Details",
			history: "Conciliation History",
		},
		pendingTab: {
			emptyState: {
				title: "There are no pending reconciliations at the moment",
				description: "Import a new OFX file to reconcile new transactions",
			},
		},
		historyTab: {
			emptyState: {
				title: "No reconciliations found",
			},
		},
	},
	collapse: {
		branch: "Branch",
		account: "Account",
		importedDate: "Imported Period",
		importedDateDescription: "From {{since}} to {{until}}",
		conciliationDate: "Conciliation Date",
		actions: {
			undoConciliation: "Undo conciliation",
		},
		unConciliatedAmount: "{{amount}} unconciled",
		item: {
			value: "Value",
			date: "Date",
			description: "Description",
			chartOfAccount: "Chart of Accounts",
			paymentMethod: "Payment Method",
			status: {
				conciliated: "Conciled",
				refused: "Ignored",
			},
			tooltip: {
				search: "Search transaction",
				addAccount: "Add an account",
				ignoreTransaction: "Ignore transaction",
			},
			accountType: {
				CheckingAccount: "Checking Account",
				SavingsAccount: "Savings Account",
			},
			paymentTypes: {
				CREDIT: "Credit",
				DEBIT: "Debit",
				INT: "INT",
				DIV: "DIV",
				FEE: "Fee",
				SRVCHG: "Service Charge",
				DEP: "Deposit",
				ATM: "ATM",
				POS: "POS",
				XFER: "Transfer",
				CHECK: "Check",
				PAYMENT: "Payment",
				CASH: "Cash",
				DIRECTDEP: "Direct Deposit",
				DIRECTDEBIT: "Direct Debit",
				REPEATPMT: "Repeat Payment",
				OTHER: "Other",
			},
		},
		cancel: "Cancel",
		complete: "Complete conciliation",
	},
	transactionSuccessNotification: "Transaction updated successfully!",
	cancelButton: "Cancel",
	confirmButton: "Confirm",
	conciliateConciliation: {
		title: "Conciliate",
		description:
			"Once the conciliation is completed, the ignored transactions will be <b>permanently deleted</b> from your conciliation records. Do you still want to continue?",
		error: "You still have pending transactions!",
	},
	ignoreTransactionModal: {
		title: "Ignore Transaction",
		description: "Are you sure you want to ignore the transaction <b>{{name}}</b>?",
	},
	undoIgnoreTransactionModal: {
		button: "Undo ignore",
		title: "Undo Ignore",
		description:
			"Are you sure you want to undo ignoring the transaction <b>{{name}}</b>?",
	},
	undoConciliationTransactionModal: {
		button: "Undo reconciliation",
		title: "Undo Reconciliation",
		description:
			"Are you sure you want to undo the reconciliation of the transaction <b>{{name}}</b>?",
	},
	conciliationModal: {
		title: "Search transaction",
		contentTitle: "Import information",
		labelValue: "Value",
		labelDate: "Date",
		labelDescription: "Description",
		labelChartAccount: "Chart of accounts",
		labelPaymentMethod: "Payment method",
		buttonCancel: "Cancel",
		buttonConfirm: "Conciliate",
		table: {
			emptyIsFetched: "No account found",
			emptyInitial: {
				title: "Use the search to start",
				subtitle: "Search and select a transaction to conciliate the transaction below.",
			},
		},
	},
};
