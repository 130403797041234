export const transactionsReport = {
	title: "Lista de Transações",
	descriptionPlace: "Lista de todas as transações do período",
	descriptionEvent: "Lista de todas as transações do evento",
	filter: {
		title: "Filtrar",
		emptyFilter: "Clique para filtrar",
		modal: {
			inputPlaceholder: "Digite aqui",
			selectPlaceholder: "Selecione aqui",
			descriptionEnd: "para filtrar as transações",
		},
		modalOptions: {
			terminal: {
				label: "Terminal",
				title: "Número do terminal",
				description: "Insira o número do terminal",
			},
			clientDocument: {
				label: "Documento do cliente",
				title: "CPF ou número de documento",
				description: "Insira o CPF ou número de documento do cliente",
			},
			employee: {
				label: "Funcionário",
				title: "Nome do funcionário",
				description: "Insira o nome do funcionário",
			},
			zigCode: {
				label: "ZigCode",
				description: "Insira o número do ZigCode",
			},
			paymentMethod: {
				label: "Forma de pagamento",
				description: "Selecione uma forma de pagamento",
			},
			transactionType: {
				label: "Tipo de transação",
				description: "Selecione um tipo de transação",
			},
			bar: {
				label: "Filtro por bar",
				title: "Bar",
				description: "Selecione um bar",
			},
		},
	},
	print: "Imprimir relatório",
	table: {
		id: "ID",
		date: "Data",
		syncDate: "Data da sincronização",
		type: "Tipo",
		employee: "Funcionário",
		terminal: "Terminal",
		deviceId: "Device ID",
		zigCode: "Zig Code",
		bar: "Bar",
		client: "Cliente",
		product: "Produto",
		quantity: "Quantidade",
		source: "Source",
		paymentMethod: "Forma de pagamento",
		status: "Status",
		document: "Documento",
		acquirer: "Adquirente",
		acquirerTransactionId: "ID da transação de adquirência",
		cardBrand: "Bandeira do Cartão",
		nsu: "NSU",
		nsuHost: "NSU Host",
		authorizationCode: "Código Autorização",
		bin: "Bin",
		service: "Serviço",
		value: "Valor",
		hawkerDocument: "Documento Ambulante",
		hawkerName: "Nome Ambulante",
	},
	transactionTypes: {
		recharge: "Recarga",
		transaction: "Transação",
		ficha: "Ficha",
		canceled: "Cancelada",
		refund: "Reembolso",
	},
	maxSearchPeriod: "O período máximo de busca é de 31 dias",
};
