import { consolidated } from "./consolidated";
import { financial } from "./financial";
import { withdraws } from "./withdraws";
import { invoices } from "./invoices";
import { devolution } from "./devolution";
import { adjustments } from "./adjustments";
import { accounts } from "./accounts";
import { flow } from "./flow";
import { anticipationDrawer } from "./anticipation-drawer";
import { withdrawRequestDrawer } from "./withdraw-request-drawer";
import { errorsValidations } from "./errors-validations";
import { transferForm } from "./transfer-form";
import { withdrawConfirmation } from "./withdraw-confirmation";
import { withdrawRequestDetails } from "#i18n/translations/en-US/place/extract/withdraw-request-details";
import { debitNotes } from "./debit-notes";
import { locationNotes } from "./location-notes";

export const extract = {
	accounts,
	availableToWithdraw: "Available for withdrawal",
	actionLabelAnticipate: "Request anticipation and bill payment",
	actionLabelBlocked: "Withdraw / Pay bill",
	actionLabelWithdraw: "Request a withdraw, payment of bill or transfer",
	adjustments,
	anticipationDrawer,
	availableInMoreThanOneMonth: "Amount to be released in more than 32 days",
	availableInTwoDays: "Balance to be released in the next 2 days",
	availableMoreThanOneMonth: "Value available over 30 days",
	owedToZig: "Balance due to ZIG",
	totalBalance: "Total balance",
	vendorOwned: "Due to suppliers",
	vendors: "Vendors",
	totalBalanceAnticipation: "Total balance for anticipation",
	valueAvailableToAnticipate: "Value available for anticipation",
	availableToAnticipate: "Available to anticipate",
	withdrawRequestDrawer,
	errorsValidations,
	transferForm,
	withdrawConfirmation,
	bankSlipForm: {
		error: "The request could not be completed.",
		request: "Request",
		success: "Payment of the ticket requested successfully!",
		tryAgain: "Try again",
		warningBanner: {
			description:
				"Requests made after 10:30 am (Brasília time) will only be answered the next day.",
			title: "Attention",
		},
	},
	bankslipAvailable: "Bill available",
	consolidated,
	devolution,
	dueDate_Abrev: "Exp",
	excelButton: "Export to Excel",
	financial,
	flow,
	hooks: {
		accountData: "Account Information",
		summary: "Data review",
		value: "Anticipation Value",
	},
	invoices,
	lastUpdated: "updated {{lastUpdated}} ago",
	paidToZig: "Amount to be paid to ZIG",
	paidToZigMoreThanOneMonth: "Amount to be paid to ZIG in more than 30 days",
	period: "Period",
	tabs: {
		accounts: "Saved accounts",
		adjustments: "Settings",
		consolidated: "Consolidated",
		devolution: "Expired recharges",
		financial: "Daily statement",
		flow: "Cash Flow Extract",
		withdraws: "Withdrawals, advances and bankslips",
		invoices: "Invoices",
		debitNotes: "Debit notes",
		locationNotes: "Location Notes",
		automaticWithdraw: "Automatic withdraw",
	},
	title: "Extract",
	withdrawRequestDetails,
	withdraws,
	debitNotes,
	locationNotes,
	retainedBalance: "Retained balance",
	twentyPercentRetention: "20% retention of values ​​from the last 7 days",
	totalRetained: "Total retained",
	relatedPartDataTooltip: "Data specified from 21/05/2024",
	automaticWithdraw: {
		type: "Type",
		vendorName: "Vendor Name",
		expectedAmount: "Scheduled Withdraw Amount",
		placeBalance: "Place Balance",
		actualAmount: "Withdrawn Amount",
		status: "Status",
		vendor: "Vendor",
		place: "Place",
		error: "Error",
		success: "Success",
		partial: "Partial",
	},
};
