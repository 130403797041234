export const productsTab = {
	addEditProductModal: {
		add: "Adicionar produto",
		edit: "Editar produto",
		form: {
			category: {
				error: "Escolha ou crie uma categoria",
				info: "Você estará criando uma nova categoria",
				label: "Categoria",
				placeholder: "Selecione ou crie uma categoria",
			},
			code: {
				label: "SKU",
				placeholder: "Digite um SKU",
				tooltip: "Caso não seja preenchido, o código será gerado automaticamente",
			},
			error: "Esse campo é obrigatório",
			hasAlcohol: "Contém álcool?",
			name: {
				error: "Use apenas caracteres válidos",
				label: "Nome do Produto",
				placeholder: "Digite um nome para o produto",
			},
			ncm: {
				error: "O NCM deve ter 8 dígitos",
				label: "NCM",
				placeholder: "Digite um NCM",
			},
			photo: "Foto",
			type: {
				label: "Tipo",
				placeholder: "Selecione um tipo de produto",
			},
		},
		unavailable: "Indisponível",
		undefined: "Indefinido",
		update: "Atualizar produto",
	},
	exportProducts: {
		products: "Produtos",
		warningNotification: "Produtos não modificados",
		columns: {
			id: "Id do produto",
			title: "Nome",
			category: "Categoria",
			places: "Locais",
			fiscalNcm: "NCM",
			fiscalCode: "SKU",
			kind: "Tipo de produto",
			type: "Tipo",
			hasAlcohol: "Contém álcool?",
			description: "Descrição",
		},
	},
	header: {
		add: "Adicionar produto",
		addPlaces: "Adicionar locais",
		counter: "{{amount}} Produtos",
		editPlaces: "Editar locais",
		export: "Exportar produtos",
		inventory: "Alterar inventário",
		search: "Pesquisar",
		deleteProduct: "Excluir (1) produto",
		deleteMultipleProducts: "Excluir ({{products}}) produtos",
	},
	inventoryUpdate: {
		columns: {
			category: "Categoria",
			fiscalCode: "SKU",
			fiscalNcm: "Fiscal - Ncm",
			hasAlcohol: "Contém álcool?",
			id: "Id do produto",
			name: "Nome",
			type: "Tipo do produto",
		},
		download: "Baixar planilha",
		import: "Importar planilha",
		label: "Alterar inventório",
	},
	label: "Cadastro Base de Produtos",
	description: "Lista de produtos cadastrados na organização e suas informações basais.",
	productForm: {
		alcoholLabel: "Contém álcool?",
		categoryLabel: "Categoria",
		categoryPlaceholder: "Digite a categoria do produto",
		createProduct: "Criar produto de inventário",
		editProduct: "Editar produto de inventário",
		fiscalCodelabel: "SKU",
		fiscalCodePlacehodler: "Digite o código SKU do produto",
		ncmLabel: "NCM",
		ncmPlaceholder: "Digite o código NCM do produto",
		photolabel: "Foto",
		productNameLabel: "Nome do produto",
		productNamePlaceholder: "Digite o nome do produto",
		productTypelabel: "Tipo",
		productTypePlaceholder: "Selecione o tipo do produto",
		placesLabel: "Locais",
		placesPlaceholder: "Selecione os locais do produto",
		price: "Preço",
		priceTooltip:
			"A mudança do preço neste campo altera o preço do produto para todos os places vinculados",
		editPrice: "Alterar preço",
		saveEditProduct: "Salvar alterações",
		saveProduct: "Salvar produto",
		confirmProductPrice: {
			goBack: "Voltar para edição",
			confirm: "Confirmar alteração",
			title: "Confirmar ajuste",
			description: {
				title: "Deseja confirmar a alteração de preço?",
				subtitle:
					"Os places vinculados passarão a ter o novo valor de <b>{{productValue}}</b> para o produto <b>{{productName}}.</b>",
			},
		},
	},
	removeProduct: {
		label: "Remover produto",
		title: "Deseja realmente remover o produto?",
	},
	rows: {
		noProducts: "Sem produtos",
		places: "Locais",
		products: "Produtos",
		type: "Tipo",
		fiscalCode: "SKU",
	},
	selectPlaces: {
		label: "Selecionar locais",
		search: "Pesquisar locais",
	},
	types: {
		product: "Produto",
		supply: "Insumo",
	},
	modalDelete: {
		title: "Excluir produto",
		description:
			"Ao confirmar a ação você exclui permanentemente o produto. Deseja continuar?",
	},
	modalDeleteMultipleProducts: {
		title: "Excluir produtos",
		description:
			"Ao remover os produtos selecionados eles serão excluídos de todos os locais, e não será possível reverter essa ação.",
		confirm: "Deseja excluir mesmo assim?",
	},
	link: "Vínculo",
	links: "Vínculos",
	productPlacesModal: {
		title: "Vínculos do produto",
		description: "Relação de locais onde o produto está presente",
		seePlace: "Ver local",
	},
};
