import { isDev } from "#configuration/config";
import { useStores } from "#stores";
import { ALLOWED_USERS } from "./constants";

export const useModularization = () => {
	const { organizationStore, authStore } = useStores();
	const hasModularizationOrgFeature = organizationStore.organization?.features.some(
		feature => feature.id === "modularization",
	);

	const isAllowedUser =
		authStore.currentEmployee?.username &&
		ALLOWED_USERS.some(email => authStore.currentEmployee?.username.includes(email));

	// TODO: remover solução temporária para uso em testes no ambiente dev
	const forceMenuV1 = localStorage.getItem("menu-v1");

	const showMenuV2 = isDev
		? isDev && !forceMenuV1
		: Boolean(hasModularizationOrgFeature || isAllowedUser);

	return {
		showMenuV2,
	};
};
