import {
	FiscalProductGroupVatVendusDetailsCode,
	FiscalProductGroupVatVendusDetailsProductType,
	FiscalProductGroupVatVendusDetailsTaxExemption,
	IssueResult,
} from "#api/enterprise-generated";
import { debounce } from "lodash-es";
import { parallelLimit } from "./async";
import enterprise from "#resources/api/enterprise-client";

const commonCsosn = {
	"102": "Tributada pelo Simples Nacional sem permissão de crédito",
	"300": "Imune",
	"400": "Não tributada pelo Simples Nacional",
	"500":
		"ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
	"900": "Outros",
};

export const satCsosn = {
	...commonCsosn,
};

export const nfceCsosn = {
	...commonCsosn,
	"101": "Tributada pelo Simples Nacional com permissão de crédito",
	"103": "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
	"201":
		"Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
	"202":
		"Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
	"203":
		"Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
};

enum BaseCalculo {
	MVA = "0",
	Pauta = "1",
	Tabelado = "2",
	Operacao = "3",
}

export const baseCalculo = {
	[BaseCalculo.MVA]: "Margem Valor Agregado (%)",
	[BaseCalculo.Pauta]: "Pauta (R$)",
	[BaseCalculo.Tabelado]: "Preço Tabelado Máximo (R$)",
	[BaseCalculo.Operacao]: "Valor da Operação",
};

export const cstIcms: Record<string, string> = {
	"00": "Tributada integralmente",
	"10": "Tributada e com cobrança do ICMS por substituição tributária",
	"101": "Tributada pelo Simples Nacional com permissão de crédito",
	"102": "Tributada pelo Simples Nacional sem permissão de crédito",
	"103": "Isenção do ICMS no Simples Nacional para faixa de receita bruta",
	"20": "Tributada Com redução de base de cálculo",
	"201":
		"Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária",
	"202":
		"Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária",
	"203":
		"Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária",
	"30": "Isenta ou não tributada e com cobrança do ICMS por substituição tributária",
	"300": "Imune",
	"40": "Isenta",
	"400": "Não tributada pelo Simples Nacional",
	"41": "Não tributada",
	"50": "Suspensão",
	"500":
		"ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação",
	"51":
		"Diferimento (a exigência do preenchimento das informações do ICMS diferido fica a critério de cada UF)",
	"60": "ICMS cobrado na operação anterior por Substituição Tributária",
	"70":
		"Tributada com redução de base de cálculo e com cobrança do ICMS por substituição tributária",
	"90": "Outras operações ",
	"900": "Outras (regime Simples Nacional)",
};

export const cstPisAndCofins: Record<string, string> = {
	"01":
		"Operação tributável: base de cálculo = valor da operação (alíquota normal: cumulativo/não cumulativo)",
	"02":
		"Operação tributável: base de cálculo = valor da operação (alíquota diferenciada)",
	"03":
		"Operação tributável: base de cálculo = quantidade vendida X alíquota por unidade de produto",
	"04": "Operação tributável: tributação monofásica (alíquota zero)",
	"05": "Operação tributável: substituição tributária",
	"06": "Operação tributável: alíquota zero",
	"07": "Operação isenta da contribuição",
	"08": "Operação sem incidência da contribuição",
	"09": "Operação com suspensão da contribuição",
	"49": "Outras operações de saída",
	"50":
		"Operação com direito a crédito: vinculada exclusivamente a receita tributada no mercado interno",
	"51":
		"Operação com direito a crédito: vinculada exclusivamente a receita não tributada no mercado interno",
	"52":
		"Operação com direito a crédito: vinculada exclusivamente a receita de exportação",
	"53":
		"Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno",
	"54":
		"Operação com direito a crédito: vinculada a receitas tributadas no mercado interno e de exportação",
	"55":
		"Operação com direito a crédito: vinculada a receitas não-tributadas no mercado interno e de exportação",
	"56":
		"Operação com direito a crédito: vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação",
	"60":
		"Orédito presumido: operação de aquisição vinculada exclusivamente a receita tributada no mercado interno",
	"61":
		"Crédito presumido: operação de aquisição vinculada exclusivamente a receita não-tributada no mercado interno",
	"62":
		"Crédito presumido: operação de aquisição vinculada exclusivamente a receita de exportação",
	"63":
		"Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno",
	"64":
		"Crédito presumido: operação de aquisição vinculada a receitas tributadas no mercado interno e de exportação",
	"65":
		"Crédito presumido: operação de aquisição vinculada a receitas não-tributadas no mercado interno e de exportação",
	"66":
		"Crédito presumido: operação de aquisição vinculada a receitas tributadas e não-tributadas no mercado interno e de exportação",
	"67": "Crédito presumido: outras operações",
	"70": "Operação de aquisição sem direito a crédito",
	"71": "Operação de aquisição com isenção",
	"72": "Operação de aquisição com suspensão",
	"73": "Operação de aquisição a alíquota zero",
	"74": "Operação de aquisição sem incidência da contribuição",
	"75": "Operação de aquisição por substituição tributária",
	"98": "Outras operações de entrada",
	"99": "Outras operações",
};

export const cfop = {
	5101: "Venda de produção do estabelecimento",
	5102: "Venda de mercadoria adquirida ou recebida de terceiros",
	5401: "Venda de produção do estabelecimento em operação com produto sujeito ao regime de substituição tributária, na condição de contribuinte substituto",
	5403: "Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituto",
	5405: "Venda de mercadoria adquirida ou recebida de terceiros em operação com mercadoria sujeita ao regime de substituição tributária, na condição de contribuinte substituído",
	5933: "Prestação de serviço tributado pelo Imposto Sobre Serviços de Qualquer Natureza",
	5949: "Outra saída de mercadoria ou prestação de serviço não especificado",
	6104: "Venda de mercadoria adquirida ou recebida de terceiros, efetuada fora do estabelecimento",
};

export const icmsOrigem: GlobalTypes.CodeLabelDictionary = {
	"0": "Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8",
	"1": "Estrangeira - Importação direta, exceto a indicada no código 6",
	"2": "Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
	"3":
		"Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%",
	"4":
		"Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes",
	"5": "Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%",
	"6":
		"Estrangeira - Importação direta, sem similar nacional, constante em lista da CAMEX",
	"7":
		"Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista da CAMEX",
	"8": "Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%",
};

export const vatVendusCodeOptions: Record<
	FiscalProductGroupVatVendusDetailsCode,
	string
> = {
	INT: "Taxa intermediária",
	ISE: "Isento",
	NOR: "Taxa normal",
	RED: "Taxa reduzida",
};

export const vatVendusProductTypeOptions: Record<
	FiscalProductGroupVatVendusDetailsProductType,
	string
> = {
	E: "Imposto especial de consumo (IABA, ISP e IT)",
	I: "Imposto (excepto IVA e IS) ou Encargo Parafiscal",
	O: "Outro (portes, adiantamentos, etc.)",
	P: "Produto",
	S: "Serviço",
};

export const vatVendusTaxExemptionOptions: Record<
	FiscalProductGroupVatVendusDetailsTaxExemption,
	string
> = {
	M01: "Artigo 16.º, n.º 6 do CIVA ou similar",
	M02: "Artigo 6.º do decreto-Lei n.º 198/90, de 19 de Junho",
	M03: "Exigibilidade de caixa",
	M04: "Artigo 13.º do CIVA ou similar",
	M05: "Artigo 14.º do CIVA ou similar",
	M06: "Artigo 15.º do CIVA ou similar",
	M07: "Artigo 9.º do CIVA ou similar",
	M08: "IVA (autoliquidação)",
	M09: "IVA (não confere direito a dedução)",
	M10: "IVA (regime de isenção: artigo 53.º do CIVA)",
	M11: "Regime particular do tabaco",
	M12: "Regime da margem de lucro agências de viagens",
	M13: "Regime da margem de lucro bens em segunda mão",
	M14: "Regime da margem de lucro objetos de arte",
	M15: "Regime da margem de lucro objetos de coleção e antiguidades",
	M16: "Artigo 14.º do RITI ou similar",
	M99: "Não sujeito; não tributado ou similar",
};

type UF = "DF" | "GO" | "PR" | "RS" | "RJ" | "MT" | "MG";
export const benefitCodePerState: Record<UF, Record<string, string>> = {
	DF: {
		DF810001: "Constituição Federal, art. 155, § 2º, inc. X, 'd'- Imunidade do ICMS",
		DF811001: "Lei nº 1.254/96, art. 3º, I - Não-incidência do ICMS",
		DF811002: "Lei nº 1.254/96, art. 3º, II - Não-incidência do ICMS",
		DF811003: "Lei nº 1.254/96, art. 3º, III - Não-incidência do ICMS",
		DF811004: "Lei nº 1.254/96, art. 3º, IV - Não-incidência do ICMS",
		DF811005: "Lei nº 1.254/96, art. 3º, V - Não-incidência do ICMS",
		DF811006: "Lei nº 1.254/96, art. 3º, VI - Não-incidência do ICMS",
		DF811007: "Lei nº 1.254/96, art. 3º, VII - Não-incidência do ICMS",
		DF811008: "Lei nº 1.254/96, art. 3º, VIII - Não-incidência do ICMS",
		DF811009: "Lei nº 1.254/96, art. 3º, IX - Não-incidência do ICMS",
		DF811010: "Lei nº 1.254/96, art. 3º, X - Não-incidência do ICMS",
		DF811011: "Lei nº 1.254/96, art. 3º, XI - Não-incidência do ICMS",
		DF814001:
			"Convênio ICMS/CONFAZ 84/90, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 1 - Isenção do ICMS",
		DF814002:
			"Convênio ICMS/CONFAZ 27/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 2 - Isenção do ICMS",
		DF814003:
			"Convênio ICMS/CONFAZ 08/89, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 3 - Isenção do ICMS",
		DF814004:
			"Convênio ICM 26/75, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 4 - Isenção do ICMS",
		DF814005:
			"Convênio ICMS/CONFAZ 27/90, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 5 - Isenção do ICMS",
		DF814006:
			"Convênio ICM 33/77, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 6 - Isenção do ICMS",
		DF814007:
			"Convênio ICM 5/72, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 7 - Isenção do ICMS",
		DF814009:
			"Convênio ICMS/CONFAZ 20/89, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 9 - Isenção do ICMS",
		DF814010:
			"Convênio ICMS/CONFAZ 76/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 10 - Isenção do ICMS",
		DF814011:
			"Convênio ICMS/CONFAZ 38/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 11 - Isenção do ICMS",
		DF814012:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 12 - Isenção do ICMS",
		DF814013:
			"Convênio ICM 1/75, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 13 - Isenção do ICMS",
		DF814014:
			"Convênio ICM 44/75, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 14 - Isenção do ICMS",
		DF814015:
			"Convênio ICMS/CONFAZ 44/75, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 15 - Isenção do ICMS",
		DF814016:
			"Convênio ICMS/CONFAZ 32/75, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 16 - Isenção do ICMS",
		DF814017:
			"Convênio ICMS/CONFAZ 70/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 17 - Isenção do ICMS",
		DF814018:
			"Convênio ICMS/CONFAZ 25/83, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 18 - Isenção do ICMS",
		DF814019:
			"Convênio ICMS/CONFAZ 70/90, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 19 - Isenção do ICMS",
		DF814020:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 20 - Isenção do ICMS",
		DF814021:
			"V Convênio do Rio de Janeiro de 1967, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 21 - Isenção do ICMS",
		DF814022:
			"I Convênio do Rio de Janeiro de 1967, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 22 -	Isenção do ICMS",
		DF814023:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 23 - Isenção do ICMS",
		DF814024:
			"Convênio ICMS/CONFAZ 78/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 24 - Isenção do ICMS",
		DF814025:
			"Convênio ICMS/CONFAZ 55/89, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 25 - Isenção do ICMS",
		DF814026:
			"Convênio ICMS/CONFAZ 85/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 26 - Isenção do ICMS",
		DF814027:
			"Convênio ICMS/CONFAZ 57/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 27 - Isenção do ICMS",
		DF814028:
			"Convênio ICMS/CONFAZ 29/90, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 28 - Isenção do ICMS",
		DF814029:
			"Convênio ICMS/CONFAZ 59/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 29 - Isenção do ICMS",
		DF814030:
			"Convênio ICMS/CONFAZ 03/90, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 30 - Isenção do ICMS",
		DF814031:
			"Convênio ICM 40/75, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 31 - Isenção do ICMS",
		DF814032:
			"Convênio ICMS/CONFAZ 41/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 32 - Isenção do ICMS",
		DF814033:
			"Convênio ICMS/CONFAZ 20/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 33 - Isenção do ICMS",
		DF814034:
			"Convênio ICMS/CONFAZ 35/77, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 34 - Isenção do ICMS",
		DF814036:
			"Convênio ICMS/CONFAZ 24/89, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 36 - Isenção do ICMS",
		DF814037:
			"Convênio ICMS/CONFAZ 104/89, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 37 - Isenção do ICMS",
		DF814038:
			"Convênio ICMS/CONFAZ 99/89, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 38 - Isenção do ICMS",
		DF814039:
			"Convênio ICMS/CONFAZ 130/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 39 - Isenção do ICMS",
		DF814041:
			"Convênio ICMS/CONFAZ 35/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 41 - Isenção do ICMS",
		DF814042:
			"Convênio ICMS/CONFAZ 88/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 42 - Isenção do ICMS",
		DF814043:
			"Convênio ICMS/CONFAZ 34/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 43 - Isenção do ICMS",
		DF814045:
			"Convênio ICMS/CONFAZ 126/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 45 - Isenção do ICMS",
		DF814046:
			"Convênio ICMS/CONFAZ 11/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 46 - Isenção do ICMS",
		DF814047:
			"Convênio ICMS/CONFAZ 35/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 47 - Isenção do ICMS",
		DF814048:
			"Convênio ICMS/CONFAZ 48/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 48 - Isenção do ICMS",
		DF814049:
			"Convênio ICMS/CONFAZ 45/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 49 - Isenção do ICMS",
		DF814052:
			"Convênio ICMS/CONFAZ 113/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 52 - Isenção do ICMS",
		DF814053:
			"Convênio ICMS/CONFAZ 126/10, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 53 - Isenção do ICMS",
		DF814054:
			"Convênio ICMS/CONFAZ 136/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 54 - Isenção do ICMS",
		DF814055:
			"Convênio ICMS/CONFAZ 158/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 55 - Isenção do ICMS",
		DF814056:
			"Convênio ICMS/CONFAZ 158/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 56 - Isenção do ICMS",
		DF814057:
			"Convênio ICMS/CONFAZ 158/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 57 - Isenção do ICMS",
		DF814058:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 58 - Isenção do ICMS",
		DF814059:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 59 - Isenção do ICMS",
		DF814060:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 60 - Isenção do ICMS",
		DF814061:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 61 - Isenção do ICMS",
		DF814062:
			"Convênio ICMS/CONFAZ 64/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 62 - Isenção do ICMS",
		DF814063:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 63 - Isenção do ICMS",
		DF814064:
			"Convênio ICMS/CONFAZ 77/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 64 - Isenção do ICMS",
		DF814065:
			"Convênio ICMS/CONFAZ 30/96, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 65 - Isenção do ICMS",
		DF814066:
			"Convênio ICMS/CONFAZ 80/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 66 - Isenção do ICMS",
		DF814067:
			"Convênio ICMS/CONFAZ 80/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 67 - Isenção do ICMS",
		DF814068:
			"Convênio ICMS/CONFAZ 82/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 68 - Isenção do ICMS",
		DF814071:
			"Convênio ICMS/CONFAZ 42/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 71 - Isenção do ICMS",
		DF814073:
			"Convênio ICMS/CONFAZ 42/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 73 - Isenção do ICMS",
		DF814074:
			"Convênio ICMS/CONFAZ 18/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 74 - Isenção do ICMS",
		DF814075:
			"Convênio ICMS/CONFAZ 162/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 75 - Isenção do ICMS",
		DF814079:
			"Convênio ICMS/CONFAZ 116/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 79 - Isenção do ICMS",
		DF814080:
			"Convênio ICMS/CONFAZ 101/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 80 - Isenção do ICMS",
		DF814081:
			"Convênio ICMS/CONFAZ 09/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 81 - Isenção do ICMS",
		DF814082:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 82 - Isenção do ICMS",
		DF814083:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 83 - Isenção do ICMS",
		DF814084:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 84 - Isenção do ICMS",
		DF814085:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 85 - Isenção do ICMS",
		DF814086:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 86 - Isenção do ICMS",
		DF814087:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 87 - Isenção do ICMS",
		DF814088:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 88 - Isenção do ICMS",
		DF814089:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 89 - Isenção do ICMS",
		DF814090:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 90 - Isenção do ICMS",
		DF814091:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 91 - Isenção do ICMS",
		DF814092:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 92 - Isenção do ICMS",
		DF814093:
			"Convênio ICMS/CONFAZ 38/01, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 93 - Isenção do ICMS",
		DF814094:
			"Convênio ICMS/CONFAZ 84/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 94 - Isenção do ICMS",
		DF814095:
			"Convênio ICMS/CONFAZ 123/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 95 - Isenção do ICMS",
		DF814096:
			"Convênio ICMS/CONFAZ 158/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 96 - Isenção do ICMS",
		DF814097:
			"Convênio ICMS/CONFAZ 62/03, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 97 - Isenção do ICMS",
		DF814098:
			"Convênio ICMS/CONFAZ 47/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 98 - Isenção do ICMS",
		DF814099:
			"Convênio ICMS/CONFAZ 57/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 99 - Isenção do ICMS",
		DF814100:
			"Convênio ICMS/CONFAZ 18/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 100, -	Isenção do ICMS",
		DF814101:
			"Convênio ICMS/CONFAZ 95/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 101 -	Isenção do ICMS",
		DF814103:
			"Convênio ICMS/CONFAZ 01/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 103 -	Isenção do ICMS",
		DF814104:
			"Convênio ICMS/CONFAZ 75/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 104 -	Isenção do ICMS",
		DF814106:
			"Convênio ICMS/CONFAZ 08/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 106 -	Isenção do ICMS",
		DF814107:
			"Convênio ICMS/CONFAZ 43/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 107 -	Isenção do ICMS",
		DF814111:
			"Convênio ICMS/CONFAZ 33/01, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 111 -	Isenção do ICMS",
		DF814112:
			"Convênio ICMS/CONFAZ 42/01, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 112 -	Isenção do ICMS",
		DF814113:
			"Convênio ICMS/CONFAZ 93/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 113 -	Isenção do ICMS",
		DF814115:
			"Convênio ICMS/CONFAZ 103/00, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 115 - Isenção do ICMS",
		DF814116:
			"Convênio ICMS/CONFAZ 103/00, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 116 - Isenção do ICMS",
		DF814118:
			"Convênio ICMS/CONFAZ 10/02, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 118 -	Isenção do ICMS",
		DF814120:
			"Convênio ICMS/CONFAZ 31/02, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 120 -	Isenção do ICMS",
		DF814121:
			"Convênio ICMS/CONFAZ 87/02, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 121 -	Isenção do ICMS",
		DF814123:
			"Convênio ICMS/CONFAZ 140/01, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 123 - Isenção do ICMS",
		DF814124:
			"Convênio ICMS/CONFAZ 18/03, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 124 -	Isenção do ICMS",
		DF814125:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 125 - Isenção do ICMS",
		DF814126:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 126 - Isenção do ICMS",
		DF814127:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 127 - Isenção do ICMS",
		DF814129:
			"Convênio ICMS/CONFAZ 105/07, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 129 - Isenção do ICMS",
		DF814130:
			"Convênio ICMS/CONFAZ 38/12, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 130 -	Isenção do ICMS",
		DF814131:
			"Convênio ICMS/CONFAZ 51/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 131 -	Isenção do ICMS",
		DF814132:
			"Convênio ICMS/CONFAZ 84/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 132 -	Isenção do ICMS",
		DF814133:
			"Convênio ICMS/CONFAZ 27/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 133 -	Isenção do ICMS",
		DF814135:
			"Convênio ICMS/CONFAZ 79/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 135 -	Isenção do ICMS",
		DF814136:
			"Convênio ICMS/CONFAZ 81/08, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 136 -	Isenção do ICMS",
		DF814137:
			"Convênio ICMS/CONFAZ 122/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 137 - Isenção do ICMS",
		DF814138:
			"Convênio ICMS/CONFAZ 69/06, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 138 -	Isenção do ICMS",
		DF814140:
			"Convênio ICMS/CONFAZ 30/06, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 140 -	Isenção do ICMS",
		DF814142:
			"Convênio ICMS/CONFAZ 152/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 142 - Isenção do ICMS",
		DF814143:
			"Convênio ICMS/CONFAZ 53/07, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 143 -	Isenção do ICMS",
		DF814144:
			"Convênio ICMS/CONFAZ 09/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 144 -	Isenção do ICMS",
		DF814145:
			"Convênio ICMS/CONFAZ 10/07, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 145 -	Isenção do ICMS",
		DF814146:
			"Convênio ICMS/CONFAZ 91/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 146 -	Isenção do ICMS",
		DF814147:
			"Lei Distrital nº 4.242/08, regulamentada no Decreto nº 18.955/1997 Anexo I, caderno I, item 7 - Isenção do ICMS",
		DF814148:
			"Convênio ICMS/CONFAZ 27/07, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 148 -	Isenção do ICMS",
		DF814149:
			"Convênio ICMS/CONFAZ 129/06, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 149 - Isenção do ICMS",
		DF814151:
			"Convênio ICMS/CONFAZ 147/07, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 151 - Isenção do ICMS",
		DF814152:
			"Convênio ICMS/CONFAZ 141/07, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 152 - Isenção do ICMS",
		DF814154:
			"Convênio ICMS/CONFAZ 91/00, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 154 -	Isenção do ICMS",
		DF814155:
			"Convênio ICMS/CONFAZ 140/08, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 155 - Isenção do ICMS",
		DF814156:
			"Convênio ICMS/CONFAZ 14/09, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 156 -	Isenção do ICMS",
		DF814157:
			"Convênio ICMS/CONFAZ 58/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 157 -	Isenção do ICMS",
		DF814158:
			"Convênio ICMS/CONFAZ 26/09, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 158 -	Isenção do ICMS",
		DF814161:
			"Convênio ICMS/CONFAZ 73/10, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 161 -	Isenção do ICMS",
		DF814162:
			"Convênio ICMS/CONFAZ 33/10, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 162 -	Isenção do ICMS",
		DF814163:
			"Convênio ICMS/CONFAZ 43/10, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 163 -	Isenção do ICMS",
		DF814164:
			"Convênio ICMS/CONFAZ 94/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 164 - Isenção do ICMS",
		DF814166:
			"Convênio ICMS/CONFAZ 05/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 166 - Isenção do ICMS",
		DF814176:
			"Convênio ICMS/CONFAZ 05/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 176 - Isenção do ICMS",
		DF814177:
			"Convênios ICMS 143/10, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 177 - Isenção do ICMS",
		DF814178:
			"Convênios ICMS 55/11, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 178 - Isenção do ICMS",
		DF814179:
			"Convênio ICMS 99/98, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 179 - Isenção do ICMS",
		DF814180:
			"Convênio ICMS 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 180 - Isenção do ICMS",
		DF814181:
			"Convênio ICMS 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 181 - Isenção do ICMS",
		DF814182:
			"Convênio ICMS 16/15, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 182 - Isenção do ICMS",
		DF814183:
			"Lei nº 6.521/20, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno I, item 183 - Isenção do ICMS",
		DF814500: "Convênio ICMS 137/15 - Isenção do ICMS",
		DF814501: "Decreto nº 39.828/19, art. 2º, inc. I - Isenção do ICMS",
		DF814502: "Decreto nº 39.828/19, art. 2º, inc. II - Isenção do ICMS",
		DF814503: "Decreto nº 39.828/19, art. 2º, inc. III - Isenção do ICMS",
		DF814504: "Decreto nº 39.828/19, art. 2º, inc. IV - Isenção do ICMS",
		DF814505: "Decreto nº 39.828/19, art. 2º, inc. V - Isenção do ICMS",
		DF814506: "Decreto nº 40.036/2019, art. 3º, inc. I - Isenção do ICMS",
		DF814507: "Decreto nº 40.036/2019, art. 3º, inc. II - Isenção do ICMS",
		DF814508: "Decreto nº 40.036/2019, art. 3º, inc. III - Isenção do ICMS",
		DF814509: "Decreto nº 40.036/2019, art. 3º, inc. IV - Isenção do ICMS",
		DF814510: "Decreto nº 40.036/2019, art. 3º, inc. V - Isenção do ICMS",
		DF816001:
			"Convênio ICMS/CONFAZ 75/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 01 -	Redução de BC do ICMS",
		DF816002:
			"Convênio ICMS/CONFAZ 50/92, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 02 -	Redução de BC do ICMS",
		DF816003:
			"Convênio ICMS/CONFAZ 25/83, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 03 -	Redução de BC do ICMS",
		DF816004:
			"Convênio ICMS/CONFAZ 52/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 04 -	Redução de BC do ICMS",
		DF816005:
			"Convênio ICMS/CONFAZ 52/91, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 05 -	Redução de BC do ICMS",
		DF816006:
			"Convênio ICMS/CONFAZ 15/81, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 06 -	Redução de BC do ICMS",
		DF816011:
			"Convênio ICMS/CONFAZ 128/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 11 e projeto de lei a ser encaminhado à CLDF, incluindo na cesta básica a carne de frango e o leite UHT - Redução de BC do ICMS",
		DF816012:
			"Convênio ICMS/CONFAZ 86/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 12 - Redução de BC do ICMS",
		DF816014:
			"i 1.254/96, regulamentada no Decreto nº 18.955/1997 Anexo I, caderno II, item 14- Redução de BC do ICMS",
		DF816015:
			"i 1.254/96, regulamentada no Decreto nº 18.955/1997 Anexo I, caderno II, item 15	- Redução de BC do ICMS",
		DF816016:
			"Convênio ICMS/CONFAZ 114/95, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 16 - Redução de BC do ICMS",
		DF816017:
			"Convênio ICMS/CONFAZ 120/96, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 17 - Redução de BC do ICMS",
		DF816018:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 18 - Redução de BC do ICMS",
		DF816019:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 19 - Redução de BC do ICMS",
		DF816020:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 20 - Redução de BC do ICMS",
		DF816021:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 21 - Redução de BC do ICMS",
		DF816022:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 22 - Redução de BC do ICMS",
		DF816023:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 23 - Redução de BC do ICMS",
		DF816024:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 24 - Redução de BC do ICMS",
		DF816025:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 25 - Redução de BC do ICMS",
		DF816026:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 26 - Redução de BC do ICMS",
		DF816027:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 27 - Redução de BC do ICMS",
		DF816028:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 28 - Redução de BC do ICMS",
		DF816029:
			"Convênio ICMS/CONFAZ 50/93, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 29 - Redução de BC do ICMS",
		DF816033:
			"Convênio ICMS/CONFAZ 13/94, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 33 - Redução de BC do ICMS",
		DF816034:
			"Convênio ICMS/CONFAZ 78/01, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 34 - Redução de BC do ICMS",
		DF816035:
			"Convênio ICMS/CONFAZ 06/09, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 35 - Redução de BC do ICMS",
		DF816036:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 36 - Redução de BC do ICMS",
		DF816038:
			"Lei 2.708/01, regulamentada no Decreto nº 18.955/1997 Anexo I, caderno II, item 38 - Redução de BC do ICMS",
		DF816039:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 39 - Redução de BC do ICMS",
		DF816040:
			"Convênio ICMS/CONFAZ 133/02, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 40 - Redução de BC do ICMS",
		DF816041:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 41 - Redução de BC do ICMS",
		DF816042:
			"Convênio ICMS/CONFAZ 89/05, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 42 - Redução de BC do ICMS",
		DF816043:
			"Convênio ICMS/CONFAZ 34/06, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 43 - Redução de BC do ICMS",
		DF816044:
			"Convênio ICMS/CONFAZ 89/04, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 44 - Redução de BC do ICMS",
		DF816046:
			"Convênio ICMS/CONFAZ 113/06, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 46 - Redução de BC do ICMS",
		DF816047:
			"Convênio ICMS/CONFAZ100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 47 - Redução de BC do ICMS",
		DF816048:
			"Convênio ICMS/CONFAZ 78/15, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 48 - Redução de BC do ICMS",
		DF816049:
			"Convênio ICMS/CONFAZ 58/99, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 49 - Redução de BC do ICMS",
		DF816050:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 50 - Redução de BC do ICMS",
		DF816051:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 51 - Redução de BC do ICMS",
		DF816052:
			"Convênio ICMS/CONFAZ 100/97, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 52 - Redução de BC do ICMS",
		DF816053:
			"Convênio ICMS/CONFAZ 07/13, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 53 - Redução de BC do ICMS",
		DF816054:
			"Convênio ICMS/CONFAZ 102/11, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 54 - Redução de BC do ICMS",
		DF816055:
			"Convênio ICMS/CONFAZ 188/17, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 55 - Redução de BC do ICMS",
		DF816056:
			"Convênio ICMS/CONFAZ 61/12, regulamentado no Decreto nº 18.955/1997 Anexo I, caderno II, item 56 - Redução de BC do ICMS",
		DF816500: "Lei nº 1.254/96, art. 18, § 4º - Redução de BC do ICMS",
		DF816501: "Convênio ICMS 125/11 - Redução de BC do ICMS",
		DF817001:
			"Convênio ICMS/CONFAZ 120/96, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno III item 1 - Crédito presumido do ICMS",
		DF817002:
			"Convênio ICMS/CONFAZ 106/96, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno III item 2 - Crédito presumido do ICMS",
		DF817004:
			"Convênios ICMS/CONFAZ 56/10, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno III item 4 - Crédito presumido do ICMS",
		DF817007:
			"Convênio ICMS/CONFAZ 23/90, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno III item 7 - Crédito presumido do ICMS",
		DF817009:
			"Convênio ICMS/CONFAZ 56/12, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno III item 9 - Crédito presumido do ICMS",
		DF817501:
			"Decreto nº 39.753/2019, fundamentado no Convênio ICMS/CONFAZ 190/17 - Crédito presumido do ICMS",
		DF817502:
			"Decreto nº 39.803/2019, fundamentado no Convênio ICMS/CONFAZ 190/17 - Crédito presumido do ICMS",
		DF817503:
			"Decreto nº 40.036/2019, fundamentado no Convênio ICMS/CONFAZ 190/17 - Crédito presumido do ICMS",
		DF819001:
			"Convênios AE-15/74, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno IV item 1 - Suspensão do ICMS",
		DF819002:
			"Convênios AE-15/74, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno IV item 2 - Suspensão do ICMS",
		DF819003:
			"Convênios ICMS/CONFAZ 19/91, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno IV item 3 - Suspensão do ICMS",
		DF819005:
			"Convênios ICMS/CONFAZ 158/94, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno IV item 5 - Suspensão do ICMS",
		DF819006:
			"Convênios ICMS/CONFAZ 09/05, regulamentado no Decreto nº 18.955/1997, Anexo I, Caderno IV item 6 -	Suspensão do ICMS",
		DF824001: "Decreto-Lei nº 82/66, art. 92, inc. V - Isenção do ISS",
		DF826001: "Lei nº 3.730/2005 - Redução da BC do ISS",
		DF826002: "Lei nº 3.731/05 - Redução da BC do ISS",
		DF826003: "Lei nº 3.736/2005 - Redução da BC do ISS",
		DF890000: "cBenef não previsto - Outros",
		DF890001: "Benefício concedido por decisão judicial - Outros",
	},
	GO: {
		GO800001: `RCTE, Art 79, Inciso I, alínea "a" - NÃO INCIDÊNCIA DO ICMS - Operação que destine ao exterior mercadoria,
	 inclusive produto primário e produto industrializado semielaborado`,
		GO800002: `RCTE, Art 79, Inciso I, alínea "b" - NÃO INCIDÊNCIA DO ICMS - Operação que destine a outro Estado petróleo, inclusive lubrificante, combustível líquido e gasoso dele derivados, e energia elétrica, quando destinados à comercialização ou à industrialização`,
		GO800003: `RCTE, Art 79, Inciso I, alínea "c" - NÃO INCIDÊNCIA DO ICMS - Operação com ouro, quando definido em lei como ativo financeiro ou instrumento cambial`,
		GO800004: `RCTE, Art 79, Inciso I, alínea "d" - NÃO INCIDÊNCIA DO ICMS - Operação com livro, jornal e periódico e o papel destinado a sua impressão`,
		GO800005: `RCTE, Art 79, Inciso I, alínea "e" - NÃO INCIDÊNCIA DO ICMS - Operações relativas a mercadorias sujeita ao ISS, de competência dos Municípios`,
		GO800006: `RCTE, Art 79, Inciso I, alínea "f" - NÃO INCIDÊNCIA DO ICMS - Operação que destine mercadoria a sucessor legal, quando em decorrência desta não haja saída física da mercadoria`,
		GO800007: `RCTE, Art 79, Inciso I, alínea "g" - NÃO INCIDÊNCIA DO ICMS - Operação decorrente de alienação fiduciária em garantia, inclusive a operação efetuada pelo credor em decorrência do inadimplemento do devedor`,
		GO800008: `RCTE, Art 79, Inciso I, alínea "h" - NÃO INCIDÊNCIA DO ICMS - Operação de arrendamento mercantil, não compreendida a venda do bem arrendado ao arrendatário`,
		GO800009: `RCTE, Art 79, Inciso I, alínea "i" - NÃO INCIDÊNCIA DO ICMS - Operação de qualquer natureza de que decorra a transferência de bem móvel salvado de sinistro para companhia seguradora`,
		GO800010: `RCTE, Art 79, Inciso I, alínea "j" - NÃO INCIDÊNCIA DO ICMS - Operação que destine mercadoria a depósito fechado do próprio contribuinte, localizado no território deste Estado, e o seu retorno ao estabelecimento depositante`,
		GO800011: `RCTE, Art 79, Inciso I, alínea "l" - NÃO INCIDÊNCIA DO ICMS - Operação que destine mercadoria a armazém geral, situado neste Estado, para depósito em nome do remetente e o seu retorno ao estabelecimento depositante`,
		GO800012: `RCTE, Art 79, Inciso I, alínea "m" - NÃO INCIDÊNCIA DO ICMS - Operação de saída decorrente de alienação de mercadoria integrada ao ativo imobilizado do estabelecimento`,
		GO800013: `RCTE, Art 79, Inciso I, alínea "n" - NÃO INCIDÊNCIA DO ICMS - Operação de saída de bem em comodato`,
		GO800014: `RCTE, Art 79, Inciso I, alínea "o" - NÃO INCIDÊNCIA DO ICMS - Operação com obra de arte, quando comercializada pelo próprio autor ou na primeira venda por intermediário especializado`,
		GO800015: `RCTE, Art 79, Inciso I, alínea "p" - NÃO INCIDÊNCIA DO ICMS - Operação de saída de mercadoria em razão de mudança de endereço do estabelecimento, de um para outro local no território do Estado`,
		GO800016: `RCTE, Art 79, Inciso I, alínea "q" - NÃO INCIDÊNCIA DO ICMS - Operação de saída interna de mercadoria destinada à industrialização ou outro tratamento`,
		GO800017: `RCTE, Art 79, Inciso I, alínea "s" - NÃO INCIDÊNCIA DO ICMS - Operação de saída interna de mercadoria ou bem, que constitua mera movimentação física, desde que retorne ao remetente`,
		GO800018: `RCTE, Art 79, Inciso I, alínea "t" - NÃO INCIDÊNCIA DO ICMS - Operação de saída interna de produto agropecuário em estado natural, para fim de beneficiamento ou outro tratamento, desde que deva retornar ao estabelecimento de origem`,
		GO800019: `RCTE, Art 79, Inciso I, alínea "u" - NÃO INCIDÊNCIA DO ICMS - Operação de saída interna de mercadoria coletada em campanha de assistência social, obedecidas as condições estabelecidas em ato do Secretário de Estado da Economia`,
		GO800020: `RCTE, Art 79, Inciso I, alínea "v" - NÃO INCIDÊNCIA DO ICMS - Operação de saída interna, com os produtos de produção própria do estabelecimento remetente para outro estabelecimento pertencente à mesma pessoa natural ou jurídica`,
		GO800021: `RCTE, Art 79, Inciso I, alínea "x" - NÃO INCIDÊNCIA DO ICMS - Operação de saída de mercadoria remetida para demonstração, inclusive com destino a consumidor ou usuário final`,
		GO800022: `RCTE, Art 79, Inciso I, alínea "z" - NÃO INCIDÊNCIA DO ICMS - Operação de saída de mercadoria remetida para mostruário ou treinamento`,
		GO800023: `RCTE, Art 79, Inciso II, alínea "a" - NÃO INCIDÊNCIA DO ICMS - Prestação de serviço de transporte vinculada à operação de exportação de mercadoria para o exterior`,
		GO800024: `RCTE, Art 79, Inciso II, alínea "b" - NÃO INCIDÊNCIA DO ICMS - Prestação de serviço de transporte relativo à primeira remessa da mercadoria diretamente do produtor agropecuário destinada a depósito fechado ou armazém geral, situado neste Estado`,
		GO800025: `RCTE, Art 79, Inciso III - NÃO INCIDÊNCIA DO ICMS - Prestação de serviço de comunicação destinado ao exterior`,
		GO809000: `RCTE, Art 79 - NÃO INCIDÊNCIA DO ICMS - Reconhecida judicialmente`,
		GO811001: `RCTE, Anexo IX, Art 6º, Inciso I - ISENÇÃO - Saída de mercadoria com destino a exposição ou feira de amostra`,
		GO811002: `RCTE, Anexo IX, Art 6º, Inciso II - ISENÇÃO - Saída de mercadoria promovida por órgãos da Administração Pública, empresa pública, sociedade de economia mista e empresa concessionária de serviço público`,
		GO811003: `RCTE, Anexo IX, Art 6º, Inciso IV - ISENÇÃO - Saída interestadual de mercadoria remetida para conserto, reparo ou industrialização`,
		GO811004: `RCTE, Anexo IX, Art 6º, Inciso V - ISENÇÃO - Fornecimento de refeição, sem finalidade lucrativa`,
		GO811005: `RCTE, Anexo IX, Art 6º, Inciso VI - ISENÇÃO - Saída de mercadoria decorrente de venda efetuada à empresa Itaipu Binacional`,
		GO811006: `RCTE, Anexo IX, Art 6º, Inciso VII - ISENÇÃO - Saída de produto industrializado de origem nacional, destinado à embarcação ou aeronave, de bandeira estrangeira`,
		GO811007: `RCTE, Anexo IX, Art 6º, Inciso VIII - ISENÇÃO - Saída de mercadoria e a respectiva prestação de serviço de transporte, realizadas em decorrência de doação a entidade governamental ou a entidade assistencial`,
		GO811008: `RCTE, Anexo IX, Art 6º, Inciso IX - ISENÇÃO - Saída de produto típico de artesanato, promovida pelo próprio artesão ou por entidade da qual faça parte`,
		GO811009: `RCTE, Anexo IX, Art 6º, Inciso X - ISENÇÃO - Saída de produto farmacêutico entre órgãos da Administração Pública Federal, Estadual ou Municipal`,
		GO811010: `RCTE, Anexo IX, Art 6º, Inciso XI - ISENÇÃO - Saída de hortifrutícola, de pintos de um dia, de ovos, em estado natural e desde que não destinados à industrialização`,
		GO811011: `RCTE, Anexo IX, Art 6º, Inciso XII - ISENÇÃO - Saída de embarcação construída no País e de peça, parte e componente, aplicados pela indústria naval`,
		GO811012: `RCTE, Anexo IX, Art 6º, Inciso XIII - ISENÇÃO - Saída de reprodutor e matriz de animais registrados destinados a estabelecimento agropecuário`,
		GO811013: `RCTE, Anexo IX, Art 6º, Inciso XIV - ISENÇÃO - Entrada de reprodutor e matriz dos animais mencionados no inciso XIII do art. 6º do Anexo IX do RCTE, importados do exterior`,
		GO811014: `RCTE, Anexo IX, Art 6º, Inciso XV - ISENÇÃO - Saída de mercadoria de produção própria promovida por instituições de assistência social e de educação, sem finalidade lucrativa`,
		GO811015: `RCTE, Anexo IX, Art 6º, Inciso XVI - ISENÇÃO - Saída interna do estabelecimento varejista com destino a consumidor final de leite pasteurizado tipo especial`,
		GO811016: `RCTE, Anexo IX, Art 6º, Inciso XVII - ISENÇÃO - Saída de produto industrializado de origem nacional para comercialização ou industrialização na Zona Franca de Manaus e outras localidades`,
		GO811017: `RCTE, Anexo IX, Art 6º, Inciso XVIII - ISENÇÃO - Serviço de telecomunicação efetuado a partir de equipamento terminal instalado em dependência de Operadora`,
		GO811018: `RCTE, Anexo IX, Art 6º, Inciso XIX - ISENÇÃO - Saída de estabelecimento de Operadora de bem destinado à utilização em sua própria instalação ou a guarda em outro estabelecimento da mesma empresa`,
		GO811019: `RCTE, Anexo IX, Art 6º, Inciso XX - ISENÇÃO - Prestação de serviço de comunicação, efetuada por contribuinte que promova a divulgação, por meio do veículo beneficiário do favor fiscal, de matéria aprovada pelo Conselho Nacional de Política Fazendária - CONFAZ`,
		GO811020: `RCTE, Anexo IX, Art 6º, Inciso XXI - ISENÇÃO - Fornecimento de energia elétrica para consumo residencial que não ultrapasse a 50 (cinquenta) KW/h mensais`,
		GO811021: `RCTE, Anexo IX, Art 6º, Inciso XXII - ISENÇÃO - Prestação de serviço de transporte de passageiros desde que com características de transporte urbano ou metropolitano, com cobrança de tarifa reduzida`,
		GO811022: `RCTE, Anexo IX, Art 6º, Inciso XXIII - ISENÇÃO - Entrada decorrente de importação de mercadoria doada por organização internacional ou estrangeira ou por país estrangeiro para distribuição gratuita`,
		GO811023: `RCTE, Anexo IX, Art 6º, Inciso XXIV - ISENÇÃO - Operação de que decorra fornecimento de água natural canalizada, pela SANEAGO ou outra empresa concessionária, desde que o consumo mensal não ultrapasse 30m3`,
		GO811024: `RCTE, Anexo IX, Art 6º, Inciso XXV - ISENÇÃO - Prestação de serviço de transporte rodoviário de passageiro, realizada por veículo registrado na categoria de aluguel - táxi`,
		GO811025: `RCTE, Anexo IX, Art 6º, Inciso XXVI - ISENÇÃO - recebimento ou a entrada no estabelecimento importador de mercadoria importada sob o regime aduaneiro especial na modalidade drawback`,
		GO811026: `RCTE, Anexo IX, Art 6º, Inciso XXVII - ISENÇÃO - Saída, a título de distribuição gratuita, de amostra de produto de diminuto ou nenhum valor comercial`,
		GO811027: `RCTE, Anexo IX, Art 6º, Inciso XXVIII - ISENÇÃO - Saída interna entre estabelecimentos de uma mesma empresa, de bem integrado ao ativo imobilizado, cuja entrada no estabelecimento do remetente tenha ocorrido até 31 de outubro de 1996`,
		GO811028: `RCTE, Anexo IX, Art 6º, Inciso XXIX - ISENÇÃO - Saída interna de bem integrado ao ativo imobilizado para fornecimento de serviço fora do estabelecimento`,
		GO811029: `RCTE, Anexo IX, Art 6º, Inciso XXX - ISENÇÃO - Saída interna dos bens a que se refere o inciso XXIX do art. 6º do Anexo IX do RCTE, em retorno ao estabelecimento de origem`,
		GO811030: `RCTE, Anexo IX, Art 6º, Inciso XXXI - ISENÇÃO - Saída de combustível e lubrificante para o abastecimento de embarcação e aeronave nacionais com destino ao exterior`,
		GO811031: `RCTE, Anexo IX, Art 6º, Inciso XXXII - ISENÇÃO - Saída interestadual de bem integrado ao ativo imobilizado para fornecimento de serviço fora do estabelecimento`,
		GO811032: `RCTE, Anexo IX, Art 6º, Inciso XXXIII - ISENÇÃO - Fornecimento de energia elétrica para estabelecimento de produtor rural, cujo consumo não ultrapasse a 50 (cinquenta) KW/h mensais`,
		GO811033: `RCTE, Anexo IX, Art 6º, Inciso XXXIV - ISENÇÃO - Saída de vasilhame, recipiente ou embalagem, inclusive sacaria, quando não cobrado do destinatário ou não incluído no valor da mercadoria que acondiciona`,
		GO811034: `RCTE, Anexo IX, Art 6º, Inciso XXXV - ISENÇÃO - Saída de botijão vazio destinado ao acondicionamento de gás liquefeito de petróleo (GLP), em destroca efetuada por distribuidor de gás`,
		GO811035: `RCTE, Anexo IX, Art 6º, Inciso XXXVI - ISENÇÃO - Saída de produto industrializado promovida por loja franca ("free-shops"), instalada na zona primária do aeroporto de categoria internacional`,
		GO811036: `RCTE, Anexo IX, Art 6º, Inciso XXXVII - ISENÇÃO - Saída de produto industrializado destinado à comercialização por loja franca referida no inciso anterior`,
		GO811037: `RCTE, Anexo IX, Art 6º, Inciso XXXVIII - ISENÇÃO - Entrada de máquina para limpar e selecionar fruta, sem similar produzido no País, quando importada diretamente do exterior`,
		GO811038: `RCTE, Anexo IX, Art 6º, Inciso XXXIX - ISENÇÃO - Saída interna de veículo quando adquirido pela Secretaria de Segurança Pública ou pela Secretaria de Estado da Economia`,
		GO811039: `RCTE, Anexo IX, Art 6º, Inciso XL - ISENÇÃO - Saída de trava-bloco para a construção de casa popular, vinculada a programa habitacional para a população de baixa renda`,
		GO811040: `RCTE, Anexo IX, Art 6º, Inciso XLI - ISENÇÃO - Saída de oócito, de sêmen e de embrião, de bovino, caprino, ovino ou suíno congelados ou resfriados`,
		GO811041: `RCTE, Anexo IX, Art 6º, Inciso XLII - ISENÇÃO - relativamente ao diferencial de alíquotas, a entrada, no local de construção do Centro Integrado de Apoio à Criança - CIAC, de peça de argamassa armada ou de concreto armado`,
		GO811042: `RCTE, Anexo IX, Art 6º, Inciso XLIII - ISENÇÃO - Saída interna de gado asinino, bovino, bufalino, caprino, equino, muar, ovino e suíno destinado a criar, recriar ou engorda, realizada entre produtores agropecuários`,
		GO811043: `RCTE, Anexo IX, Art 6º, Inciso XLIV - ISENÇÃO - Saída de produto resultante de aula prática dos cursos profissionalizantes ministrados pelo Serviço Nacional de Aprendizagem Comercial - SENAC`,
		GO811044: `RCTE, Anexo IX, Art 6º, Inciso XLV - ISENÇÃO - Recebimento, por órgão da Administração Pública Direta, autarquia ou fundação do Estado de Goiás, de mercadoria importada do exterior`,
		GO811045: `RCTE, Anexo IX, Art 6º, Inciso XLVI - ISENÇÃO - Saída interna de casulo do bicho-da-seda`,
		GO811046: `RCTE, Anexo IX, Art 6º, Inciso XLVII - ISENÇÃO - Entrada de trator agrícola de quatro rodas e de colheitadeira mecânica de algodão, sem similar produzido no País`,
		GO811047: `RCTE, Anexo IX, Art 6º, Inciso XLVIII - ISENÇÃO - Saída de equino destinado a outra unidade da Federação, para cobertura ou para participação em prova, ou para treinamento`,
		GO811048: `RCTE, Anexo IX, Art 6º, Inciso XLIX - ISENÇÃO - Saída interna de produto hortifrutícola destinado à industrialização`,
		GO811049: `RCTE, Anexo IX, Art 6º, Inciso L - ISENÇÃO - Operações com produtos e medicamentos destinados ao tratamento de portadores do vírus da AIDS`,
		GO811050: `RCTE, Anexo IX, Art 6º, Inciso LI - ISENÇÃO - operações com máquina, equipamento, aparelho, instrumento e material, ou seu respectivo acessório, sobressalente e ferramenta, amparadas no Programa BEFIEX`,
		GO811051: `RCTE, Anexo IX, Art 6º, Inciso LII - ISENÇÃO - Saída de produtos alimentícios, com destino a estabelecimento do Banco de Alimentos ("Food Bank"), do Instituto de Integração e de Promoção da Cidadania (INTEGRA) e do Mesa Brasil SESC`,
		GO811052: `RCTE, Anexo IX, Art 6º, Inciso LIII - ISENÇÃO - Entrada interestadual de máquinas e equipamentos destinados à execução do projeto de construção do Poliduto Replan-Brasília`,
		GO811053: `RCTE, Anexo IX, Art 6º, Inciso LIV - ISENÇÃO - Saída interna de mercadoria constante da cesta básica`,
		GO811054: `RCTE, Anexo IX, Art 6º, Inciso LV - ISENÇÃO - Operações relacionadas no Convênio ICMS 18/95`,
		GO811055: `RCTE, Anexo IX, Art 6º, Inciso LVI - ISENÇÃO - Recebimento de amostra do exterior, sem valor comercial, tal como definida pela legislação federal que outorga a isenção do Imposto de Importação`,
		GO811056: `RCTE, Anexo IX, Art 6º, Inciso LVII - ISENÇÃO - Ingresso de bem procedente do exterior integrante de bagagem de viajante`,
		GO811057: `RCTE, Anexo IX, Art 6º, Inciso LIX - ISENÇÃO - Entrada do exterior de máquinas e equipamentos destinados à pesquisa científica e tecnológica, realizada diretamente pela EMBRAPA`,
		GO811058: `RCTE, Anexo IX, Art 6º, Inciso LX - ISENÇÃO - Recebimento, por doação, de produto importado do exterior, diretamente por órgão ou entidade da administração pública`,
		GO811059: `RCTE, Anexo IX, Art 6º, Inciso LXI - ISENÇÃO - Entrada, a qualquer título, de equipamento científico e de informática efetuada por órgão da administração pública direta e indireta`,
		GO811060: `RCTE, Anexo IX, Art 6º, Inciso LXII - ISENÇÃO - Saída interestadual de equipamento de propriedade da Empresa Brasileira de Telecomunicações S.A. - EMBRATEL`,
		GO811061: `RCTE, Anexo IX, Art 6º, Inciso LXIII - ISENÇÃO - Operação e a prestação internas de fornecimento de energia elétrica e de prestação serviço de telecomunicação, destinadas ao consumo por órgão da administração pública estadual`,
		GO811062: `RCTE, Anexo IX, Art 6º, Inciso LXV - ISENÇÃO - Prestação de serviço de transporte ferroviário de carga vinculada à operação de importação de país signatário do Acordo sobre o Transporte Internacional`,
		GO811063: `RCTE, Anexo IX, Art 6º, Inciso LXVI - ISENÇÃO - Saída interna com mamona em baga`,
		GO811064: `RCTE, Anexo IX, Art 6º, Inciso LXVIII - ISENÇÃO - Operação com equipamentos ou acessórios destinados a portadores de deficiência física`,
		GO811065: `RCTE, Anexo IX, Art 6º, Inciso LXIX - ISENÇÃO - Importação e a saída interna de mercadoria destinada à ampliação do sistema de informática da Secretaria de Estado da Economia de Goiás`,
		GO811066: `RCTE, Anexo IX, Art 6º, Inciso LXXIV - ISENÇÃO - Operação interna com resíduos e sucata de qualquer tipo de material`,
		GO811067: `RCTE, Anexo IX, Art 6º, Inciso LXXV - ISENÇÃO - Doação efetuada pelo fabricante de microcomputador usado para escola pública especial e profissionalizante, associação destinada a portador de deficiência e comunidade carente`,
		GO811068: `RCTE, Anexo IX, Art 6º, Inciso LXXVI - ISENÇÃO - Saída interna de produção própria de caroço de algodão com destino à industrialização`,
		GO811069: `RCTE, Anexo IX, Art 6º, Inciso LXXVII - ISENÇÃO - Importação de mercadoria ou bem sob o amparo do Regime Especial Aduaneiro de Admissão Temporária previsto na legislação federal específica`,
		GO811070: `RCTE, Anexo IX, Art 6º, Inciso LXXVIII - ISENÇÃO - Saída interna de produção própria do estabelecimento do produtor com destino à industrialização`,
		GO811071: `RCTE, Anexo IX, Art 6º, Inciso LXXIX - ISENÇÃO - Sucessivas saídas internas, com destino à industrialização, de produto não comestível resultante do abate de animal no Estado de Goiás`,
		GO811072: `RCTE, Anexo IX, Art 6º, Inciso LXXXI - ISENÇÃO - Saída interna com animal silvestre ou exótico vivo reproduzido com o fim de industrialização ou comercialização em criatório estabelecido no Estado de Goiás`,
		GO811073: `RCTE, Anexo IX, Art 6º, Inciso LXXXII - ISENÇÃO - Saída interna, com destino à industrialização, inclusive ao resfriamento, de leite em estado natural de produção própria do estabelecimento do produtor`,
		GO811074: `RCTE, Anexo IX, Art 6º, Inciso LXXXIII - ISENÇÃO - Saída de veículo adquirido pelo Departamento de Polícia Federal, com recurso do Fundo para Aparelhamento e Operacionalização das Atividades Fim da Polícia Federal`,
		GO811075: `RCTE, Anexo IX, Art 6º, Inciso LXXXIV - ISENÇÃO -  Saída que destine ao Ministério da Saúde os equipamentos médico-hospitalares arrolados no Apêndice XI do Anexo IX do RCTE`,
		GO811076: `RCTE, Anexo IX, Art 6º, Inciso LXXXV - ISENÇÃO - Operação de devolução impositiva de embalagem vazia de agrotóxico e respectiva tampa`,
		GO811077: `RCTE, Anexo IX, Art 6º, Inciso LXXXVI - ISENÇÃO - Operação com veículo adquirido pela Polícia Rodoviária Federal, de acordo com o previsto no Plano Anual de Reaparelhamento da Polícia Rodoviária Federal`,
		GO811078: `RCTE, Anexo IX, Art 6º, Inciso LXXXVII - ISENÇÃO - Saída interna destinada ao ativo fixo de estabelecimento apicultor`,
		GO811079: `RCTE, Anexo IX, Art 6º, Inciso LXXXVIII - ISENÇÃO - Saída interna de produção própria do estabelecimento apicultor remetente`,
		GO811080: `RCTE, Anexo IX, Art 6º, Inciso LXXXIX - ISENÇÃO - Entrada decorrente de importação do exterior de aparelhos, máquinas, equipamentos e instrumentos`,
		GO811081: `RCTE, Anexo IX, Art 6º, Inciso XC - ISENÇÃO - Saída realizada pela empresa Furnas Centrais Elétricas S/A em doação de material de consumo, de equipamento e de outro bem móvel`,
		GO811082: `RCTE, Anexo IX, Art 6º, Inciso XCI - ISENÇÃO - Operação e prestação internas, relativas à aquisição de bem, mercadoria e serviço por órgãos da Administração Pública Estadual Direta e suas fundações e autarquias`,
		GO811083: `RCTE, Anexo IX, Art 6º, Inciso XCII - ISENÇÃO - Relativamente à aplicação do diferencial de alíquotas na aquisição interestadual de bem destinado ao ativo imobilizado de estabelecimento industrial`,
		GO811084: `RCTE, Anexo IX, Art 6º, Inciso XCIII - ISENÇÃO - Operação relativa à venda do bem arrendado ao arrendatário contribuinte do ICMS`,
		GO811085: `RCTE, Anexo IX, Art 6º, Inciso XCV - ISENÇÃO - Saída interna de algodão, produzido no Estado de Goiás, em retorno ao estabelecimento que o tenha remetido para descaroçamento`,
		GO811086: `RCTE, Anexo IX, Art 6º, Inciso XCVI - ISENÇÃO - Saída interna de bambu produzido no Estado de Goiás com destino à industrialização ou à construção civil`,
		GO811087: `RCTE, Anexo IX, Art 6º, Inciso XCVII - ISENÇÃO - Sucessivas saídas internas de produto reciclado no Estado de Goiás, com destino à industrialização`,
		GO811088: `RCTE, Anexo IX, Art 6º, Inciso C - ISENÇÃO - Saída interna de muda de planta, inclusive a ornamental`,
		GO811089: `RCTE, Anexo IX, Art 6º, Inciso CI - ISENÇÃO - Operação interna com produto vegetal destinado à produção de biodiesel e de querosene de aviação alternativo`,
		GO811090: `RCTE, Anexo IX, Art 6º, Inciso CII - ISENÇÃO - Saída de pilha e bateria usadas, após seu esgotamento energético, que contenham em suas composições chumbo, cádmio, mercúrio e seus compostos`,
		GO811091: `RCTE, Anexo IX, Art 6º, Inciso CIII - ISENÇÃO - Saída interna de produto típico do cerrado goiano, exceto a lenha e a madeira, com destino à industrialização`,
		GO811092: `RCTE, Anexo IX, Art 6º, Inciso CIV - ISENÇÃO - Saída interna do estabelecimento produtor agropecuário com destino a central ou posto de coleta e recebimento de embalagem de agrotóxico usada e lavada`,
		GO811093: `RCTE, Anexo IX, Art 6º, Inciso CV - ISENÇÃO - Operação que destine a contribuinte abrangido por projeto agroindustrial de avicultura ou de suinocultura mercadorias para uso exclusivo na construção de granjas e aviários`,
		GO811094: `RCTE, Anexo IX, Art 6º, Inciso CVI - ISENÇÃO - Saída de produto farmacêutico e de fralda geriátrica da Fundação Oswaldo Cruz - FIOCRUZ - com destino à farmácia que faça parte do Programa Farmácia Popular do Brasil`,
		GO811095: `RCTE, Anexo IX, Art 6º, Inciso CVII - ISENÇÃO - Saída interna de soja, produzida no Estado de Goiás, em retorno ao estabelecimento que a tenha remetido para industrialização`,
		GO811096: `RCTE, Anexo IX, Art 6º, Inciso CVIII - ISENÇÃO - Importação de máquinas, aparelhos e equipamentos industriais, bem como suas partes e peças, destinados a integrar o ativo imobilizado de indústria gráfica`,
		GO811097: `RCTE, Anexo IX, Art 6º, Inciso CX - ISENÇÃO - Saída de medidor de vazão e de condutivímetro quando destinado ao estabelecimento industrial fabricante das bebidas`,
		GO811098: `RCTE, Anexo IX, Art 6º, Inciso CXII - ISENÇÃO - Saída de estabelecimento ou de oficina credenciada ou autorizada de peça defeituosa para o fabricante, oriunda de troca em virtude de garantia`,
		GO811099: `RCTE, Anexo IX, Art 6º, Inciso CXIII - ISENÇÃO - relativamente ao diferencial de alíquotas, a aquisição interestadual para o ativo imobilizado de estabelecimento industrial minério de níquel, cobre e seus derivados`,
		GO811100: `RCTE, Anexo IX, Art 6º, Inciso CXIV - ISENÇÃO - Prestação de serviço de comunicação referente ao acesso à internet e ao acesso de conectividade em banda larga no âmbito do Programa GESAC`,
		GO811101: `RCTE, Anexo IX, Art 6º, Inciso CXV - ISENÇÃO - Saída de óleo comestível usado destinado à indústria para utilização como matéria-prima e aquisição interna para comercialização`,
		GO811102: `RCTE, Anexo IX, Art 6º, Inciso CXVI - ISENÇÃO - Saída interna de asinino, ave, bovino, bufalino, caprino, equino, leporídeo, muar, ovino, ranídeo e suíno realizada por produtor agropecuário, destinada ao abate`,
		GO811103: `RCTE, Anexo IX, Art 6º, Inciso CXVII - ISENÇÃO - Saída interna de peixe produzido no Estado de Goiás, destinada a produção, reprodução, abate, comercialização ou industrialização`,
		GO811104: `RCTE, Anexo IX, Art 6º, Inciso CXVIII - ISENÇÃO - Prestação de serviço de comunicação referente ao acesso à internet e à conectividade em banda larga destinadas a escolas públicas`,
		GO811105: `RCTE, Anexo IX, Art 6º, Inciso CXIX - ISENÇÃO - Saída interestadual em transferência de bem do ativo imobilizado e de uso e consumo realizada por empresa prestadora de serviço de transporte aéreo`,
		GO811106: `RCTE, Anexo IX, Art 6º, Inciso CXX - ISENÇÃO - Operação e prestação realizada ou contratada pela Alcântara Cyclone Space - ACS e Centro de Lançamento em Alcântara-MA, no âmbito do Tratado Binacional Brasil-Ucrânia`,
		GO811107: `RCTE, Anexo IX, Art 6º, Inciso CXXI - ISENÇÃO - transferência interna de produto de fabricação própria, promovida pelo fabricante de vestuário, de roupas de cama, de mesa e banho, com destino à atacadista`,
		GO811108: `RCTE, Anexo IX, Art 6º, Inciso CXXII - ISENÇÃO - Operação que destine produto de fabricação própria à comercialização ou industrialização, realizada por fabricante do vestuário, de roupas de cama, de mesa e de banho optante do Simples Nacional`,
		GO811109: `RCTE, Anexo IX, Art 6º, Inciso CXXIII - ISENÇÃO - Valor agregado nas sucessivas saídas internas relacionadas ao processo de industrialização de vestuário, de roupas de cama, de mesa e de banho, por encomenda do industrial fabricante`,
		GO811110: `RCTE, Anexo IX, Art 6º, Inciso CXXIV - ISENÇÃO - Aquisições interestaduais de mercadorias para utilização como matéria-prima na fabricação de nova espécie de mercadoria ou de mercadorias excepcionadas do pagamento no Decreto nº 9.104/2017, realizadas por empresas optantes pelo Simples Nacional`,
		GO811111: `RCTE, Anexo IX, Art 6º, Inciso CXXV - ISENÇÃO - Saída de pneu usado, mesmo que recuperado de abandono, que tenha como objetivo sua reciclagem, tratamento ou disposição final ambientalmente adequada`,
		GO811112: `RCTE, Anexo IX, Art 6º, Inciso CXXVI - ISENÇÃO - Operação e a respectiva prestação de serviço de transporte decorrente da aquisição de equipamento de segurança eletrônica realizada por meio do Departamento Penitenciário Nacional`,
		GO811113: `RCTE, Anexo IX, Art 6º, Inciso CXXVII - ISENÇÃO - Saída interna de areia artificial de produção própria do estabelecimento com destino à industrialização`,
		GO811114: `RCTE, Anexo IX, Art 6º, Inciso CXXVIII - ISENÇÃO - Saída de gênero alimentício para alimentação escolar promovida por agricultor familiar ou empreendedor familiar rural ou por suas organizações`,
		GO811115: `RCTE, Anexo IX, Art 6º, Inciso CXXIX - ISENÇÃO - Prestação de serviço de comunicação referente ao acesso à internet por conectividade em banda larga prestada no âmbito do Programa Internet Popular`,
		GO811116: `RCTE, Anexo IX, Art 6º, Inciso CXXX - ISENÇÃO - Operação de importação, realizada por produtores de algodão ou por suas associações, de máquina e aparelho para ensaio têxtil`,
		GO811117: `RCTE, Anexo IX, Art 6º, Inciso CXXXI - ISENÇÃO - Operação interna de aquisição de veículo automotor utilitário novo destinado à pessoa que exerça a atividade de feirante ou feirante especial`,
		GO811118: `RCTE, Anexo IX, Art 6º, Inciso CXXXII - ISENÇÃO - Aquisição interestadual para integrar o ativo imobilizado, aquisição interna de insumos e a venda de grupos geradores de energia elétrica pelo industrial produtor de grupos geradores de energia elétrica`,
		GO811119: `RCTE, Anexo IX, Art 6º, Inciso CXXXIII - ISENÇÃO - Aquisição interestadual para integrar o ativo imobilizado e venda de veículo para órgão da Administração Pública realizada por industrial de veículo automotor`,
		GO811120: `RCTE, Anexo IX, Art 6º, Inciso CXXXIV - ISENÇÃO - Saída interna do animal que tenha sido criado ou engordado pelo produtor integrado ou parceiro, promovida pela empresa titular do projeto agroindustrial`,
		GO811121: `RCTE, Anexo IX, Art 6º, Inciso CXXXV - ISENÇÃO - Saída interna de mercadoria resultante da industrialização do açafrão, cujo processo industrial tenha sido realizado no Estado de Goiás`,
		GO811122: `RCTE, Anexo IX, Art 6º, Inciso CXXXVI - ISENÇÃO - Operação realizada pela Empresa Brasileira de Hemoderivados e Biotecnologia - Hemobrás - com fármaco e medicamento derivado do plasma humano`,
		GO811123: `RCTE, Anexo IX, Art 6º, Inciso CXXXVII - ISENÇÃO - Operação, realizada por industrial, beneficiário dos Programas Fomentar ou Produzir, fabricante de extintores de incêndio descartáveis de polímero de engenharia`,
		GO811124: `RCTE, Anexo IX, Art 6º, Inciso CXXXVIII - ISENÇÃO - Prestação de serviço de comunicação decorrente de utilização do serviço de assinatura com franquia na modalidade telefonia fixa`,
		GO811125: `RCTE, Anexo IX, Art 6º, Inciso CXL - ISENÇÃO - Operação interna e na importação com bem ou mercadoria destinados à utilização na rede de transporte público de passageiros sobre trilhos`,
		GO811126: `RCTE, Anexo IX, Art 6º, Inciso CXLI - ISENÇÃO - Saída de mercadoria de produção própria, realizada por Trabalhador Manual`,
		GO811127: `RCTE, Anexo IX, Art 6º, Inciso CXLIII - ISENÇÃO - Operação interna com óleo diesel destinada a empresa de transporte coletivo detentora de contrato de concessão celebrado com a CMTC`,
		GO811128: `RCTE, Anexo IX, Art 6º, Inciso CXLIV - ISENÇÃO - Operações interna e interestadual, na forma prevista no Capítulo XXXIV do Anexo XII, com etanol anidro combustível para armazenagem no sistema dutoviário`,
		GO811129: `RCTE, Anexo IX, Art 6º, Inciso CXLV - ISENÇÃO - Operações realizadas no âmbito do consórcio de empresas constituído com a finalidade de produção de energia elétrica, a partir do bagaço de cana-de-açúcar`,
		GO811130: `RCTE, Anexo IX, Art 6º, Inciso CXLVII - ISENÇÃO - Valor cobrado a título de gorjeta, quando do fornecimento de alimentação e bebidas promovido por bares, restaurantes, hotéis e estabelecimentos similares`,
		GO811131: `RCTE, Anexo IX, Art 6º, Inciso CXLVIII - ISENÇÃO - Fornecimento de energia elétrica pela distribuidora à unidade consumidora, nos termos do Sistema de Compensação de Energia Elétrica`,
		GO811132: `RCTE, Anexo IX, Art 6º, Inciso CXLIX - ISENÇÃO - Sucessivas saídas internas de trigo, com destino à industrialização`,
		GO811133: `RCTE, Anexo IX, Art 6º, Inciso CLI - ISENÇÃO - Operação interna com os produtos destinados à geração de energia solar`,
		GO811134: `RCTE, Anexo IX, Art 6º, Inciso CLII - ISENÇÃO - Fornecimento de energia elétrica para pessoa jurídica de direito privado, sem fins lucrativos, que recebam do Poder Executivo Estadual auxílio financeiro mensal`,
		GO811135: `RCTE, Anexo IX, Art 6º, Inciso CLIII - ISENÇÃO - Operações anteriores à saída destinada ao consumidor final, com bens e mercadorias digitais`,
		GO811136: `RCTE, Anexo IX, Art 6º, Inciso CLIV - ISENÇÃO - Remessa expressa internacional devolvida ao exterior na forma da legislação federal pertinente, por empresa de courier`,
		GO811137: `RCTE, Anexo IX, Art 6º, Inciso CLV - ISENÇÃO - Saídas internas de mercadorias, novas ou usadas, comercializadas sob a forma de bazar, promovidas pela entidade Obras Sociais do Centro Espírita Irmão Áureo - OSCEIA`,
		GO811138: `RCTE, Anexo IX, Art 6º, Inciso CLVI - ISENÇÃO - Operações com o medicamento Spinraza (Nusinersena) injection 12 mg/5 ml, destinado a tratamento da Atrofia Muscular Espinal - AME`,
		GO811139: `RCTE, Anexo IX, Art 6º, Inciso CLVII - ISENÇÃO - Operações com aceleradores lineares, classificados no código 9022.21.90 da Nomenclatura Comum do Mercosul - NCM`,
		GO811140: `RCTE, Anexo IX, Art 6º, Inciso CLVIII - ISENÇÃO - Saída de óleo lubrificante usado ou contaminado pelo uso, destinado a estabelecimento refinador ou coletor-revendedor`,
		GO811141: `RCTE, Anexo IX, Art 6º, Inciso CLIX - ISENÇÃO - Operações com vacinas e insumos destinados à produção de vacinas para o enfrentamento ao Coronavírus`,
		GO811900: `RCTE, Anexo IX, Art 6º	ISENÇÃO - Reconhecida judicialmente`,
		GO812001: `RCTE, Anexo IX, Art 7º, Inciso I - ISENÇÃO - Entrada de mercadoria importada do exterior, para ser utilizada no processo de fracionamento e industrialização de componente e derivado do sangue`,
		GO812002: `RCTE, Anexo IX, Art 7º, Inciso II - ISENÇÃO - Entrada de bens importados do exterior por órgão ou entidade da administração pública destinados a atividades de ensino, pesquisa ou prestação de serviços médico-hospitalares`,
		GO812003: `RCTE, Anexo IX, Art 7º, Inciso IV - ISENÇÃO - Saída das mercadorias relacionadas no Apêndice III do Anexo IX do RCTE, que se destinem, exclusivamente, ao atendimento a pessoas portadoras de deficiência`,
		GO812004: `RCTE, Anexo IX, Art 7º, Inciso V - ISENÇÃO - Entrada do exterior dos medicamentos, sem similares produzidos no País, importados diretamente pela APAE`,
		GO812005: `RCTE, Anexo IX, Art 7º, Inciso VII - ISENÇÃO - Importação do exterior de reprodutor e matriz de caprino de comprovada superioridade genética, quando efetuada diretamente por produtor`,
		GO812006: `RCTE, Anexo IX, Art 7º, Inciso VIII - ISENÇÃO - Saída de mercadoria doada à Secretaria de Educação, por contribuinte do ICMS, para distribuição, também por doação, à rede oficial de ensino`,
		GO812007: `RCTE, Anexo IX, Art 7º, Inciso IX - ISENÇÃO - Saída de pós-larva de camarão`,
		GO812008: `RCTE, Anexo IX, Art 7º, Inciso X - ISENÇÃO - Prestação interna de serviço de transporte de calcário, desde que vinculado a programa estadual de preservação ambiental`,
		GO812009: `RCTE, Anexo IX, Art 7º, Inciso XIV - ISENÇÃO - Saída de veículo automotor novo destinado à pessoa portadora de deficiência física, visual, mental severa ou profunda, ou autista`,
		GO812010: `RCTE, Anexo IX, Art 7º, Inciso XV - ISENÇÃO - Entrada de bem destinado à implantação de projeto de saneamento básico por companhia estadual de saneamento, importado do exterior`,
		GO812011: `RCTE, Anexo IX, Art 7º, Inciso XVII - ISENÇÃO - Saída de mercadoria doada ao Governo Estadual para distribuição gratuita a pessoa necessitada ou vítima de catástrofe`,
		GO812012: `RCTE, Anexo IX, Art 7º, Inciso XXII - ISENÇÃO - Saídas interna e interestadual do estabelecimento industrial ou concessionário de automóvel novo quando destinado a motorista profissional (táxi)`,
		GO812013: `RCTE, Anexo IX, Art 7º, Inciso XXIII - ISENÇÃO - Operação com produtos e equipamentos utilizados em diagnósticos em imunohematologia, sorologia e coagulação`,
		GO812014: `RCTE, Anexo IX, Art 7º, Inciso XXIV - ISENÇÃO - Operação com preservativo, classificado no código 4014.10.00 da NBM/SH`,
		GO812015: `RCTE, Anexo IX, Art 7º, Inciso XXV - ISENÇÃO - Saída interna com insumos agropecuários`,
		GO812016: `RCTE, Anexo IX, Art 7º, Inciso XXVI - ISENÇÃO - Saída de produtos utilizados em sistemas de geração de energia solar e eólica`,
		GO812017: `RCTE, Anexo IX, Art 7º, Inciso XXVII - ISENÇÃO - Operação que destine equipamentos didático, científico e médico-hospitalar ao Ministério da Educação e do Desporto - MEC`,
		GO812018: `RCTE, Anexo IX, Art 7º, Inciso XXX - ISENÇÃO -Saída ou a aquisição interestadual de bem do ativo imobilizado e de uso ou consumo ou a remessa de animais para inseminação e inovulação efetuada pela EMBRAPA`,
		GO812019: `RCTE, Anexo IX, Art 7º, Inciso XXXI - ISENÇÃO - Operação e a prestação referentes à saída de mercadoria, em decorrência de doação, para assistência à vítima de situação de seca nacionalmente reconhecida`,
		GO812020: `RCTE, Anexo IX, Art 7º, Inciso XXXII - ISENÇÃO - Operação com equipamentos e insumos destinados à prestação de serviços de saúde`,
		GO812021: `RCTE, Anexo IX, Art 7º, Inciso XXXIII - ISENÇÃO - Importação realizada pela FNS e pelo MS, do produto destinados às campanhas de vacinação, programas nacionais de combate à dengue, malária, febre amarela`,
		GO812022: `RCTE, Anexo IX, Art 7º, Inciso XXXV - ISENÇÃO - Operações com os medicamentos relacionados na cláusula primeira do Convênio ICMS-140/01`,
		GO812023: `RCTE, Anexo IX, Art 7º, Inciso XXXVII - ISENÇÃO - Operação realizada com fármaco e medicamento relacionados no Apêndice XVII do Anexo IX do RCTE, destinados a órgãos da Administração Pública`,
		GO812024: `RCTE, Anexo IX, Art 7º, Inciso XXXVIII - ISENÇÃO - Importação de soro conservante de córnea, realizada pela Fundação Banco de Olhos de Goiás`,
		GO812025: `RCTE, Anexo IX, Art 7º, Inciso XXXIX - ISENÇÃO - Importação de matéria-prima, sem similar produzida no país, destinada à produção de fármaco, relacionados no Apêndice XXIII do Anexo IX do RCTE`,
		GO812026: `RCTE, Anexo IX, Art 7º, Inciso XL - ISENÇÃO - Saída interna ou interestadual de mercadoria, em decorrência de doação, destinada ao atendimento do Programa intitulado Fome Zero`,
		GO812027: `RCTE, Anexo IX, Art 7º, Inciso XLI - ISENÇÃO - Prestação interna de serviço de transporte de cargas destinada a contribuinte do imposto estabelecido neste Estado`,
		GO812028: `RCTE, Anexo IX, Art 7º, Inciso XLII - ISENÇÃO - Operação interna, correspondente à doação de mercadoria ou bem à Organização das Voluntárias do Estado de Goiás - OVG`,
		GO812029: `RCTE, Anexo IX, Art 7º, Inciso XLIII - ISENÇÃO - Operação que destine a contribuinte abrangido pelo Projeto Integrado de Exploração Agropecuária e Agroindustrial do Estado de Roraima os produtos arrolados no inciso XXV do Anexo IX do RCTE`,
		GO812030: `RCTE, Anexo IX, Art 7º, Inciso XLIV - ISENÇÃO - Operação interna em decorrência de doação à entidade filantrópica "Vila São Bento Cotollengo" de produtos alimentícios`,
		GO812031: `RCTE, Anexo IX, Art 7º, Inciso XLV - ISENÇÃO - Operação com mercadoria destinada aos programas de fortalecimento e modernização das áreas fiscal, de gestão, de planejamento e de controle externo estaduais`,
		GO812032: `RCTE, Anexo IX, Art 7º, Inciso XLVI - ISENÇÃO - Saída interna de bem destinado a integrar o ativo imobilizado de empresa beneficiada pelo REPORTO`,
		GO812033: `RCTE, Anexo IX, Art 7º, Inciso XLVII - ISENÇÃO - Relativamente ao diferencial de alíquotas, a entrada no território goiano de 280 acumuladores elétricos e seus separadores destinados à empresa FURNAS`,
		GO812034: `RCTE, Anexo IX, Art 7º, Inciso XLVIII - ISENÇÃO - Operação de circulação de mercadoria caracterizada pela emissão e negociação do Certificado de Depósito Agropecuário - CDA - e do Warrant Agropecuário - WA`,
		GO812035: `RCTE, Anexo IX, Art 7º, Inciso L - ISENÇÃO -  Importação de máquinas, aparelhos e equipamentos industriais, bem como suas partes e peças relacionados no Apêndice XXIX do Anexo IX do RCTE, pelo SENAI, SENAC e SENAR`,
		GO812036: `RCTE, Anexo IX, Art 7º, Inciso LI - ISENÇÃO -  Operação com medicamento e reagente químico, relacionados no Apêndice XXX do Anexo IX do RCTE`,
		GO812037: `RCTE, Anexo IX, Art 7º, Inciso LII - ISENÇÃO - Importação dos produtos do Apêndice XXXI do Anexo IX do RCTE, efetuada por empresa concessionária de prestação de serviços públicos de radiodifusão`,
		GO812038: `RCTE, Anexo IX, Art 7º, Inciso LIII - ISENÇÃO - Saída de reagente para diagnóstico da Doença de Chagas a órgão ou entidade da administração pública direta, suas autarquias e fundações`,
		GO812039: `RCTE, Anexo IX, Art 7º, Inciso LIV - ISENÇÃO - Operação relativa à aquisição realizada pelo FNDE, de ônibus, micro-ônibus, e embarcação, destinados ao transporte escolar`,
		GO812040: `RCTE, Anexo IX, Art 7º, Inciso LIX - ISENÇÃO - Saída de estabelecimento que efetuar o reparo, conserto ou manutenção de aeronave, de peça defeituosa para o fabricante, oriunda de troca em virtude de garantia`,
		GO812041: `RCTE, Anexo IX, Art 7º, Inciso LX - ISENÇÃO - Operação com fosfato de oseltamivir, vinculada ao Programa Farmácia Popular do Brasil, destinado ao tratamento dos portadores da Gripe A (H1N1)`,
		GO812042: `RCTE, Anexo IX, Art 7º, Inciso LXI - ISENÇÃO - Importação do exterior, quando efetuada diretamente por produtor, de pós-larvas de camarão e reprodutores SPF`,
		GO812043: `RCTE, Anexo IX, Art 7º, Inciso LXII - ISENÇÃO - Saída de reprodutor de camarão marinho produzido no País`,
		GO812044: `RCTE, Anexo IX, Art 7º, Inciso LXIII - ISENÇÃO - Operação de comercialização do sanduíche BIG MAC realizada pelo estabelecimento integrante da Rede McDonald’s que participar do evento McDia Feliz`,
		GO812045: `RCTE, Anexo IX, Art 7º, Inciso LXIX - ISENÇÃO - Operações internas destinadas à ACCEG ou ao Hospital de Câncer de Inhumas, com as máquinas, os aparelhos e os equipamentos`,
		GO812046: `RCTE, Anexo IX, Art 7º, Inciso LXXIII - ISENÇÃO - Operações com o equipamento respiratório Elmo, suas partes e peças, utilizado no âmbito das medidas de enfrentamento ao Coronavírus`,
		GO812047: `RCTE, Anexo IX, Art 7º, Inciso LXXIV - ISENÇÃO - operações internas e de importação de oxigênio medicinal, classificado no código 2804.40.00 da NCM`,
		GO812048: `RCTE, Anexo IX, Art 7º, Inciso LXXV - ISENÇÃO - Operações internas com medicamentos que possuem farmacêuticos ativos relacionados no Apêndice XLVIII do Anexo IX do RCTE`,
		GO812900: `RCTE, Anexo IX, Art 7º - ISENÇÃO - Reconhecida judicialmente`,
		GO821001: `RCTE, Anexo IX, Art 8º, Inciso I - REDUÇÃO DE BASE DE CÁLCULO - Saída de mercadoria adquirida na condição de usada e quando a operação de que decorra a sua entrada no estabelecimento não tenha sido onerada pelo ICMS`,
		GO821002: `RCTE, Anexo IX, Art 8º, Inciso II - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de leite pasteurizado tipo especial com destino a estabelecimento varejista ou a consumidor final`,
		GO821003: `RCTE, Anexo IX, Art 8º, Inciso III - REDUÇÃO DE BASE DE CÁLCULO - Saída tributada interna de gás liquefeito de petróleo`,
		GO821004: `RCTE, Anexo IX, Art 8º, Inciso VI - REDUÇÃO DE BASE DE CÁLCULO - Entrada de máquina e equipamento importados do exterior e destinados a integrar o ativo imobilizado de empresa industrial que adquiriu pelo BIFIEX`,
		GO821005: `RCTE, Anexo IX, Art 8º, Inciso VIII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna que destine mercadoria para comercialização, produção ou industrialização`,
		GO821006: `RCTE, Anexo IX, Art 8º, Inciso X - REDUÇÃO DE BASE DE CÁLCULO - Prestação de serviço de televisão por assinatura`,
		GO821007: `RCTE, Anexo IX, Art 8º, Inciso XII - A REDUÇÃO DE BASE DE CÁLCULO - Fornecimento de refeições promovido por bares, restaurantes e estabelecimentos similares`,
		GO821008: `RCTE, Anexo IX, Art 8º, Inciso XIII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna com produto de informática, telecomunicação ou automação relacionado no Apêndice IV do Anexo IX do RCTE`,
		GO821009: `RCTE, Anexo IX, Art 8º, Inciso XVI - REDUÇÃO DE BASE DE CÁLCULO - Prestação de serviço de radiochamada`,
		GO821010: `RCTE, Anexo IX, Art 8º, Inciso XIX - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de arroz e feijão industrializados no Estado de Goiás`,
		GO821011: `RCTE, Anexo IX, Art 8º, Inciso XXI - REDUÇÃO DE BASE DE CÁLCULO - Operação interna com areia natural e artificial, saibro, material britado`,
		GO821012: `RCTE, Anexo IX, Art 8º, Inciso XXIII - REDUÇÃO DE BASE DE CÁLCULO - Operação interna com os produtos do Anexo I do RCTE, exceto armas e munições, Programa de Combate à Comercialização Ilegal de Mercadorias`,
		GO821013: `RCTE, Anexo IX, Art 8º, Inciso XXV - REDUÇÃO DE BASE DE CÁLCULO - Operação com os produtos relacionados no Convênio ICMS 34/06, praticada pelo industrial ou importador com destino a contribuinte`,
		GO821014: `RCTE, Anexo IX, Art 8º, Inciso XXVII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de máquinas e equipamentos rodoviários relacionados no Apêndice XII do Anexo IX do RCTE`,
		GO821015: `RCTE, Anexo IX, Art 8º, Inciso XXVIII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de telha, tijolo, tijoleira e tapa-viga cerâmicos, não esmaltados nem vitrificados`,
		GO821016: `RCTE, Anexo IX, Art 8º, Inciso XXIX - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de gasolina de aviação`,
		GO821017: `RCTE, Anexo IX, Art 8º, Inciso XXX - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de bem destinado ao ativo imobilizado de estabelecimento industrial ou agropecuário`,
		GO821018: `RCTE, Anexo IX, Art 8º, Inciso XXXII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de algodão em pluma beneficiada com o incentivo de que trata o inciso XIII do art. 11 do Anexo IX do RCTE`,
		GO821019: `RCTE, Anexo IX, Art 8º, Inciso XXXIII - REDUÇÃO DE BASE DE CÁLCULO - Operação interna com mercadorias que compõem a cesta básica`,
		GO821020: `RCTE, Anexo IX, Art 8º, Inciso XXXIV - REDUÇÃO DE BASE DE CÁLCULO - Saída interna com fralda descartável, produzida no Estado de Goiás`,
		GO821021: `RCTE, Anexo IX, Art 8º, Inciso XXXV - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de veículo automotor caminhão com peso em carga máxima superior a 5 (cinco) toneladas e caminhão-trator`,
		GO821022: `RCTE, Anexo IX, Art 8º, Inciso XXXVI - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de produtos relacionados no art. 1º, II, "i", 1 e 2 da Lei nº 13.453/99`,
		GO821023: `RCTE, Anexo IX, Art 8º, Inciso XXXVII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de mármore e granito produzidos no Estado de Goiás`,
		GO821024: `RCTE, Anexo IX, Art 8º, Inciso XL - REDUÇÃO DE BASE DE CÁLCULO - Saída de mercadoria resultante da industrialização de produto típico do cerrado goiano, exceto bebida alcoólica`,
		GO821025: `RCTE, Anexo IX, Art 8º, Inciso XLI - REDUÇÃO DE BASE DE CÁLCULO - Saída interestadual de carne e demais produtos comestíveis resultantes do abate de gado bovino, bufalino, caprino, ovino ou suíno`,
		GO821026: `RCTE, Anexo IX, Art 8º, Inciso XLIII - REDUÇÃO DE BASE DE CÁLCULO - Operação interna com massa asfáltica`,
		GO821027: `RCTE, Anexo IX, Art 8º, Inciso XLIV - REDUÇÃO DE BASE DE CÁLCULO - Operação interna com pedra-de-pirenópolis (pedra goiás)`,
		GO821028: `RCTE, Anexo IX, Art 8º, Inciso XLV - REDUÇÃO DE BASE DE CÁLCULO - Prestação onerosa de serviço de comunicação, na modalidade de monitoramento e rastreamento de veículo e carga`,
		GO821029: `RCTE, Anexo IX, Art 8º, Inciso XLVI - REDUÇÃO DE BASE DE CÁLCULO - Prestação de serviço de comunicação por meio de veiculação de mensagens de publicidade e propaganda na televisão por assinatura`,
		GO821030: `RCTE, Anexo IX, Art 8º, Inciso XLVII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de veículo automotor ônibus ou de chassi com motor para ônibus`,
		GO821031: `RCTE, Anexo IX, Art 8º, Inciso XLVIII - REDUÇÃO DE BASE DE CÁLCULO - Operação interestadual realizada por fabricante ou importador com pneumático novo de borracha e câmara-de-ar de borracha`,
		GO821032: `RCTE, Anexo IX, Art 8º, Inciso XLIX - REDUÇÃO DE BASE DE CÁLCULO - Saídas interna e interestadual com mercadoria adquirida por Órgãos da Administração Pública Direta, para aplicação nas UMS`,
		GO821033: `RCTE, Anexo IX, Art 8º, Inciso L - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de mercadoria ou bem destinado à construção e instalação de LT e subestação de EE produzida a partir do bagaço de cana-de-açúcar`,
		GO821034: `RCTE, Anexo IX, Art 8º, Inciso LII - REDUÇÃO DE BASE DE CÁLCULO - Operação interna de embalagem destinada ao industrial fabricante de adubo e fertilizante`,
		GO821035: `RCTE, Anexo IX, Art 8º, Inciso LIV - REDUÇÃO DE BASE DE CÁLCULO - Operação com os produtos listados no Apêndice XXXIII do Anexo IX do RCTE, destinados ao tratamento e controle de efluentes industriais e domésticos`,
		GO821036: `RCTE, Anexo IX, Art 8º, Inciso LVI - REDUÇÃO DE BASE DE CÁLCULO - Saída interna realizada por integrante de grupo econômico que destine mercadoria para comercialização, produção ou industrialização`,
		GO821037: `RCTE, Anexo IX, Art 8º, Inciso LVII - REDUÇÃO DE BASE DE CÁLCULO - Saída de medicamento de uso humano destinada a órgão da administração pública direta ou indireta, hospital ou clínica de saúde`,
		GO821038: `RCTE, Anexo IX, Art 8º, Inciso LIX - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de veículo automotor`,
		GO821039: `RCTE, Anexo IX, Art 8º, Inciso LX - REDUÇÃO DE BASE DE CÁLCULO - Saída de bem, material ou peça com defeito na prestação de serviços de assistência técnica, manutenção e reparo`,
		GO821900: `RCTE, Anexo IX, Art 8º - REDUÇÃO DE BASE DE CÁLCULO - Reconhecida judicialmente`,
		GO822001: `RCTE, Anexo IX, Art 9º, Inciso I - REDUÇÃO DE BASE DE CÁLCULO - Saída interna e interestadual com máquina e equipamento relacionados nos Apêndice V e VI do Anexo IX do RCTE`,
		GO822002: `RCTE, Anexo IX, Art 9º, Inciso III - REDUÇÃO DE BASE DE CÁLCULO - Saída dos produtos relacionados no Convênio ICMS 75/91`,
		GO822003: `RCTE, Anexo IX, Art 9º, Inciso V - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de produtos relacionados no Convênio ICMS 50/93`,
		GO822004: `RCTE, Anexo IX, Art 9º, Inciso VII - REDUÇÃO DE BASE DE CÁLCULO - Saída interestadual com insumos agropecuários relacionados na cláusula primeira do Convênio ICMS 100/97`,
		GO822005: `RCTE, Anexo IX, Art 9º, Inciso VIII - REDUÇÃO DE BASE DE CÁLCULO - Saída interestadual com insumos agropecuários relacionados na cláusula segunda do Convênio ICMS 100/97`,
		GO822006: `RCTE, Anexo IX, Art 9º, Inciso IX - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de farelo gordo de arroz, destinado à alimentação animal ou ao emprego na fabricação de ração animal`,
		GO822007: `RCTE, Anexo IX, Art 9º, Inciso XX - REDUÇÃO DE BASE DE CÁLCULO - Operação interestadual realizada por estabelecimento fabricante ou importador com a mercadoria relacionada nos Apêndices XVIII a XX do Anexo IX do RCTE`,
		GO822008: `RCTE, Anexo IX, Art 9º, Inciso XXV - REDUÇÃO DE BASE DE CÁLCULO - Saída realizada por industrializador de mandioca do produto resultante da industrialização dessa mercadoria`,
		GO822009: `RCTE, Anexo IX, Art 9º, Inciso XXVII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de querosene de aviação`,
		GO822010: `RCTE, Anexo IX, Art 9º, Inciso XXVIII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de gás natural proveniente de gás natural liquefeito - GNL`,
		GO822011: `RCTE, Anexo IX, Art 9º, Inciso XXIX - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de biodiesel (B-100) resultante da industrialização dos produtos da cláusula primeira do Convênio ICMS 113/06`,
		GO822012: `RCTE, Anexo IX, Art 9º, Inciso XXXI - REDUÇÃO DE BASE DE CÁLCULO - Saída de bovino dos Municípios da RIDE para ser abatido em estabelecimento frigorífico localizado no Distrito Federal`,
		GO822013: `RCTE, Anexo IX, Art 9º, Inciso XXXII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de madeira de produção própria do estabelecimento produtor destinada à industrialização`,
		GO822014: `RCTE, Anexo IX, Art 9º, Inciso XXXIII - REDUÇÃO DE BASE DE CÁLCULO - Importação, diretamente do Paraguai, de mercadoria ou bem do anexo do Decreto federal nº 6.956/09, no Regime de Tributação Unificado - optante pelo Simples Nacional`,
		GO822015: `RCTE, Anexo IX, Art 9º, Inciso XXXIV - REDUÇÃO DE BASE DE CÁLCULO - Operações realizadas pelo estabelecimento industrial fabricante com destino ao Ministério da Defesa e aos seus órgãos`,
		GO822016: `RCTE, Anexo IX, Art 9º, Inciso XXXV - REDUÇÃO DE BASE DE CÁLCULO - Prestação de serviço de transporte intermunicipal de passageiros, que tenha início e término em seu território`,
		GO822017: `RCTE, Anexo IX, Art 9º, Inciso XXXVII - REDUÇÃO DE BASE DE CÁLCULO - Saída interna de querosene de aviação - QAV com destino a consumo de empresa de transporte aéreo de carga ou de pessoas`,
		GO822018: `RCTE, Anexo IX, Art 9º, Inciso XXXVIII - REDUÇÃO DE BASE DE CÁLCULO - Importações, saídas internas e interestaduais com os produtos relacionados no Convênio ICMS 100/97`,
		GO822019: `RCTE, Anexo IX, Art 9º, Inciso XXXVIII - REDUÇÃO DE BASE DE CÁLCULO - Fornecimento de refeições promovido por bares, restaurantes e estabelecimentos similares`,
		GO822900: `RCTE, Anexo IX, Art 9º - REDUÇÃO DE BASE DE CÁLCULO - Reconhecida judicialmente`,
		GO890001: `RCTE, OPERAÇÕES ESPECIAIS - Operações, sem benefício fiscal, para as quais a legislação define a emissão da NF sem destaque de ICMS`,
		GO890002: `(REVOGADO) Lei nº 13.591/00, Art. 20, § 4º - Operações com produtos de fabricação própria entre as empresas beneficiárias dos Programas FOMENTAR e PRODUZIR ou entre as empresas beneficiárias do programa PRODUZIR`,
		GO890003: `Decreto nº 5.686/02, Art. 6º - REDUÇÃO DA BASE DE CÁLCULO - saída interna promovida por empresa comercial importadora e exportadora, beneficiária do programa COMEXPRODUZIR, com bens e mercadorias importados do exterior e destinados à comercialização, produção ou industrialização`,
		GO890004: `RCTE - ICMS MONOFÁSICO SOBRE COMBUSTÍVEIS - Regime de tributação monofásica, Conv.199/2022 (a partir de mai/2023) e Conv.15/2023 (a partir de jul/2023)`,
	},
	PR: {
		PR800000: `Imunidade ou não incidência do imposto reconhecida judicialmente - Imunidade ou não incidência`,
		PR800001: `Não incidência de que trata o inciso I do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800002: `Não incidência de que trata o inciso II do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800003: `Não incidência de que trata o inciso III do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800004: `Não incidência de que trata o inciso IV do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800005: `Não incidência de que trata o inciso V do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800006: `Não incidência de que trata o inciso VI do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800007: `Não incidência de que trata o inciso VII do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800008: `Não incidência de que trata o inciso VIII do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800009: `Não incidência de que trata o inciso IX do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800010: `Não incidência de que trata o inciso X do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800011: `Não incidência de que trata o inciso XI do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800012: `Não incidência de que trata o inciso XIII do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800013: `Não incidência de que trata o inciso XIV do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800014: `Não incidência de que trata o inciso XV do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800015: `Não incidência de que trata o inciso I do parágrafo único do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800016: `Não incidência de que trata o inciso II do parágrafo único do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800017: `Não incidência de que trata o inciso XII do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR800018: `Sem destaque do ICMS conforme art. 140 do RICMS/2017 - Não tributado`,
		PR800019: `Sem destaque do ICMS conforme inciso II do § 3º do art. 237 e art. 13 do Anexo VIII do RICMS/2017 - Não tributado`,
		PR800020: `Sem destaque do ICMS conforme § 5º do art. 237 do RICMS/2017 - Não tributado`,
		PR800021: `Sem destaque do ICMS conforme alínea 'b' do inciso I do § 11 do art. 237 do RICMS/2017 - Não tributado`,
		PR800022: `Sem destaque do ICMS conforme § 2º do art. 395 do RICMS/2017 - Não tributado`,
		PR800023: `Sem destaque do ICMS conforme inciso I do art. 397 do RICMS/2017 - Não tributado`,
		PR800024: `Sem destaque do ICMS conforme § 1º do art. 411 do RICMS/2017 - Não tributado`,
		PR800025: `Sem destaque do ICMS conforme inciso II do § 2º do art. 411 do RICMS/2017 - Não tributado`,
		PR800026: `Sem destaque do ICMS conforme inciso II do art. 415 do RICMS/2017 - Não tributado`,
		PR800027: `Sem destaque do ICMS conforme inciso II do art. 416 do RICMS/2017 - Não tributado`,
		PR800028: `Sem destaque do ICMS conforme art. 419 do RICMS/2017 - Não tributado`,
		PR800029: `Sem destaque do ICMS conforme alínea 'b' do inciso I do art. 425 do RICMS/2017 - Não tributado`,
		PR800030: `Sem destaque do ICMS conforme inciso II do art. 425 do RICMS/2017 - Não tributado`,
		PR800031: `Sem destaque do ICMS conforme alínea 'a' do inciso I do art. 430 do RICMS/2017 - Não tributado`,
		PR800032: `Sem destaque do ICMS conforme inciso II do art. 430 do RICMS/2017 - Não tributado`,
		PR800033: `Sem destaque do ICMS conforme art. 439 do RICMS/2017 - Não tributado`,
		PR800034: `Sem destaque do ICMS conforme § 3º do art. 440 do RICMS/2017 - Não tributado`,
		PR800035: `Sem destaque do ICMS conforme art. 472 do RICMS/2017 - Não tributado`,
		PR800036: `Sem destaque do ICMS conforme inciso I do art. 497 e alínea 'a' do inciso II do art. 499 do RICMS/2017 - Não tributado`,
		PR800037: `Sem destaque do ICMS conforme alínea 'a' do inciso I do § 1º art. 497 e alínea 'c' do inciso II do art. 499 do RICMS/2017 - Não tributado`,
		PR800038: `Sem destaque do ICMS conforme alínea 'b' do inciso II do art. 499 e inciso II do art. 499-A do RICMS/2017 - Não tributado`,
		PR800039: `Sem destaque do ICMS conforme art. 518 do RICMS/2017 - Não tributado`,
		PR800040: `Sem destaque do ICMS conforme inciso I do art. 518 do RICMS/2017 - Não tributado`,
		PR800041: `Sem destaque do ICMS conforme arts. 538 e 539 do RICMS/2017 - Não tributado`,
		PR800042: `Sem destaque do ICMS conforme art. 578, 'caput' do RICMS/2017 - Não tributado`,
		PR800043: `Sem destaque do ICMS conforme art. 578, § 4º, inciso II, item 2, do RICMS/2017 - Não tributado`,
		PR800044: `Sem destaque do ICMS conforme Anexo VIII, arts. 8º, inciso I, alínea 'c', e 9º, inciso I, do RICMS/2017 - Não tributado`,
		PR800045: `Sem destaque do ICMS conforme Anexo IV, Subanexo II, arts. 31 e 33,do RICMS/2017 - Não tributado`,
		PR800046: `Sem destaque do ICMS conforme Anexo IV, Subanexo I, art.14, do RICMS/2017 - Não tributado`,
		PR800047: `Imunidade por se tratar de operação equiparada a exportação, descrita no item 50 do Anexo V do RICMS/2017, conforme Consulta nº 052/2018 do Setor Consultivo da REPR. - Imunidade`,
		PR800048: `Não incidência de que trata o inciso XVI do “caput” do art. 3º do RICMS/2017 - Imunidade ou não incidência`,
		PR809997: `Não incidência reconhecida em solução de consulta do Setor Consultivo da REPR/IGT - Não tributado`,
		PR809998: `Operação ou prestação não tributada sem código específico - Imunidade ou não incidência`,
		PR809999: `Não Incidência prevista em regime especial e/ou programa de incentivo - Isenção`,
		PR810000: `Isenção do pagamento do imposto reconhecida judicialmente - Isenção`,
		PR810001: `Isenção prevista no item 1 do Anexo V do RICMS/2017 - Isenção`,
		PR810002: `Isenção prevista no item 2 do Anexo V do RICMS/2017 - Isenção`,
		PR810003: `Isenção prevista no item 3 do Anexo V do RICMS/2017 - Isenção`,
		PR810004: `Isenção prevista no item 4 do Anexo V do RICMS/2017 - Isenção`,
		PR810005: `Isenção prevista no item 5 do Anexo V do RICMS/2017 - Isenção`,
		PR810006: `Isenção prevista no item 6 do Anexo V do RICMS/2017 - Isenção`,
		PR810007: `Isenção prevista no item 7 do Anexo V do RICMS/2017 - Isenção`,
		PR810008: `Isenção prevista no item 8 do Anexo V do RICMS/2017 - Isenção`,
		PR810009: `Isenção prevista no item 9 do Anexo V do RICMS/2017 - Isenção`,
		PR810010: `Isenção prevista no item 10 do Anexo V do RICMS/2017 - Isenção`,
		PR810011: `Isenção prevista no item 11 do Anexo V do RICMS/2017 - Isenção`,
		PR810012: `Isenção prevista no item 12 do Anexo V do RICMS/2017 - Isenção`,
		PR810013: `Isenção prevista no item 13 do Anexo V do RICMS/2017 - Isenção`,
		PR810014: `Isenção prevista no item 14 do Anexo V do RICMS/2017 - Isenção`,
		PR810015: `Isenção prevista no item 15 do Anexo V do RICMS/2017 - Isenção`,
		PR810016: `Isenção prevista no item 16 do Anexo V do RICMS/2017 - Isenção`,
		PR810017: `Isenção prevista no item 17 do Anexo V do RICMS/2017 - Isenção`,
		PR810018: `Isenção prevista no item 18 do Anexo V do RICMS/2017 - Isenção`,
		PR810019: `Isenção prevista no item 19 do Anexo V do RICMS/2017 - Isenção`,
		PR810020: `Isenção prevista no item 20 do Anexo V do RICMS/2017 - Isenção`,
		PR810021: `Isenção prevista no item 21 do Anexo V do RICMS/2017 e no art. 1º da Lei nº 14.978/2005 - Isenção`,
		PR810022: `Isenção prevista no item 22 do Anexo V do RICMS/2017 - Isenção`,
		PR810023: `Isenção prevista no item 23 do Anexo V do RICMS/2017 - Isenção`,
		PR810024: `Isenção prevista no item 24 do Anexo V do RICMS/2017 - Isenção`,
		PR810025: `Isenção prevista no item 25 do Anexo V do RICMS/2017 - Isenção`,
		PR810026: `Isenção prevista no item 26 do Anexo V do RICMS/2017 - Isenção`,
		PR810027: `Isenção prevista no item 27 do Anexo V do RICMS/2017 - Isenção`,
		PR810028: `Isenção prevista no item 28 do Anexo V do RICMS/2017 - Isenção`,
		PR810029: `Isenção prevista no item 29 do Anexo V do RICMS/2017 - Isenção`,
		PR810030: `Isenção prevista no item 30 do Anexo V do RICMS/2017 - Isenção`,
		PR810031: `Isenção prevista no item 31 do Anexo V do RICMS/2017 - Isenção`,
		PR810032: `Isenção prevista no item 32 do Anexo V do RICMS/2017 - Isenção`,
		PR810033: `Isenção prevista no item 33 do Anexo V do RICMS/2017 - Isenção`,
		PR810034: `Isenção prevista no item 34 do Anexo V do RICMS/2017 - Isenção`,
		PR810035: `Isenção prevista no item 35 do Anexo V do RICMS/2017 - Isenção`,
		PR810036: `Isenção prevista no item 36 do Anexo V do RICMS/2017 - Isenção`,
		PR810037: `Isenção prevista no item 37 do Anexo V do RICMS/2017 e no art. 1º da Lei nº 14.773/2005 - Isenção`,
		PR810038: `Isenção prevista no item 38 do Anexo V do RICMS/2017 - Isenção`,
		PR810039: `Isenção prevista no item 39 do Anexo V do RICMS/2017 - Isenção`,
		PR810040: `Isenção prevista no item 40 do Anexo V do RICMS/2017 - Isenção`,
		PR810041: `Isenção prevista no item 41 do Anexo V do RICMS/2017 - Isenção`,
		PR810042: `Isenção prevista no item 42 do Anexo V do RICMS/2017 - Isenção`,
		PR810043: `Isenção prevista no item 43 do Anexo V do RICMS/2017 - Isenção`,
		PR810044: `Isenção prevista no item 44 do Anexo V do RICMS/2017 - Isenção`,
		PR810045: `Isenção prevista no item 45 do Anexo V do RICMS/2017 - Isenção`,
		PR810046: `Isenção prevista no item 46 do Anexo V do RICMS/2017 - Isenção`,
		PR810047: `Isenção prevista no item 47 do Anexo V do RICMS/2017 - Isenção`,
		PR810048: `Isenção prevista no item 48 do Anexo V do RICMS/2017 - Isenção`,
		PR810049: `Isenção prevista no item 49 do Anexo V do RICMS/2017 - Isenção`,
		PR810050: `Isenção prevista no item 50 do Anexo V do RICMS/2017 - Isenção`,
		PR810051: `Isenção prevista no item 51 do Anexo V do RICMS/2017 - Isenção`,
		PR810052: `Isenção prevista no item 52 do Anexo V do RICMS/2017 - Isenção`,
		PR810053: `Isenção prevista no item 53 do Anexo V do RICMS/2017 - Isenção`,
		PR810054: `Isenção prevista no item 54 do Anexo V do RICMS/2017 - Isenção`,
		PR810055: `Isenção prevista no item 55 do Anexo V do RICMS/2017 - Isenção`,
		PR810056: `Isenção prevista no item 56 do Anexo V do RICMS/2017 - Isenção`,
		PR810057: `Isenção prevista no item 57 do Anexo V do RICMS/2017 - Isenção`,
		PR810058: `Isenção prevista no item 58 do Anexo V do RICMS/2017 e no art. 1º da Lei nº 14.959/2005 - Isenção`,
		PR810059: `Isenção prevista no item 59 do Anexo V do RICMS/2017 - Isenção`,
		PR810060: `Isenção prevista no item 60 do Anexo V do RICMS/2017 - Isenção`,
		PR810061: `Isenção prevista no item 61 do Anexo V do RICMS/2017 - Isenção`,
		PR810062: `Isenção prevista no item 62 do Anexo V do RICMS/2017 - Isenção`,
		PR810063: `Isenção prevista no item 63 do Anexo V do RICMS/2017 - Isenção`,
		PR810064: `Isenção prevista no item 64 do Anexo V do RICMS/2017 - Isenção`,
		PR810065: `Isenção prevista no item 65 do Anexo V do RICMS/2017 - Isenção`,
		PR810066: `Isenção prevista no item 66 do Anexo V do RICMS/2017 - Isenção`,
		PR810067: `Isenção prevista no item 67 do Anexo V do RICMS/2017 - Isenção`,
		PR810068: `Isenção prevista no item 68 do Anexo V do RICMS/2017 - Isenção`,
		PR810069: `Isenção prevista no item 69 do Anexo V do RICMS/2017 - Isenção`,
		PR810070: `Isenção prevista no item 70 do Anexo V do RICMS/2017 - Isenção`,
		PR810071: `Isenção prevista no item 71 do Anexo V do RICMS/2017 - Isenção`,
		PR810072: `Isenção prevista no item 72 do Anexo V do RICMS/2017 - Isenção`,
		PR810073: `Isenção prevista no item 73 do Anexo V do RICMS/2017 - Isenção`,
		PR810074: `Isenção prevista no item 74 do Anexo V do RICMS/2017 - Isenção`,
		PR810075: `Isenção prevista no item 75 do Anexo V do RICMS/2017 - Isenção`,
		PR810076: `Isenção prevista no item 76 do Anexo V do RICMS/2017 - Isenção`,
		PR810077: `Isenção prevista no item 77 do Anexo V do RICMS/2017 - Isenção`,
		PR810078: `Isenção prevista no item 78 do Anexo V do RICMS/2017 - Isenção`,
		PR810079: `Isenção prevista no item 79 do Anexo V do RICMS/2017 - Isenção`,
		PR810080: `Isenção prevista no item 80 do Anexo V do RICMS/2017 e no art. 1º da Lei nº 14.586/2004 - Isenção`,
		PR810081: `Isenção prevista no item 81 do Anexo V do RICMS/2017 - Isenção`,
		PR810082: `Isenção prevista no item 82 do Anexo V do RICMS/2017 - Isenção`,
		PR810083: `Isenção prevista no item 83 do Anexo V do RICMS/2017 - Isenção`,
		PR810084: `Isenção prevista no item 84 do Anexo V do RICMS/2017 - Isenção`,
		PR810085: `Isenção prevista no item 85 do Anexo V do RICMS/2017 - Isenção`,
		PR810086: `Isenção prevista no item 86 do Anexo V do RICMS/2017 - Isenção`,
		PR810087: `Isenção prevista no item 87 do Anexo V do RICMS/2017 - Isenção`,
		PR810088: `Isenção prevista no item 88 do Anexo V do RICMS/2017 - Isenção`,
		PR810089: `Isenção prevista no item 89 do Anexo V do RICMS/2017 - Isenção`,
		PR810090: `Isenção prevista no item 90 do Anexo V do RICMS/2017 - Isenção`,
		PR810091: `Isenção prevista no item 91 do Anexo V do RICMS/2017 - Isenção`,
		PR810092: `Isenção prevista no item 92 do Anexo V do RICMS/2017 - Isenção`,
		PR810093: `Isenção prevista no item 93 do Anexo V do RICMS/2017 - Isenção`,
		PR810094: `Isenção prevista no item 94 do Anexo V do RICMS/2017 - Isenção`,
		PR810095: `Isenção prevista no item 95 do Anexo V do RICMS/2017 - Isenção`,
		PR810096: `Isenção prevista no item 96 do Anexo V do RICMS/2017 - Isenção`,
		PR810097: `Isenção prevista no item 97 do Anexo V do RICMS/2017 - Isenção`,
		PR810098: `Isenção prevista no item 98 do Anexo V do RICMS/2017 - Isenção`,
		PR810099: `Isenção prevista no item 99 do Anexo V do RICMS/2017 - Isenção`,
		PR810100: `Isenção prevista no item 100 do Anexo V do RICMS/2017 - Isenção`,
		PR810101: `Isenção prevista no item 101 do Anexo V do RICMS/2017 - Isenção`,
		PR810102: `Isenção prevista no item 102 do Anexo V do RICMS/2017 - Isenção`,
		PR810103: `Isenção prevista no item 103 do Anexo V do RICMS/2017 - Isenção`,
		PR810104: `Isenção prevista no item 104 do Anexo V do RICMS/2017 - Isenção`,
		PR810105: `Isenção prevista no item 105 do Anexo V do RICMS/2017 - Isenção`,
		PR810106: `Isenção prevista no item 106 do Anexo V do RICMS/2017 - Isenção`,
		PR810107: `Isenção prevista no item 107 do Anexo V do RICMS/2017 - Isenção`,
		PR810108: `Isenção prevista no item 108 do Anexo V do RICMS/2017 - Isenção`,
		PR810109: `Isenção prevista no item 109 do Anexo V do RICMS/2017 - Isenção`,
		PR810110: `Isenção prevista no item 110 do Anexo V do RICMS/2017 e no art. 1º da Lei nº 15.457/2007 - Isenção`,
		PR810111: `Isenção prevista no item 111 do Anexo V do RICMS/2017 e no art. 1º da Lei nº 17.557/2013 - Isenção`,
		PR810112: `Isenção prevista no item 112 do Anexo V do RICMS/2017 - Isenção`,
		PR810113: `Isenção prevista no item 113 do Anexo V do RICMS/2017 - Isenção`,
		PR810114: `Isenção prevista no item 114 do Anexo V do RICMS/2017 - Isenção`,
		PR810115: `Isenção prevista no item 115 do Anexo V do RICMS/2017 - Isenção`,
		PR810116: `Isenção prevista no item 116 do Anexo V do RICMS/2017 - Isenção`,
		PR810117: `Isenção prevista no item 117 do Anexo V do RICMS/2017 - Isenção`,
		PR810118: `Isenção prevista no item 118 do Anexo V do RICMS/2017 - Isenção`,
		PR810119: `Isenção prevista no item 119 do Anexo V do RICMS/2017 - Isenção`,
		PR810120: `Isenção prevista no item 120 do Anexo V do RICMS/2017 - Isenção`,
		PR810121: `Isenção prevista no item 121 do Anexo V do RICMS/2017 - Isenção`,
		PR810122: `Isenção prevista no item 122 do Anexo V do RICMS/2017 - Isenção`,
		PR810123: `Isenção prevista no item 123 do Anexo V do RICMS/2017 - Isenção`,
		PR810124: `Isenção prevista no item 124 do Anexo V do RICMS/2017 - Isenção`,
		PR810125: `Isenção prevista no item 125 do Anexo V do RICMS/2017 - Isenção`,
		PR810126: `Isenção prevista no item 126 do Anexo V do RICMS/2017 - Isenção`,
		PR810127: `Isenção prevista no item 127 do Anexo V do RICMS/2017 - Isenção`,
		PR810128: `Isenção prevista no item 128 do Anexo V do RICMS/2017 - Isenção`,
		PR810129: `Isenção prevista no item 129 do Anexo V do RICMS/2017 - Isenção`,
		PR810130: `Isenção prevista no item 130 do Anexo V do RICMS/2017 - Isenção`,
		PR810131: `Isenção prevista no item 131 do Anexo V do RICMS/2017 - Isenção`,
		PR810132: `Isenção prevista no item 132 do Anexo V do RICMS/2017 - Isenção`,
		PR810133: `Isenção prevista no item 133 do Anexo V do RICMS/2017 - Isenção`,
		PR810134: `Isenção prevista no item 134 do Anexo V do RICMS/2017 - Isenção`,
		PR810135: `Isenção prevista no item 135 do Anexo V do RICMS/2017 - Isenção`,
		PR810136: `Isenção prevista no item 136 do Anexo V do RICMS/2017 - Isenção`,
		PR810137: `Isenção prevista no item 137 do Anexo V do RICMS/2017 - Isenção`,
		PR810138: `Isenção prevista no item 138 do Anexo V do RICMS/2017 - Isenção`,
		PR810139: `Isenção prevista no item 139 do Anexo V do RICMS/2017 - Isenção`,
		PR810140: `Isenção prevista no item 140 do Anexo V do RICMS/2017 - Isenção`,
		PR810141: `Isenção prevista no item 141 do Anexo V do RICMS/2017 - Isenção`,
		PR810142: `Isenção prevista no item 142 do Anexo V do RICMS/2017 - Isenção`,
		PR810143: `Isenção prevista no item 143 do Anexo V do RICMS/2017 - Isenção`,
		PR810144: `Isenção prevista no item 144 do Anexo V do RICMS/2017 - Isenção`,
		PR810145: `Isenção prevista no item 145 do Anexo V do RICMS/2017 - Isenção`,
		PR810146: `Isenção prevista no item 146 do Anexo V do RICMS/2017 - Isenção`,
		PR810147: `Isenção prevista no item 147 do Anexo V do RICMS/2017 - Isenção`,
		PR810148: `Isenção prevista no item 148 do Anexo V do RICMS/2017 - Isenção`,
		PR810149: `Isenção prevista no item 149 do Anexo V do RICMS/2017 - Isenção`,
		PR810150: `Isenção prevista no item 150 do Anexo V do RICMS/2017 - Isenção`,
		PR810151: `Isenção prevista no item 151 do Anexo V do RICMS/2017 - Isenção`,
		PR810152: `Isenção prevista no item 152 do Anexo V do RICMS/2017 - Isenção`,
		PR810153: `Isenção prevista no item 153 do Anexo V do RICMS/2017 - Isenção`,
		PR810154: `Isenção prevista no item 154 do Anexo V do RICMS/2017 - Isenção`,
		PR810155: `Isenção prevista no item 155 do Anexo V do RICMS/2017 - Isenção`,
		PR810156: `Isenção prevista no item 156 do Anexo V do RICMS/2017 - Isenção`,
		PR810157: `Isenção prevista no item 157 do Anexo V do RICMS/2017 - Isenção`,
		PR810158: `Isenção prevista no item 158 do Anexo V do RICMS/2017 - Isenção`,
		PR810159: `Isenção prevista no item 159 do Anexo V do RICMS/2017 - Isenção`,
		PR810160: `Isenção prevista no item 160 do Anexo V do RICMS/2017 - Isenção`,
		PR810161: `Isenção prevista no item 161 do Anexo V do RICMS/2017 - Isenção`,
		PR810162: `Isenção prevista no item 162 do Anexo V do RICMS/2017 - Isenção`,
		PR810163: `Isenção prevista no item 163 do Anexo V do RICMS/2017 - Isenção`,
		PR810164: `Isenção prevista no item 164 do Anexo V do RICMS/2017 - Isenção`,
		PR810165: `Isenção prevista no item 165 do Anexo V do RICMS/2017 - Isenção`,
		PR810166: `Isenção prevista no item 166 do Anexo V do RICMS/2017 - Isenção`,
		PR810167: `Isenção prevista no item 167 do Anexo V do RICMS/2017 - Isenção`,
		PR810168: `Isenção prevista no item 168 do Anexo V do RICMS/2017 - Isenção`,
		PR810169: `Isenção prevista no item 169 do Anexo V do RICMS/2017 - Isenção`,
		PR810170: `Isenção prevista no item 170 do Anexo V do RICMS/2017 - Isenção`,
		PR810171: `Isenção prevista no item 171 do Anexo V do RICMS/2017 - Isenção`,
		PR810172: `Isenção prevista no item 172 do Anexo V do RICMS/2017 - Isenção`,
		PR810173: `Isenção prevista no item 173 do Anexo V do RICMS/2017 - Isenção`,
		PR810174: `Isenção prevista no item 174 do Anexo V do RICMS/2017 - Isenção`,
		PR810175: `Isenção prevista no item 175 do Anexo V do RICMS/2017 - Isenção`,
		PR810176: `Isenção prevista no item 58-A do Anexo V do RICMS/2017 - Isenção`,
		PR810177: `Isenção prevista no item 104-A do Anexo V do RICMS/2017 e no art. 1º da Lei nº 19.595/2018 - Isenção`,
		PR810178: `Isenção prevista no item 108-A do Anexo V do RICMS/2017 - Isenção`,
		PR810179: `Isenção prevista no item 125-A do Anexo V do RICMS/2017 - Isenção`,
		PR810180: `Isenção prevista no item 143-A do Anexo V do RICMS/2017 - Isenção`,
		PR810181: `Isenção prevista no item 143-B do Anexo V do RICMS/2017 - Isenção`,
		PR810182: `Isenção prevista no item 143-C do Anexo V do RICMS/2017 - Isenção`,
		PR810183: `Isenção prevista no item 144-A do Anexo V do RICMS/2017 - Isenção`,
		PR810184: `Isenção prevista no §3º do art. 549 do RICMS/2017 - Isenção`,
		PR810185: `Isenção prevista no item 1-A do Anexo V do RICMS/2017 - Isenção`,
		PR810186: `Isenção prevista no item 5-A do Anexo V do RICMS/2017 - Isenção`,
		PR810187: `Isenção prevista no item 23-A do Anexo V do RICMS/2017 - Isenção`,
		PR810188: `Isenção prevista no item 84-A do Anexo V do RICMS/2017 - Isenção`,
		PR810189: `Isenção prevista no item 138-A do Anexo V do RICMS/2017 - Isenção`,
		PR810190: `Isenção prevista no item 151-A do Anexo V do RICMS/2017 - Isenção`,
		PR810191: `Isenção prevista no item 164-A do Anexo V do RICMS/2017 - Isenção`,
		PR810192: `Isenção prevista no item 167-A do Anexo V do RICMS/2017 - Isenção`,
		PR810193: `Isenção prevista no item 174-A do Anexo V do RICMS/2017 - Isenção`,
		PR810194: `Isenção prevista no item 174-B do Anexo V do RICMS/2017 - Isenção`,
		PR810195: `Isenção prevista no item 174-C do Anexo V do RICMS/2017 - Isenção`,
		PR810196: `Isenção prevista no item 174-D do Anexo V do RICMS/2017 - Isenção`,
		PR810197: `Isenção prevista no item 174-E do Anexo V do RICMS/2017 - Isenção`,
		PR819998: `Isenção sem código específico - Isenção`,
		PR819999: `Isenção prevista em regime especial e/ou programa de incentivo - Redução de base de cálculo`,
		PR820000: `Redução de base de cálculo do imposto reconhecida judicialmente - Redução de base de cálculo`,
		PR820001: `Redução de base de cálculo prevista no item 1 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820002: `Redução de base de cálculo prevista no item 2 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820003: `Redução de base de cálculo prevista no item 3 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820004: `Redução de base de cálculo prevista no item 4 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820005: `Redução de base de cálculo prevista no item 5 do Anexo VI do RICMS/2017 -Redução de base de cálculo`,
		PR820006: `Redução de base de cálculo prevista no item 6 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820007: `Redução de base de cálculo prevista no item 7 do Anexo VI do RICMS/2017 e no art. 1º da Lei nº 15.182/2006 - Redução de base de cálculo`,
		PR820008: `Redução de base de cálculo prevista no item 8 do Anexo VI do RICMS/2017 - Redução de base decálculo`,
		PR820009: `Redução de base de cálculo prevista no item 9 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820010: `Redução de base de cálculo prevista no item 10 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820011: `Redução de base de cálculo prevista no item 11 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820012: `Redução de base de cálculo prevista no item 12 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820013: `Redução de base de cálculo prevista no item 13 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820014: `Redução de base de cálculo prevista no item 14 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820015: `Redução de base de cálculo prevista no item 15 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820016: `Redução de base de cálculo prevista no item 16 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820017: `Redução de base de cálculo prevista no item 17 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820018: `Redução de base de cálculo prevista no item 18 do Anexo VI do RICMS/2017 e no inciso V do art. 5º da Lei nº 13.212/2001 - Redução de base de cálculo`,
		PR820019: `Redução de base de cálculo prevista no item 19 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820020: `Redução de base de cálculo prevista no item 20 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820021: `Redução de base de cálculo prevista no item 21 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820022: `Redução de base de cálculo prevista no item 22 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820023: `Redução de base de cálculo prevista no item 23 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820024: `Redução de base de cálculo prevista no item 24 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820025: `Redução de base de cálculo prevista no item 25 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820026: `Redução de base de cálculo prevista no item 26 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820027: `Redução de base de cálculo prevista no item 27 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820028: `Redução de base de cálculo prevista no item 28 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820029: `Redução de base de cálculo prevista no item 29 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820030: `Redução de base de cálculo prevista no item 30 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820031: `Redução de base de cálculo prevista no item 31 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820032: `Redução de base de cálculo prevista no item 32 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820033: `Redução de base de cálculo prevista no item 33 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820034: `Redução de base de cálculo prevista no item 34 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820035: `Redução de base de cálculo prevista no item 35 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820036: `Redução de base de cálculo prevista no item 36 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820037: `Redução de base de cálculo prevista no item 37 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820038: `Redução de base de cálculo prevista no item 38 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820039: `Redução de base de cálculo prevista no item 39 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820040: `Redução de base de cálculo prevista no item 40 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820041: `Redução de base de cálculo prevista no item 41 do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820042: `Redução de base de cálculo prevista no item 8-A do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820043: `Redução de base de cálculo prevista no item 36-A do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820044: `Redução de base de cálculo do imposto prevista no inciso II, do art 5º da Lei 13 212/2001 - Redução de base de cálculo`,
		PR820045: `Redução de base de cálculo do imposto prevista no inciso IV, do art. 5º da Lei 13.212/2001 - Redução de base de cálculo`,
		PR820046: `Redução de base de cálculo prevista no item 32-A do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820047: `Redução de base de cálculo prevista no inciso VI, do art. 3º da Lei 13.214/2001 - Redução de base de cálculo`,
		PR820048: `Redução de base de cálculo prevista no inciso I do art. 5º da Lei 13.212/2001 - Redução de base de cálculo`,
		PR820049: `Redução de base de cálculo prevista no inciso II do art. 5º da Lei 13.212/2001 - Redução de base de cálculo`,
		PR820050: `Redução de base de cálculo prevista no inciso IV do art. 5º da Lei 13.212/2001 - Redução de base de cálculo`,
		PR820051: `Redução de base de cálculo prevista no inciso III do art. 3º da Lei 13.214/2001 - Redução de base de cálculo`,
		PR820052: `Redução de base de cálculo prevista no inciso V do art. 3º da Lei 13.214/2001 - Redução de base de cálculo`,
		PR820053: `Redução de base de cálculo prevista no inciso VI do art. 3º da Lei 13.214/2001 - Redução de base de cálculo`,
		PR820054: `Redução de base de cálculo prevista na alínea 'c' do art. 4º da Lei 13.214/2001 - Redução de base de cálculo`,
		PR820055: `Redução de base de cálculo prevista no art. 3º da Lei 13.332/2001 - Redução de base de cálculo`,
		PR820056: `Redução de base de cálculo prevista no art. 3º da Lei 14.747/2005 - Redução de base de cálculo`,
		PR820057: `Redução da base de cálculo prevista no art. 37 do RICMS/2017 - Redução de base de cálculo`,
		PR820058: `Redução da base de cálculo prevista no item 26-A do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820059: `Redução da base de cálculo prevista no item 36-B do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR820060: `Redução da base de cálculo prevista no item 16-A do Anexo VI do RICMS/2017 - Redução de base de cálculo`,
		PR829998: `Redução da base de cálculo sem código específico - Diferimento`,
		PR829999: `Redução de base de cálculo prevista em regime especial e/ou programa de incentivo - Diferimento`,
		PR830000: `Diferimento do pagamento do imposto reconhecido judicialmente - Diferimento`,
		PR830001: `Diferimento previsto no inciso I do “caput” do art. 28 do Anexo VIII do RICMS/2017- Diferimento`,
		PR830002: `Diferimento previsto no inciso II do “caput” do art. 28 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830003: `Diferimento previsto no inciso III do “caput” do art. 28 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830004: `Diferimento previsto no inciso IV do “caput” do art. 28 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830005: `Diferimento previsto no item 1 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830006: `Diferimento previsto no item 2 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830007: `Diferimento previsto no item 3 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830008: `iferimento previsto no subitem 4.1 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830009: `iferimento previsto no subitem 4.2 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830010: `Diferimento previsto no item 5 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830011: `Diferimento previsto no item 6 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830012: `Diferimento previsto no item 7 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830013: `Diferimento previsto no item 8 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830014: `Diferimento previsto no item 9 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830015: `Diferimento previsto no item 10 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830016: `Diferimento previsto no item 11 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830017: `Diferimento previsto no item 12 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830018: `Diferimento previsto no item 13 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830019: `Diferimento previsto no item 14 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830020: `Diferimento previsto no item 15 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830021: `Diferimento previsto no item 16 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830022: `Diferimento previsto no item 17 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830023: `Diferimento previsto no item 18 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830024: `Diferimento previsto no item 19 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830025: `Diferimento previsto no item 20 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830026: `Diferimento previsto no item 21 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830027: `Diferimento previsto no item 22 do “caput” do art. 31 do Anexo VIII do RICMS/2017 e no inciso I do art. 1º da Lei nº 14.895/2005 - Diferimento`,
		PR830028: `Diferimento previsto no item 23 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830029: `Diferimento previsto no item 24 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830030: `Diferimento previsto no item 25 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830031: `Diferimento previsto no item 26 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830032: `Diferimento previsto no subitem 27.1 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830033: `Diferimento previsto no subitem 27.2 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830034: `Diferimento previsto no subitem 27.3 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830035: `Diferimento previsto no item 28 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830036: `Diferimento previsto no item 29 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830037: `Diferimento previsto no item 30 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830038: `Diferimento previsto no item 31 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830039: `Diferimento previsto no item 32 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830040: `Diferimento previsto no item 33 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830041: `Diferimento previsto no item 34 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830042: `Diferimento previsto no item 35 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830043: `Diferimento previsto no item 36 do “caput” do art. 31 do Anexo VIII do RICMS/2017 e no art. 3º da Lei nº 13.212/2001 - Diferimento`,
		PR830044: `Diferimento previsto no item 37 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830045: `Diferimento previsto no item 38 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830046: `Diferimento previsto no item 39 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830047: `Diferimento previsto no item 40 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830048: `Diferimento previsto no item 41 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830049: `Diferimento previsto no item 42 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830050: `Diferimento previsto no item 43 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830051: `Diferimento previsto no item 44 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830052: `Diferimento previsto no item 45 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830053: `Diferimento previsto no item 46 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830054: `Diferimento previsto no item 47 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830055: `Diferimento previsto no item 48 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830056: `Diferimento previsto no item 49 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830057: `Diferimento previsto no item 50 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830058: `Diferimento previsto no item 51 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830059: `Diferimento previsto no item 52 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830060: `Diferimento previsto no item 53 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830061: `Diferimento previsto no item 54 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830062: `Diferimento previsto no item 55 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830063: `Diferimento previsto no item 56 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830064: `Diferimento previsto no item 57 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830065: `Diferimento previsto no item 58 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830066: `Diferimento previsto no item 59 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830067: `Diferimento previsto no item 60 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830068: `Diferimento previsto no item 61 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830069: `Diferimento previsto no item 62 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830070: `Diferimento previsto no item 63 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830071: `Diferimento previsto no item 64 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830072: `Diferimento previsto no item 65 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830073: `Diferimento previsto no item 66 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830074: `Diferimento previsto no item 67 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830075: `Diferimento previsto no item 68 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830076: `Diferimento previsto no item 69 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830077: `Diferimento previsto no item 70 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830078: `Diferimento previsto no item 71 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830079: `Diferimento previsto no item 72 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830080: `Diferimento previsto no item 73 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830081: `Diferimento previsto no item 74 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830082: `Diferimento previsto no item 75 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830083: `Diferimento previsto no item 76 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830084: `Diferimento previsto no item 77 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830085: `Diferimento previsto no item 78 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830086: `Diferimento previsto no item 79 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830087: `Diferimento previsto no item 80 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830088: `Diferimento previsto no item 81 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830089: `iferimento previsto no item 82 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830090: `Diferimento previsto no item 83 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830091: `Diferimento previsto no item 84 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830092: `Diferimento previsto no item 85 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830093: `Diferimento previsto no item 86 do “caput” do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830094: `Diferimento previsto no § 1º do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830095: `Diferimento previsto no § 11 do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830096: `Diferimento previsto no § 17 do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830097: `Diferimento previsto no § 19 do art. 31 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830098: `Diferimento previsto no art. 32 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830099: `Diferimento previsto no art. 39 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830100: `Diferimento previsto no art. 41 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830101: `Diferimento previsto no inciso I do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830102: `Diferimento previsto no inciso II do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830103: `Diferimento previsto no inciso III do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830104: `Diferimento previsto no inciso IV do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830105: `Diferimento previsto no inciso V do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830106: `Diferimento previsto na alínea a do inc. VI do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830107: `Diferimento previsto na alínea b do inc. VI do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830108: `Diferimento previsto no inciso VII do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830109: `Diferimento previsto no inciso VIII do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830110: `Diferimento previsto no inciso IX do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830111: `Diferimento previsto no inciso X do “caput” do art. 42 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830112: `Diferimento previsto no inciso I do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830113: `Diferimento previsto no inciso II do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830114: `Diferimento previsto no inciso III do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830115: `Diferimento previsto no inciso IV do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830116: `Diferimento previsto no inciso V do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830117: `Diferimento previsto no inciso VI do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830118: `Diferimento previsto no inciso VII do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830119: `Diferimento previsto no inciso VIII do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830120: `Diferimento previsto no inciso IX do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830121: `Diferimento previsto no inciso X do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830122: `Diferimento previsto no inciso XI do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830123: `Diferimento previsto no inciso XII do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830124: `Diferimento previsto no inciso XIII do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830125: `Diferimento previsto no inciso XIV do “caput” do art. 44 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830126: `Diferimento previsto na alínea a do inciso I do “caput” do art. 46 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830127: `Diferimento previsto na alínea b do inciso I do “caput” do art. 46 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830128: `Diferimento previsto na alínea a do inciso II do “caput” do art. 46 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830129: `Diferimento previsto na alínea b do inciso II do “caput” do art. 46 do Anexo VIII do RICMS/2017 - Diferimento`,
		PR830130: `Diferimento previsto no art. 6º da Lei 13.212/2001 - Diferimento`,
		PR830131: `Diferimento previsto no art. 459 do RICMS/2017 - Diferimento`,
		PR830132: `Diferimento previsto nos arts. 524 e 530 do RICMS/2017 -Diferimento`,
		PR830133: `Diferimento previsto no art. 60 do Anexo IX do RICMS/2017 - Diferimento`,
		PR830134: `Diferimento previsto no inciso III do art. 3º da Lei 13.212/2001 - Diferimento`,
		PR830135: `Diferimento previsto no art. 10 do Decreto 6.434/2017 - Diferimento`,
		PR830136: `Diferimento previsto no art. 3º do Decreto 1.107/2011 - Diferimento`,
		PR830137: `Diferimento do ICMS conforme Anexo IV, Subanexo, art.14, do RICMS/2017 - Diferimento`,
		PR839998: `Diferimento sem código específico- Diferimento`,
		PR839999: `Diferimento previsto em regime especial e/ou programa de incentivo - Suspensão`,
		PR840000: `Suspensão do pagamento do imposto reconhecida judicialmente - Suspensão`,
		PR840001: `Suspensão prevista no inciso I do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840002: `Suspensão prevista no inciso II do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840003: `Suspensão prevista no inciso III do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840004: `Suspensão prevista no inciso IV do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840005: `Suspensão prevista no inciso V do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840006: `Suspensão prevista no inciso VI do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840007: `Suspensão prevista no inciso VII do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840008: `Suspensão prevista no inciso VIII do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840009: `Suspensão prevista no inciso IX do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840010: `Suspensão prevista no inciso X do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840011: `Suspensão prevista no inciso XI do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840012: `Suspensão prevista no inciso XII do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840013: `Suspensão prevista no inciso XIII do “caput” do art. 1º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840014: `Suspensão prevista no “caput” do art. 2º do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840015: `Suspensão prevista no art. 12-A, da Subseção II, do Anexo VIII do RICMS/2017 - Suspensão`,
		PR840016: `Suspensão prevista no § 6º do art. 448 do RICMS/2017 - Suspensão`,
		PR840017: `Suspensão prevista no art. 458 do RICMS/2017 - Suspensão`,
		PR840018: `Suspensão prevista no art. 460 do RICMS/2017 - Suspensão`,
		PR840019: `Suspensão prevista no art. 468 do RICMS/2017 - Suspensão`,
		PR840020: `Suspensão prevista na alínea 'b' do inciso I do § 1º do art. 497 e alínea 'c' do inciso II do art. 499 do RICMS/2017 - Suspensão`,
		PR840021: `Suspensão prevista no inciso I do 'caput', na alínea 'a' do inciso IV - e no inciso V do § 1º, nas alíneas 'a' e 'b' do inciso I e no inciso II do § 3º do art. 499-C do RICMS/2017 - Suspensão`,
		PR840022: `Suspensão prevista nos arts. 525 e 529 do RICMS/2017 - Suspensão`,
		PR840023: `Suspensão prevista nos art. 532 do RICMS/2017 - Suspensão`,
		PR840024: `Sem destaque do ICMS conforme art. 554 do RICMS/2017 - Suspensão`,
		PR840025: `Suspensão prevista nos arts. 572, 573, § 2º, inciso I, e 576, inciso II, alínea 'a', item 2, do RICMS/2017 - Suspensão`,
		PR840026: `Suspensão prevista na Subseção II da Seção II, do Capítulo I do Anexo VIII do RICMS/2017 -Suspensão`,
		PR840027: `Suspensão prevista no art. 60 do Anexo IX do RICMS/2017 - Suspensão`,
	},
	RS: {
		RS051001: `LIVRO I,9,II -REPRODUTORES OU MATRIZES - ISENÇÃO - Saídas de reprodutores ou matrizes a estabelecimento agropecuário inscrito como contribuinte do imposto`,
		RS051002: `LIVRO I,9,II -REPRODUTORES OU MATRIZES - ISENÇÃO - Saída de embriões ou semem congelado ou resfriado`,
		RS051003: `LIVRO I,9,II -REPRODUTORES OU MATRIZES - ISENÇÃO - Saída de equinos`,
		RS051004: `LIVRO I,9,V -AMOSTRA DIMINUTO OU NENHUM VAL COMER - ISENÇÃO - Saída de amostras de diminuto ou nenhum valor comercial`,
		RS051005: `LIVRO I,9,VI -EXPOSICOES OU FEIRAS-SAIDAS - ISENÇÃO - Saídas de mercadorias com destino a exposições ou feiras`,
		RS051006: `LIVRO I,9,VII -EXPOSICOES OU FEIRAS-RETORNO - ISENÇÃO - Retorno das saídas de mercadorias com destino a exposições ou feiras`,
		RS051007: `LIVRO I,9,VIII -INSUMOS AGROPECUARIOS - ISENÇÃO - Saídas internas de insumos agropecuários`,
		RS051008: `LIVRO I,9,IX -PROD P/ALIM ANIMAL/FABRICACAO RACAO - ISENÇÃO - Saídas internas de produtos para alimentação animal/fabricação de ração animal`,
		RS051009: `LIVRO I,9,X -BULBOS DE CEBOLA - ISENÇÃO - Saídas de bulbos de cebola destinados à produção de sementes`,
		RS051010: `LIVRO I,9,XI -POS-LARVA DE CAMARAO - ISENÇÃO - Saídas de pós-larva de camarão`,
		RS051011: `LIVRO I,9,XII -VASILHAMES,RECIP E EMBALAG-SAIDAS - ISENÇÃO - Saídas de vasilhames, recipientes e embalagens, inclusive sacaria, que devam retornar `,
		RS051012: `LIVRO I,9,XIII -VASILHAMES,RECIP,EMBALAG -RETORNO - ISENÇÃO - Saídas, em retorno ao estabelecimento remetente, de vasilhames, recipientes e embalagens, inclusive sacaria`,
		RS051013: `LIVRO I,9,XIV -BOTIJOES VAZIOS DE GLP - ISENÇÃO - Saídas relacionadas com a destroca de botijões vazios (vasilhames) de gás liquefeito de petróleo - GLP`,
		RS051014: `LIVRO I,9,XV -FORNECIMENTO DE REFEICOES - ISENÇÃO - Fornecimento de refeições aos presos ou aos próprios empregados `,
		RS051016: `LIVRO I,9,XVII -OVOS - ISENÇÃO - Saídas de ovos, exceto quando destinados à indústria`,
		RS051017: `LIVRO I,9,XVIII -FLORES NATURAIS - ISENÇÃO - Saídas de flores naturais`,
		RS051018: `LIVRO I,9,XIX -HORTIFRUTIGRANJEIROS - ISENÇÃO - Saídas de frutas frescas nacionais ou oriundas de países membros da ALADI e as de verduras e hortaliças`,
		RS051019: `LIVRO I,9,XX LEITE PAUSTERIZADO TIPO A, B, C - ISENÇÃO - Saídas internas de leite pasteurizado dos tipos "A", "B" e "C", de varejista com destino a consumidor final`,
		RS051021: `LIVRO I,9,XXIII -"DRAWBACK"-SAIDAS P/BENEFICIAMEN - ISENÇÃO - Saídas, promovidas pelo importador, de mercadorias sob o regime de "drawback" para beneficiamento `,
		RS051022: `LIVRO I,9,XXIV -"DRAWBACK"-RETORNO BENEFICIAMENTO - ISENÇÃO - Saídas em devolução das mercadorias sob o regime "drawback" ou dos produtos resultantes de industrialização`,
		RS051023: `LIVRO I,9,XXV -ZONA FRANCA DE MANAUS - ISENÇÃO - Saídas de produtos nacionais industrializados para comercialização/industrialização na Zona Franca de Manaus`,
		RS051024: `LIVRO I,9,XXVI -AREAS DE LIVRE COMERCIO - ISENÇÃO - Saídas de produtos nacionais industrializados para comercialização/industrialização em Áreas de Livre Comércio`,
		RS051025: `LIVRO I,9,XXVII -OLEO LUBRIFIC USADO CONTAMINADOS - ISENÇÃO - Saídas de óleo lubrificante usado ou contaminado para estabelecimento re-refinador ou coletor revendedor`,
		RS051026: `LIVRO I,9,XXVIII -EMBARCACOES - ISENÇÃO - Saídas de embarcações nacionais, fornecimento de peças/partes juntamente com serviços de reparo de embarcações`,
		RS051027: `LIVRO I,9,XXIX -PROD P/CONSUM EM EMBARC/AERONAVES - ISENÇÃO - Saídas de produtos industrializados para uso/consumo de embarcações/aeronaves estrangeiras aportados no País`,
		RS051028: `LIVRO I,9,XXX -COMBUSTIVEIS P/ EMBARC E AERONAVES - ISENÇÃO - Saídas de combustíveis/lubrificantes para embarcações/aeronaves nacionais com destino ao exterior`,
		RS051030: `LIVRO I,9,XXXII -OBRAS DE E - ISENÇÃO - Saídas de obras de arte decorrentes de operações realizadas pelo próprio autor`,
		RS051031: `LIVRO I,9,XXXVIII -MEDICAMENTOS P/ TRATAM DA AIDS - ISENÇÃO - Saídas de fármacos destinados à produção e de medicamentos para tratamento de HIV`,
		RS051032: `LIVRO I,9,XXXIX -CADEIRA RODAS, PROTESES,AP.AUDIC - ISENÇÃO - Saídas de cadeira de rodas, próteses e aparelhos auditivos, bem como suas partes`,
		RS051033: `LIVRO I,9,XL -VEIC AUTOM P/PORTADORES DEFICIENCIA - ISENÇÃO - Saídas de veículo automotor novo para portadores de deficiência física/visual/mental severa/autistas`,
		RS051034: `LIVRO I,9,XLI -MEDICAMENTOS QUIMIOTERAPICOS - ISENÇÃO - Operações com medicamentos usados no tratamento de câncer relacionados no Apêndice XL`,
		RS051035: `LIVRO I,9,XLVIII -ENERG ELET,VEIC P/MISSAO DIPLOM - ISENÇÃO - Saídas de energia elétrica e de veículos nacionais a Missões Diplomáticas e Repartições Consulares`,
		RS051036: `LIVRO I,9,XLIX -DOACAO ENTIDAD GOVERN OU ASSISTEN - ISENÇÃO - Saídas de mercadorias/prestações de serviço de transporte em doação a entidades governamentais /assistenciais`,
		RS051037: `LIVRO I,9,L -DOACOES EFETUADAS AO GOVERNO ESTADO - ISENÇÃO - Saídas de mercadorias doadas ao Governo do Estado do RS para distribuição gratuita`,
		RS051038: `LIVRO I,9,LX -ORGAOS DA ADMINIST PUBLICA-SAIDAS - ISENÇÃO - Saídas de mercadorias por órgãos da administração pública para fins de industrialização`,
		RS051039: `LIVRO I,9,LXI -ORGAOS DA ADMINIST PUBLICA-RETORNO - ISENÇÃO - Saídas em retorno de mercadorias encaminhadas para industrialização por órgãos da administração pública`,
		RS051040: `LIVRO I,9,LXII -TRAVA-BLOCO P/CONSTR CASA POPULAR - ISENÇÃO - Saídas de trava-blocos para a construção de casas populares promovidas administração pública`,
		RS051041: `LIVRO I,9,LXIII -PRODUTOS FARMACEUTICOS - ISENÇÃO - Saídas de produtos farmacêuticos promovidas por órgãos ou entidades da Administração Pública`,
		RS051042: `LIVRO I,9,LXV -PROG RECUP PORTADOR DE DEFICIENCIA - ISENÇÃO - Saídas vinculadas a programa de recuperação de portador de deficiência a instituições públicas estaduais`,
		RS051043: `LIVRO I,9,LXVII -OBRAS DE ARTESANATO - ISENÇÃO - Saídas de obras de artesanato de artesãos cadastrados na FGTAS, promovidas através de entidades incentivadoras`,
		RS051044: `LIVRO I,9,LXVIII -INSTIT ASSISTENC SOCIAL/EDUCAC - ISENÇÃO - Saídas de mercadorias de produção própria, por instituições de assist. social/educacional, sem fins lucrativos`,
		RS051045: `LIVRO I,9,LXIX -VEIC P/FISCALIZACAO DE TE E PM - ISENÇÃO - Saídas internas de veículos, para o Estado do RS,para reequipamento da Fiscalização de Tributos Estaduais e PM`,
		RS051046: `LIVRO I,9,LXX -DOACAO A SECRETARIA DA EDUCACAO - ISENÇÃO - Saídas internas de doações à rede oficial de ensino, por contribuintes do imposto, à Secretaria da Educação RS`,
		RS051047: `LIVRO I,9,LXXI -CENTRO FORM DE RH DO SISTEM SENAI - ISENÇÃO - Saídas de mercadorias para o reequipamento dos Centros de Formação de RH do Sistema SENAI, em razão de doação`,
		RS051049: `LIVRO I,9,LXXIII -VEIC P/CORPOS BOMBEIROS VOLUNT - ISENÇÃO - Saídas internas/desemb.aduaneiro de veículos automotores/máq./equip. para o Corpos de Bombeiros Voluntários`,
		RS051050: `LIVRO I,9,LXXIV -CAVALOS DOADOS A BRIGADA MILITAR - ISENÇÃO - Saídas internas de cavalos doados à Brigada Militar do RS destinados à utilização em patrulhamento`,
		RS051051: `LIVRO I,9,LXXV -PROMOFAZ - ISENÇÃO - Saídas e recebimentos de mercadorias adquiridas em licitações/contratações destinadas ao PROMOFAZ`,
		RS051052: `LIVRO I,9,LXXVI -VEICULOS DE BOMBEIROS - ISENÇÃO - Saídas de veículos de bombeiros para equipar os aeroportos nacionais, adquiridos pelo Ministério da Defesa`,
		RS051054: `LIVRO I,9,LXXIX -TAXI - ISENÇÃO - Saídas promovidas por fabricante ou revendedor autorizado, de automóveis novos de passageiros para taxistas`,
		RS051057: `LIVRO I,9,LXXXIV -PRESERVATIVOS - ISENÇÃO - Operações com preservativos classificados no código 4014.10.00 da NBM/SH-NCM`,
		RS051058: `LIVRO I,9,LXXXV -EQUIP P/ENERGIAS SOLAR E EOLICA - ISENÇÃO - Operações com equipamentos, partes e peças para energia solar e eólica`,
		RS051059: `LIVRO I,9,LXXXVI -"FREE SHOPS" - ISENÇÃO - Saídas promovidas/destinadas por/a "free shops" instaladas nas zonas primárias dos aeroportos internacionais`,
		RS051060: `LIVRO I,9,LXXXVII -EQUIP DIDAT,CIENT,MEDICO-HOSP - ISENÇÃO -  Operações que destinem equipamentos didáticos, científicos e médico-hospitalares ao MEC`,
		RS051061: `LIVRO I,9,LXXXVIII -OLEO DIESEL P/EMBARC PESQUEIRA - ISENÇÃO - Saídas internas de óleo diesel para embarcações pesqueiras nacionais, por distribuidora de combustível`,
		RS051062: `LIVRO I,9,LXXXIX-PROJ INT EXPL AGR PEC IND RORAIMA - ISENÇÃO - Saídas a contribuintes do Projeto Integrado de Exploração Agropecuária e Agroindustrial do Estado de Roraima`,
		RS051063: `LIVRO I,9,XC -ATIVO IMOBILIZADO - EMBRAPA - ISENÇÃO - Saídas de animais à EMBRAPA para fins de inseminação e inovulação com animais de raça, e respectivo retorno`,
		RS051064: `LIVRO I,9,XCII -DOACOES P/ASSISTENCIA VITIMAS SECA - ISENÇÃO - Saídas de mercadorias/serviços de transporte em doação a administração direta e indireta para vítimas de seca`,
		RS051065: `LIVRO I,9,XXXV -PROGRAMA BEFIEX - ISENÇÃO - Saídas de máq./equip. ao ativo imobilizado de empresa industrial que os tenha adquirido pelo Programa BEFIEX`,
		RS051067: `LIVRO I,9,XCVI-ZONAS PROCESSAMENTO EXPORT-ZPE - ISENÇÃO - Saídas internas de mercadorias e bens previstos na Lei nº 11.508/07 a estabelecimento localizado em ZPE`,
		RS051068: `LIVRO I,9,XCVII - MENSAGEIRO DA CARIDADE - ISENÇÃO - Saídas de mercadorias pelo Secretariado de Ação Social Arquidiocese de PoA, programa "Mensageiro da Caridade"`,
		RS051069: `LIVRO I,9,XCVIII-EQUIP.PRESTACAO SERV.SAUDE - ISENÇÃO - Operações com equipamentos e insumos destinados à prestação de serviços de saúde, relacionados no Apêndice XIX`,
		RS051072: `LIVRO I,9,CI -IMP.MERC.OU BENS REGIME ESP ADU ADM - ISENÇÃO - Devolução de bem cuja entrada tenha ocorrido sob amparo de regime especial aduaneiro de admissão temporária.`,
		RS051075: `LIVRO I,9,CIV-EQUIP MED HOSPIT.P/MINIST.SAUDE - ISENÇÃO - Operações com equipamentos médico-hospitalares destinados ao Ministério da Saúde`,
		RS051079: `LIVRO I,9,CVIII-EMB.VAZIAS DE AGROTOX. E TAMPAS - ISENÇÃO - Operações de devolução impositiva de embalagens vazias de agrotóxicos e respectivas tampas,realizadas sem ônus`,
		RS051081: `LIVRO I,9,CX ARRENDAMENTO MERCANTIL - ISENÇÃO - Operações de venda do bem arrendado ao arrendatário, desde que este seja contribuinte do imposto`,
		RS051082: `LIVRO I,9,CXI-PROD.ALIM.CONSIDERADOS PERDAS P/ - ISENÇÃO - Saídas de produtos alimentícios considerados "perdas" a banco de alimentos para pessoas carentes`,
		RS051083: `LIVRO I,9,CXII-PROD.ALIM.CONSIDRADOS PERDAS RE - ISENÇÃO - Saídas promovidas por bancos de alimentos a pessoas carentes`,
		RS051085: `LIVRO I,9,CXIV-MEDICAMENTOS - ISENÇÃO - Operações com medicamentos relacionados no RICMS, Livro I, art. 9º, inciso CXIV`,
		RS051086: `LIVRO I,9,CXV - FARMACOS E MEDICAMENTOS P/ORG - ISENÇÃO - Operações com os fármacos e medicamentos relacionados no Apêndice XXIII, a órgãos da administração pública`,
		RS051087: `LIVRO I,9,CXVI - PROG.SEGURANÇA ALIMENTAR E NUTRICIONAL - ISENÇÃO - Saídas de mercadorias/prest.de serviços de transporte, nas doações ao Prog.de Seg. Alimentar e Nutricional`,
		RS051091: `LIVRO I,9,CXX-PROD.FARMAC,INSTRUM E APARELHOS - ISENÇÃO - Saídas internas de mercadorias listadas no RICMS, Lv.I,art.9º,inciso CXX  a Admin.Pública Est.e Legis. e Jud.`,
		RS051095: `LIVRO I,9, CXXIV - MA-AS E PERAS - ISENÇÃO - Saídas de maçãs e peras, desde que frescas`,
		RS051096: `LIVRO I,9, CXXV - PAO FRANCES - ISENÇÃO - Saídas internas de pão francês e massa congelada destinada ao preparo de pão francês`,
		RS051097: `LIVRO I,9,CXXVI -TIJOLOS DE CERAMICA - ISENÇÃO - Saídas internas de tijolos de cerâmica, exceto refratários, classificados no código 6904.10.00 da NBM/SH-NCM`,
		RS051098: `LIVRO I,9,CXXVII -SUBVENCAO DE ENERGIA ELETRICA FO - ISENÇÃO - Saídas internas de energia elétrica na parcela da subvenção a consumidores da "Baixa Renda"`,
		RS051099: `LIVRO I,9,CXXVIII -PILHAS E BATERIAS USADAS - ISENÇÃO - Saídas de pilhas e baterias usadas para reciclagem ou disposição final ambientalmente adequada`,
		RS051100: `LIVRO I,9,CXXIX-PROD FARMAC DA FIOCRUZ - ISENÇÃO - Saídas de produtos farmacêuticos/fraldas geriátricas da  FIOCRUZ à Farmácia Popular`,
		RS051101: `LIVRO I,9,CXXX-SANDUICHES BIG MAC - ISENÇÃO - Saídas de "Big Mac" pela Rede McDonald's, na data do evento "McDia Feliz`,
		RS051102: `LIVRO I,9,CXXXI-EQUIPAMENTOS DE MEDICAO DE VAZAO - ISENÇÃO - Saídas de medidores de vazão,condutivímetros a estab. industriais fabricantes de prod.2202/2203 da NBM/SH-NCM;`,
		RS051103: `LIVRO I,9,CXXXII-SELOS PARA CONTROLE FISCAL FEDERAL - ISENÇÃO - Saídas de selos destinados ao controle fiscal federal, promovidas pela Casa da Moeda do Brasil`,
		RS051105: `LIVRO I,9,CXXXIV-REPORTO-SAIDAS INT. AT.IMOB. - ISENÇÃO - Saídas internas de bens relacionados no Apêndice XXVII,  ao ativo imobilizado de empresas do REPORTO`,
		RS051106: `LIVRO I,9,CXXXV-PRODUTOS MANUT.GAS.BR.BOLIVRO IA - ISENÇÃO - Transferências,no território nacional, de bens do Ap.XXVIII, à manutenção do Gasoduto Brasil-Bolívia, pela TBG`,
		RS051107: `LIVRO I,9,CXXXVI-OPER. COM EMISSAO CDA E WA - ISENÇÃO - Operações de mercadorias com emissão/negociação do Cert. de Depósito Agropec. - CDA e do Warrant Agropec. - WA`,
		RS051108: `LIVRO I,9,CXXXVII-CIMENTO ASFALTICO DE PETROL - ISENÇÃO - Operações com cimento asfáltico de petróleo classificado no código 2713.20.00 da NBM/SH-NCM`,
		RS051109: `LIVRO I,9CXXXVIII-PES E PE-AS DEF.,SUB.GAR., - ISENÇÃO - Saídas de partes/peças defeituosas,em garantia,por oficina autorizada a fabricante de veículos autopropulsados`,
		RS051112: `LIVRO I,9,CXLI-ONIB,MICROONIB E EMB P TRANSP ESCO - ISENÇÃO - Operações com ônibus, micro-ônibus e embarcações, para transporte escolar, adquiridos por Estados, DF e Munic.`,
		RS051115: `LIVRO I,9,CXLIV-REAGENTE P DIAGNOST DOENCA CHAGAS - ISENÇÃO - Saídas de reagente para diagnóstico da Doença de Chagas, código 3002.10.29 da NCM, para Administração Pública`,
		RS051116: `LIVRO I,9,CXLV-PES/PECAS DEF.,SUBS.GAR..EXC.AT. - ISENÇÃO - Saídas de partes/peças defeituosas, em garantia, por oficina credenciada destinadas ao fabricante`,
		RS051118: `LIVRO I,9,CXLVII.OLEO COMESTIVEL USADO - ISENÇÃO - Saídas de óleo comestível usado destinado à utilização como insumo industrial`,
		RS051122: `LIVRO I, 9,CLI. GARANTIA-FABR.PROD.AERONAUT. - ISENÇÃO - Saídas de partes/peças defeituosas de produtos aeronáuticos, em garantia, da oficina para o fabricante`,
		RS051123: `LIVRO I, 9,CLII.GARANTIA-COMER.PROD.AERONAUT. - ISENÇÃO - Saídas de partes/peças defeituosas de produtos aeronáuticos, em garantia, do fabricante para oficina`,
		RS051129: `LIVRO I,9,CLVIII.PNEUS USADOS P/RECICLAGEM - ISENÇÃO - Saídas de pneus usados para reciclagem, tratamento ou disposição final ambientalmente adequada`,
		RS051130: `LIVRO I,9,CLIX.EQUIP.DE SEGURANCA ELETRONICA - ISENÇÃO - Operações/prestações na compra de equip.de segurança eletrônica pelo Depto.Penit. Nac. CNPJ 00.394.494/0008-02`,
		RS051131: `LIVRO I,9,CLX.MAT.E EQUIP.USO DOS ESCOTEIROS - ISENÇÃO - Fornecimento pela União dos Escoteiros do Brasil, de materiais/equiptos. de uso dos escoteiros a associados`,
		RS051132: `LIVRO I,9,CLXI.FOSF.DE OSELTAMIVIR,PAC.GRIPE A - ISENÇÃO - Operações com fosfato de oseltamivir vinculadas ao Farmácia Popular do Brasil ao tratamento da Gripe A (H1N1)`,
		RS051135: `LIVRO I,9,CLXVI.MERC.FUND.PAO DOS POBRES.S.ANT - ISENÇÃO - Saídas internas de mercadorias promovidas pela Fundação O Pão dos Pobres de Santo Antônio, CGC/TE 096/0217657`,
		RS051136: `LIVRO I,9,CLXVIII.REPR.CAMARAO MARINHO - ISENÇÃO - Saídas de reprodutores de camarão marinho produzidos no País`,
		RS051137: `LIVRO I,9,CLXXII.BENS/MERC.ATV.EXP.PET./GAS NAT. - ISENÇÃO - Saídas,ao exterior,dos bens/merc.que serão importados, utiliz.em ativ.de expl./prod. de petróleo/gás natural`,
		RS051138: `LIVRO I,9,CLXXIV.GEN.AL.REG.P/MER.ESC.-PRONAF - ISENÇÃO - Saídas internas de gên.alimentícios regionais p/merenda escolar por produtores rurais do Pronaf`,
		RS051142: `LIVRO I,9,CLXXXI.MERC.CONST.CONS.MOD.REP.EMBARC. - ISENÇÃO - Saídas de mercadorias por fabricante, às embarcações utilizadas no serviço de transporte aquaviário de cargas`,
		RS051143: `LIVRO I,9,CLXXXIII.GADO VACUM P/TESTE VAC.FEB.AFT. - ISENÇÃO - Operações internas/serviço de transporte com gado vacum a ser utilizado em testes de vacinas para febre aftosa`,
		RS051145: `LIVRO I,9,CLXXXV.MAQ./EQ.IND.P/GER.HIDRELETRICAS - ISENÇÃO - Saídas internas das máquinas, aparelhos e equipamentos industriais, constantes do Apêndice XLII`,
		RS051146: `LIVRO I,9,CLXXXVI.CINZAS DE CASCA DE ARROZ - ISENÇÃO - Operações internas e as correspondentes prestações de serviço de transporte com cinzas de casca de arroz`,
		RS051148: `LIVRO I,9,CXC.MERC.DEST.A ITAIPU BINACIONAL - ISENÇÃO - Saídas de mercadorias, decorrentes de vendas, destinadas à Itaipu Binacional`,
		RS051149: `LIVRO I, 9,CXCI.ACEL.LIMEARES - ISENÇÃO - Operações com aceleradores lineares, classificados no código 9022.21.90 da NBM/SH-NCM`,
		RS051153: `LIVRO I,9, CXCV. ARROZ ORG.P/ENSINO PUBLICO - ISENÇÃO - Saídas interestaduais de arroz orgânico à merenda escolar da rede públ.de ensino, por coop.produtores - Pronaf`,
		RS051155: `LIVRO I,9,CXCVII.MERC.IMP.UTE PAMPA SUL - ISENÇÃO - Código encerrado`,
		RS051156: `LIVRO I,9,CXCVIII. ENERGIA ELET.MICRO/MINIGER - ISENÇÃO - Saídas internas de energia elétrica por distribuidora à unidade consumidora (Sistema Compensação de Energia)`,
		RS051157: `LIVRO I,9, CXCIX. EQUIPAMENTOS P/ SECR.SEGUR. - ISENÇÃO - Saídas internas de armas,coletes a prova de bala,EPIs,munições,veículos e equip. videomonit.em doação à SSP/RS`,
		RS051159: `LIVRO I.9, CCI. MEDIC.SPINRAZA - AME - ISENÇÃO - Operações com o medicamento Spinraza (Nusinersena) injection 12mg/5ml, destinado a tratamento da AME`,
		RS051160: `LIVRO I,9, CCIII. REPETRO-SPED - ISENÇÃO - Saídas por exportação de bens/mercadorias fabricados no país que serão admitidos/adquiridos nos termos Repetro`,
		RS051161: `LIVRO I,9, CCV. PRODUTOS P/ MONTAGEM DE KITS DIAGNÓSTICOS - ISENÇÃO - Saídas internas de produtos para montagem de Kits diagnósticos para detecção imuno-rápida`,
		RS051162: `LIVRO I,9,CCVII. REMESSA EXPRESSA DEV EXTERIOR - ISENÇÃO - Remessa  expressa  internacional  devolvida  ao  exterior`,
		RS051164: `LIVRO I,9,LXXXI.SISTEMA DE INFORMATICA/SEFAZ - ISENÇÃO - Saídas internas, de mercadorias destinadas à ampliação do Sistema de Informática da Secretaria da Fazenda`,
		RS051165: `LIVRO I,9,CCVIII.MEDIC.ZOLGENSMA - TRAT. AME - ISENÇÃO - Operações com o medicamento Zolgensma, destinado a tratamento da AME`,
		RS051167: `L.I,ART.9,CCX.VACINAS E INSUMOS P/VACINA SARS-CoV-2 - ISENÇÃO - Operações com vacina e insumos para enfrentamente ao Coronavírus, e prest.de serviços de transporte corresp.`,
		RS051169: `L.I,ART.9,CCXII.EQUIP.RESP.ELMO,PARTES E PEÇAS - ISENÇÃO - Saídas a prestadoras de serviço de saúde, doações, bem como prestação de serviço de transporte.`,
		RS051170: `L.I,ART.9,CCXIII.KITS TESTE COVID-19 E APARELHOS RESPIRATÓRIOS - ISENÇÃO - Saídas internas kits teste Covid-19 (3002.15.90 e 3822.0090) e aparelhos respiratórios (9019.20 e 9020.00)`,
		RS051171: `L.I,ART.9,CCXIV.OXIGENIO MEDICINAL - ISENÇÃO - Saídas internas e interestaduais em relação às UFs que especifica bem como nas prest.de serviço de transporte`,
		RS051172: `L.I,ART.9,CCXVI. MEDIC.RISDIPLAM - TRAT. AME - ISENÇÃO - Operações com o medicamento Risdiplam, destinado a tratamento da AME, NCMs 3003.90.99 e 3004.90.99.`,
		RS051173: `L.I,ART.9,CCXVIII.PROG.DESENV.SUBMARINOS - PROSUB - ISENÇÃO - Operações Operações  com  mat.prima,  partes,  peças,  máq./equip., prest. de  serv. de  transporte - PROSUB`,
		RS051174: `L.I,ART.9,CCXIX. LOCOMOTIVAS E VAGÕES - ISENÇÃO - Saídas de merc. de NCM 8601,8602,8603,8604.00 e 8605.00 às redes de transp.públ.sobre trilhos de passageiros`,
		RS051175: ` 175 L.I,ART.9,CCXX.IRRIGADORES E SISTEMAS DE IRRIGAÇÃO - ISENÇÃO - Operações internas com mercadorias classificadas nos códigos 8424.82.21 e 8424.82.29 da NBM/SH-NCM`,
		RS051176: `L.I,ART.9,CCXXI.MEDIC. EM DOACAO A ENT. BENEF. - ISENÇÃO - Operações com medicamentos relativas a doações com destino a entidades beneficentes que atuem na área da saúde`,
		RS051177: `L.I,ART.9,CCXXII.MEDIC. P/ TRAT. FIBROSE CIST. - ISENÇÃO - Operações com medicamentos com NCM 3004.90.69, destinados ao tratamento da Fibrose Cística`,
		RS051400: `LIVRO I,10,I -TELECOMUNICACAO P/ ADM PUBLICA - ISENÇÃO - Prestação de serviço de telecomunicação para administraçao pública `,
		RS051401: `LIVRO I,10,II -TELECOMUNIC P/ MISSOES DIPLOMATICAS - ISENÇÃO - Prestação de serviço de telecomunicação para Missões Diplomáticas e Repartições Consulares `,
		RS051403: `LIVRO I,10,IV -DIFUSAO SONORA - ISENÇÃO - Prestação de serviço locais de difusão sonora`,
		RS051404: `LIVRO I,10,V -TAXI - ISENÇÃO - Prestação de serviço de transporte rodoviário de pessoas, realizadas táxi`,
		RS051405: `LIVRO I,10,VI -TRANSPORTE DE CALCARIO - ISENÇÃO - Prestação de serviço de transporte de calcário, interna, vinculada a programas de preservação ambiental do RS`,
		RS051406: `LIVRO I,10,VII -TRANSPORTE FERROVIARIO DE CARGA - ISENÇÃO - Prestação de serviço de transp. ferroviário de carga,na exp/imp de países do "Acordo sobre o Transp. Intern."`,
		RS051407: `LIVRO I,10,VIII -TRANSPORTE DE MERCADORIA-PROMOFAZ - ISENÇÃO - Prestação de serviço de transporte de mercadorias adquiridas em licitações/contratações destinadas ao PROMOFAZ`,
		RS051408: `LIVRO I,10,IX-TRANSP CARGA A CONTR.INSC NO CGC - ISENÇÃO - Prestação de serviço de transporte intermunicipal de cargas realizadas a contribuinte inscrito no CGC/TE`,
		RS051409: `LIVRO I,10,X.GESAC - ISENÇÃO - Prestação de serviço de comunicação referentes a acesso à internet e conectividade em banda larga do GESAC`,
		RS051413: `LV.I,ART.10,XIV.TRANSP.PROD.ELETR.LOG.REVERSA - ISENÇÃO - Prestação de serviço de transp.na logística reversa de prods.eletrônicos usados, rol no DF 10240/20, Anexo I`,
		RS051500: `LIVRO I,11,I -PAPEL P/JORNAIS, PERIODICOS E LIVROS - NÃO INCIDÊNCIA DO ICMS - Saídas de papel destinado exclusivamente à impressão de jornais, periódicos e livros`,
		RS051501: `LIVRO I,11,II -JORNAIS, PERIODICOS E LIVROS - NÃO INCIDÊNCIA DO ICMS - Saídas de jornais, periódicos e livros, excluídos os livros em branco ou para escrituração`,
		RS051502: `LIVRO I,11,III -ENERG ELET,PETROLEO,COMB-INTEREST - NÃO INCIDÊNCIA DO ICMS - Operações interestaduais de energia elétrica e petróleo, lubrif. e combust. dele derivados`,
		RS051503: `LIVRO I,11,IV -OURO COMO ATIVO FINANCEIRO - NÃO INCIDÊNCIA DO ICMS - Operações com ouro, quando definido em lei como ativo financeiro ou instrumento cambial`,
		RS051504: `LIVRO I,11,V -EXPORTACOES - NÃO INCIDÊNCIA DO ICMS - Operações e prestações que destinem mercadorias ao exterior `,
		RS051505: `LIVRO I,11,VI -MERC P/UTILI PREST SERV SUJEITA ISS - NÃO INCIDÊNCIA DO ICMS - Operações relativas a mercadorias sujeita ao ISS, de competência dos Municípios`,
		RS051506: `LIVRO I,11,VII -TRANSF PROPRIEDADE ESTABELECIMENTO - NÃO INCIDÊNCIA DO ICMS - Operações de qualquer natureza de que decorra a transferência de propriedade de estabelecimento`,
		RS051507: `LIVRO I,11,VIII -ALIENACAO FIDUCIARIA EM GARANTIA - NÃO INCIDÊNCIA DO ICMS - Operações decorrentes de alienação fiduciária em garantia`,
		RS051508: `LIVRO I,11,IX -ARRENDAMENTO MERCANTIL - NÃO INCIDÊNCIA DO ICMS - Operações de arrendamento mercantil, não compreendida a venda do bem arrendado ao arrendatário`,
		RS051509: `LIVRO I,11,X -SALVADOS DE SINISTRO - NÃO INCIDÊNCIA DO ICMS - Operações decorrentes da transferência de bens móveis salvados de sinistro a seguradoras`,
		RS051510: `LIVRO I,11,XI -ARMAZEM-GERAL - NÃO INCIDÊNCIA DO ICMS - Saídas de mercadorias a armazém-geral situado neste Estado, para depósito em nome do remetente`,
		RS051511: `LIVRO I,11,XII -DEPOSITO FECHADO - NÃO INCIDÊNCIA DO ICMS - Saídas de mercadorias a depósito fechado do próprio contribuinte, localizado neste Estado`,
		RS051512: `LIVRO I,11,XIII -DEVOLUCAO ARMAZ GERAL,DEP FECHADO - NÃO INCIDÊNCIA DO ICMS - Saídas de mercadorias de dep.fechado/armazém geral em devolução ao estabelecimento de origem`,
		RS051513: `LIVRO I,11,XIV -MERCADORIAS DE TERCEIROS - NÃO INCIDÊNCIA DO ICMS - Saídas na prestação de serviço de transporte, por transportadora, de mercadorias de terceiros`,
		RS051514: `LIVRO I,11,XV. ATIVO IMOB./MAT. USO E CONSUMO - NÃO INCIDÊNCIA DO ICMS - Saída de bem do ativo imobilizado ou do uso e consumo do estabelecimento`,
		RS051515: `LIVRO I,11,XVI-LICENCIAMENTO/CESSÃO DE DIREITO DE USO DE SOFTWARE - NÃO INCIDÊNCIA DO ICMS - Operações de licenciamento ou cessão do direito de uso de software, decisão do STF n° 688.223`,
		RS051516: `LIVRO I,11,XVII - FONOGRAMAS E VIDEOGRAMAS MUSICAIS - NÃO INCIDÊNCIA DO ICMS - Saídas de fonogramas e videofonogramas musicais produzidos no Brasil de autores  brasileiros`,
		RS051600: `LIVRO I,23,I -MERCADORIAS USADAS - REDUÇÃO DE BASE DE CÁLCULO - Saída de mercadorias usadas`,
		RS051601: `LIVRO I,23,II -CESTA BASICA DE ALIMETOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de mercadorias relacionadas no Ap.IV, da cesta básica de alimentos do RS`,
		RS051602: `LIVRO I,23,III -OLEO EM BRUTO/EMBALAGENS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de óleo em bruto, mesmo degomado, quando destinado à industrialização`,
		RS051604: `LIVRO I,23,V -TRIGO EM GRAO - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas e nas importações do exterior de trigo em grão`,
		RS051605: `LIVRO I,23,VI -REFEICOES-RESTAURANTES - REDUÇÃO DE BASE DE CÁLCULO - Fornecimento de refeições por bares,restaurantes,empresas preparadoras de refeições colet.`,
		RS051607: `LIVRO I,23,VIII -CESTA BASICA DE MEDICAMENTOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas das mercadorias da cesta básica de medicamentos do RS, cfe. Apêndice V`,
		RS051608: `LIVRO I,23,IX -INSUMOS AGROPECUARIOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais de insumos agropecuários`,
		RS051609: `LIVRO I,23,X -INSUMOS PARA ALIMENTAÇÃO ANIMAL/FERTILIZANTES - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais de produtos para alim.animal/fabricação de ração e fertilizantes`,
		RS051611: `LIVRO I,23,XII -BEFIEX - REDUÇÃO DE BASE DE CÁLCULO - Saídas de máq./equip. a ativo permanente de empresa industrial que os adquiriu pelo BEFIEX`,
		RS051612: `LIVRO I,23,XIII -MAQ,EQUIPAM APARELHOS,INDUSTRIAIS - REDUÇÃO DE BASE DE CÁLCULO - Saídas de máquinas, aparelhos e equipamentos, industriais, relacionados no Apêndice X`,
		RS051613: `LIVRO I,23,XIV -MAQUINAS E IMPLEMENTOS AGRICOLAS - REDUÇÃO DE BASE DE CÁLCULO - Saídas de máquinas e implementos agrícolas, relacionados no Apêndice XI`,
		RS051614: `LIVRO I,23,XV -AERONAVES, PECAS E ACESSORIOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas e importação do exterior de aeronaves, peças,acessórios relacionados no Apêndice XII`,
		RS051616: `LIVRO I,23,XVII -FERROS E ACOS NAO-PLANOS - REDUÇÃO DE BASE DE CÁLCULO - Operações internas com ferros e aços não-planos`,
		RS051617: `LIVRO I,23,XVIII -TIJ,TELHA,TUBO,MANILHA,TAPA-VIGA - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de telhas,tubos,manilhas(de argila/barro),tijoleiras e tapa-vigas(cerâmica)`,
		RS051620: `LIVRO I,23,XXI -VEICULOS AUTOMOTORES - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas e importações de veículos automotores conforme Ap.II,S.III, X, e no Ap.XXII`,
		RS051623: `LIVRO I,23,XXIV - BLOCOS E TIJOLOS DE CONCRETO - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de blocos e tijolos de concreto para construção,NBM/SH-NCM cód.6810.11.00 `,
		RS051624: `LIVRO I,23,XXV- VEICULOS DE DUAS RODAS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas e importações de veículos novos motorizados posição 8711 da NBM/SH-NCM`,
		RS051628: `LIVRO I,23,XXIX-MEDICAMENTOS E PROD PERFUMARIA - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais a contrib.por industrializador/importador de medicamentos/perfumaria `,
		RS051629: `LIVRO I 23,XXX-EMBALAG.P/MERC.QUE COMPOEM_CESTA B - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de embalagens para as mercadorias da cesta básica de alimentos do RS`,
		RS051630: `LIVRO I, 23, XXXI- MEL PURO - REDUÇÃO DE BASE DE CÁLCULO - Saídas de mel puro destinadas a consumidor final, promovidas por produtor`,
		RS051631: `LIVRO I,23,XXXII-VEICULOS,MAQ,APARELHOS E CHASS - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais por estabelecimento fabric./imp. de veículos/caminhões/chassi`,
		RS051632: `LIVRO I,AR.23,XXXIII-PNEUMATICOS,CAMARAS DE AR BOR - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais por fabric./imp. de pneumáticos novos de borracha/câmaras-de-ar`,
		RS051634: `LIVRO I,23,XXXV-PEDRA BRITADA E DE MAO - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de pedra britada e de mão classificadas no código 2517.10.00, da NBM/SH-NCM`,
		RS051639: `LIVRO I,  23, XL-CARNE AVES,LEPOR-DEOS E GADO - REDUÇÃO DE BASE DE CÁLCULO - Saídas interest.de carne/prod.comest.de aves,leporídeos,gado bov.,bufal.,capr.,ovino,suíno`,
		RS051644: `LIVRO I,  23, XLV - FEIJAO - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais de feijão, quando a alíquota aplicável for 12%`,
		RS051645: `LIVRO I, 23,XLVI,SACOLAS PLAST.P/ACOND.MERC. - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas por fabricante de sacolas plásticas a mercados,padarias,açougues e armazéns`,
		RS051646: `LIVRO I,  23, XLVII.MERCADORIAS PARA UMS - REDUÇÃO DE BASE DE CÁLCULO - Operações internas/interest.de merc. adquiridas pela Adm.Pública às Unid.Mod.de Saúde(UMS)`,
		RS051647: `LIVRO I,  23, XLIX.MAQ./APAR.IMP.APEND.XXXVI - REDUÇÃO DE BASE DE CÁLCULO - Saídas de máq./apar. Importados, rol no Ap.XXXVI,  por fabricante. Exige Termo de Acordo.`,
		RS051648: `LIVRO I,  23, L.MAQ.APR.APEND.XXXVII - REDUÇÃO DE BASE DE CÁLCULO - Saídas de máquinas e aparelhos relacionados no Apêndice XXXVII, produzidos neste Estado`,
		RS051650: `LIVRO I,23,LIII.SAIDAS INTERNAS DE UREIA. - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de ureia classificada no código 3102.10.10 da NBM/SH-NCM`,
		RS051654: `LIVRO I,23,LVIII.SUINOS VIVOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais de suínos vivos realizadas por produtor rural`,
		RS051655: `LIVRO I,23,LIX.MERC.EST.COOP.Q.NAO OPTA SN - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de mercadorias promovidas por estabelecimento de cooperativa`,
		RS051656: `LIVRO I,23,LX.ERVA MATE - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de erva-mate, inclusive com adição de açúcar, espécies veg. ou aromas nat.`,
		RS051658: `LIVRO I,23,LXII,EMBALAGENS PARA ERVA-MATE - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de embalagens, produzidas neste Estado, para erva-mate`,
		RS051660: `LIVRO I, 23,LXIV.PROD.TEX. VESTUARIOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de prod.têxteis,art.do vestuário,botões de plásticos,de fabricação própria`,
		RS051662: `LIVRO I,23,LXVI.COSM.PERF.HIG.PES.TOUC. - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de cosméticos,perfum.,art.de higiene,rol no Ap.II, S.III,XXII,por indústria`,
		RS051663: `LIVRO I,23,LXVII.QUEROSENE DE AVIACAO - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de querosene de aviação à prest.de serv.aerov.passag.em mun.do interior/RS`,
		RS051664: `LIVRO I,23,LXVIII.VEIC.MILITARES P/ EX.BR. - REDUÇÃO DE BASE DE CÁLCULO - Operações com veículos/aparelhos p/uso militar,por fabricante, ao Ministério da Defesa BR`,
		RS051665: `LIVRO I,23,LXIX.CARNE E PROD.TEMP.AVES E SUIN. - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de carne e produtos comestíveis temperados, do abate de aves e de suínos`,
		RS051666: `LIVRO I,23,LXX.MARM.TRAVERTINOS E GRANITOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de estab. comercial a estab. industrial, de mármores,travertinos e granitos`,
		RS051667: `LIVRO I,23,LXXI.MAT.P/OCULOS - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de lentes e armações p/óculos, óculos de sol, por atacado`,
		RS051669: `LIVRO I, 23,LXXIII.PA CARR.ROD.,ESC.HD.RET.CA - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas/interes. a consumidor final,de pá carreg.,escavad.hidr.,retroesc.e dumpers`,
		RS051670: `LIVRO I,23,LXXIV.EMBARCACAO DE REC./ESPORTE - REDUÇÃO DE BASE DE CÁLCULO - Saídas de embarcações de recreação ou de esporte, na posição 8903 da NBM/SH-NCM`,
		RS051671: `LIVRO I,23,LXXV.VEIC.AUT.P/10 PESSOAS OU + - REDUÇÃO DE BASE DE CÁLCULO - Saídas de veículos automóveis para transporte de 10 pessoas ou mais, incluindo o motorista`,
		RS051676: `LIVRO I,23, LXXX.LUVAS E BOTAS P/ EPI - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de luvas e botas para uso como EPI pelo adquirente`,
		RS051678: `LIVRO I,23, LXXXII. REPETRO-SPED - REDUÇÃO DE BASE DE CÁLCULO - Saídas por venda de bens/mercadorias permanentes p/explor./prod.de petróleo e gás natural`,
		RS051679: `LIVRO I,23, LXXXIII. FABRICANTES DE TRAFO E REATORES ACIMA DE 230kV. - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de transformadores e reatores(8504.23.00/8504.50.00 da NCM) p/prest.pública`,
		RS051680: `L.I,ART.23,LXXXIV.MAQ.APAR.P/TERM.PORTUARIOS - REDUÇÃO DE BASE DE CÁLCULO - Operações internas com máq./equip. a contrib. p/constr./ampl.dos Terminais Portuários do RS`,
		RS051681: `L.I,ART.23,LXXXV CARROCERIAS E SEMIRREBOQUES - REDUÇÃO DE BASE DE CÁLCULO - Operações de estabelecimento industrial com carrocerias (inc. cabinas) e semirreboques`,
		RS051682: `L.I,ART.23,LXXXVI ALHO PRODUTOR RURAL  - REDUÇÃO DE BASE DE CÁLCULO - Saídas de alho promovidas por produtor rural ou cooperativa de produtores rurais`,
		RS051683: `L.I,ART.23,LXXXVII SAIDA INTEREST. ARROZ BENEF - REDUÇÃO DE BASE DE CÁLCULO - Saídas interestaduais de arroz beneficiado de produção própria`,
		RS051684: `L.I,ART.23,LXXXVIII VEÍCULOS DE COMBATE A INCÊNDIO - REDUÇÃO DE BASE DE CÁLCULO - Operações com veículos classificados no cód. 8705.30.00 da NBM/SH-NCM.`,
		RS051685: `L.I,ART.23,LXXXIX.FERTILIZANTES - REDUÇÃO DE BASE DE CÁLCULO - Saídas dos fertilizantes elencados nas alíneas "a" e "b" do inciso LXXXIX do art.23`,
		RS051686: `L.I,ART.23,XC.AREIA, LAVADA OU NÃO - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas de areia`,
		RS051687: `L.I,ART.23,XCI.TELHA DE  FIBROCIM.,TIJOLO REFRAT., TUBOS/MANILHAS DE CONCRETO - REDUÇÃO DE BASE DE CÁLCULO - Saídas internas, nos NBM/SH-NCMs 6811, 6902.20.10 e 6810.91.00`,
		RS051688: `L.I,ART.23,XCII.BLOCO DE CONCRETO INTERTRAVADO - REDUÇÃO DE BASE DE CÁLCULO - Operações com blocos de concreto intertravados cód. 6810.19.00 da NBM/SH-NCM`,
		RS051689: `L.I,ART.23,XCIII.BATATA PREPARADA E CONGELADA - REDUÇÃO DE BASE DE CÁLCULO - Saídas de batatas preparadas e congeladas de produção própria de NCM 2004.10.00`,
		RS051800: `LIVRO I,24,I -TRANSP INTERMUNICIPAL DE PASSAGEIROS - REDUÇÃO DE BASE DE CÁLCULO - Prestações de serviço de transporte intermunicipal de pessoas, exceto o aéreo`,
		RS051805: `LIVRO I,24,VI-MONITORAMENTO E RASTR.DE VEIC/CARGA - REDUÇÃO DE BASE DE CÁLCULO - Prestações de serviço de comunicação onerosas, para monit./rastreamento de veículo e carga`,
		RS051806: `LIVRO I,24, VII.TRANSP.INTER.DE PASSAG. - REDUÇÃO DE BASE DE CÁLCULO - Prestações de serviço de transp.intermunicipal de passag. no transp.urbano ou metropolitano`,
		RS051807: `LIVRO I,24,VIII.TRANSP.INTERMUN.PESSOAS PANDEMIA - REDUÇÃO DE BASE DE CÁLCULO - Prestações de serviço de transp.intermunicipal de pessoas durante calamidade pública(COVID)`,
		RS051808: `LIVRO I,24,IX.PROGRAMA DE FOMENTO SERVIÇO DE COMUNICAÇÃO MULTIMÍDIA - REDUÇÃO DE BASE DE CÁLCULO - Prestações de serviço de telecomunic. a consumidor final no RS por empresa participante`,
		RS051901: `LIVRO II, ART. 12 - DECISÕES JUDICIAIS - OUTRAS - Saídas desoneradas por decisões judiciais`,
		RS051902: `LIVRO I,ART.38-A,§4º,E - RDA GORJETA - OUTRAS -  Regime diferenciado de apuração para bares e restaurantes, gorjeta limitada a 10% do valor da conta`,
		RS051904: `L.I,ART.19,IV – DEMANDA CONTRATADA NÃO UTILIZADA - OUTRAS -  Demanda de potência não utilizada pelo consumidor, no fornecimento de energia elétrica por demanda contratada`,
		RS052001: `LIVRO I,53,I -ESTABELEC PERTENCENTE A MESMA PESSOA - DIFERIMENTO - Operações internas de remessa de mercadoria entre estabelecimentos da mesma empresa`,
		RS052002: `AP II,S.I,I -REMESSA P/FINS INDUSTRIALIZACAO,ETC - DIFERIMENTO - Remessa p/industrialização/demonstração/conserto de máquinas e aparelhos, com posterior devolução à origem`,
		RS052003: `AP II,S.I,II -DEVOLUCAO DE MERCADORIAS - DIFERIMENTO - Devolução de máquinas e aparelhos remetidos p/industrialização/demonstração/conserto`,
		RS052004: `AP II,S.I,III -PRODUTOR - DIFERIMENTO - Saída de mercadoria de produção própria, por produtor a produtor ou indústria, comércio ou cooperativa`,
		RS052005: `AP II,S.I,IV -SAIDA DE PRODUTOR P/ORGAO OFICIAL - DIFERIMENTO - Saída de mercadoria de produção própria, por produtor/cooperativa a órgão oficial`,
		RS052006: `AP II,S.I,V -COOPERATIVA - DIFERIMENTO - Saída de mercadoria de cooperativa a outra cooperativa de que a cooperativa remetente faça parte`,
		RS052007: `AP II,S.I,VI -AGUA - DIFERIMENTO - Saída de águas, exceto a potável e de vapor d'água, para estabelecimento industrial`,
		RS052008: `AP II,S.I,VII -ALCOOL COMBUSTIVEL - DIFERIMENTO - Saída de álcool combustível e biodiesel, do estabelecimento industrial para estabelecimento distribuidor`,
		RS052009: `AP II,S.I,VIII -ARROZ,CANJICAO,CANJICA E QUIRERA - DIFERIMENTO - Saída de arroz, em casca ou beneficiado, canjicão, canjica e quirera, exceto a estab. de microempresa`,
		RS052010: `AP II,S.I,IX -CARVAO MINERAL - DIFERIMENTO - Saída de carvão min./calcário,por estab.extrator;de óleo combustível,a geradora/supridora de energ.elét.`,
		RS052011: `AP II,S.I,X -CARVAO VEGETAL - DIFERIMENTO - Saída de carvão vegetal`,
		RS052012: `AP II,S.I,XI -CEVADA EM GRAO - DIFERIMENTO - Saída de cevada em grão`,
		RS052013: `AP II,S.I,XII -CINZAS DE CARVAO MINERAL - DIFERIMENTO - Saída de cinzas de carvão mineral, para estabelecimentos fabricantes de cimento`,
		RS052014: `AP II,S.I,XIII -COUROS E PELES - DIFERIMENTO - Saída de couros e peles, em estado natural, secos, salgados ou salmourados`,
		RS052015: `AP II,S.I,XIV -ERVA-MATE EM FOLHA OU CANCHEADA - DIFERIMENTO - Saída de erva-mate em folha ou cancheada`,
		RS052016: `AP II,S.I,XV -ENERGIA ELETRICA - DIFERIMENTO - Saída de energia elétrica do gerador ao distribuidor; ao estab.rural; ao estab.industrial em área indust.`,
		RS052017: `AP II,S.I,XVI -EQUINO - DIFERIMENTO - Saída de eqüino que tenha controle genealógico oficial e idade de até 3 anos`,
		RS052018: `AP II,S.I,XVII -FARELO E TORTA DE GIRASSOL - DIFERIMENTO - Saída de farelo e torta de girassol`,
		RS052019: `AP II,S.I,XVIII -FERRO VELHO,SUCATAS,RESIDUOS,ETC. - DIFERIMENTO - Saída de ferro velho,sucata,resíduos destinados à produção industrial ou à comercialização`,
		RS052020: `AP II,S.I,XIX -FOSFATO BI-CALCIO - DIFERIMENTO - Saída de fosfato bi-cálcio destinado à alimentação animal`,
		RS052021: `AP II,S.I,XX -HORTIFRUTIGRANJEIROS - DIFERIMENTO - Saída de frutas frescas nacionais ou oriundas de países membros da ALADI e de verduras e hortaliças`,
		RS052022: `AP II,S.I,XXI -FUMO EM FOLHA CRU - DIFERIMENTO - Saída de fumo em folha cru`,
		RS052023: `AP II,S.I,XXII -PROGRAMA AGREGAR CARNES - DIFERIMENTO - Saída de gado vacum,ovino e bufalino,por atac., ao abatedor; remet./dest. devem participar do AGREGAR-RS `,
		RS052024: `AP II,S.I,XXIII -OBRAS DE ARTE - DIFERIMENTO - Saída, de galerias de arte e estabelecimentos similares, de obras de arte para demonstrações ou exposições`,
		RS052025: `AP II,S.I,XXIV -GRAO DE GIRASSOL - DIFERIMENTO - Saída de grão de girassol`,
		RS052026: `AP II,S.I,XXV -LAS,PELOS E CABELOS,ORIGEM ANIMAL - DIFERIMENTO - Saída de lãs, pêlos e cabelos, de origem animal`,
		RS052027: `AP II,S.I,XXVII -LEITOES - DIFERIMENTO - Saída de leitões de até 70 dias com até 25 kg, destinados à engorda`,
		RS052028: `AP II,S.I,XXVIII -OVOS FRESCOS - DIFERIMENTO - Saída de ovos frescos;ovos integrais past., por estab.industrial para fins de industrialização;embalagens`,
		RS052029: `AP II,S.I,XXIX -PEIXES - DIFERIMENTO - Saída de peixes como matéria-prima em processos industriais de cozimento ou enlatamento`,
		RS052030: `AP II,S.I,XXX -SEBO,CHIFRE E CASCO - DIFERIMENTO - Saída de sebo, chifre e casco`,
		RS052031: `AP II,S.I,XXXI -SOJA EM GRAO - DIFERIMENTO - Saída de soja em grão`,
		RS052032: `AP II,S.I,XXXII -SUINOS VIVOS - DIFERIMENTO - Saída de suínos vivos, com destino a estabelecimento abatedor`,
		RS052033: `AP II,S.I,XXXIII -TRIGO E TRITICALE, EM GRAO - DIFERIMENTO - Saída de trigo e de triticale, em grão, com destino à indústria moageira de trigo`,
		RS052035: `AP II,S.I,XXXV -MAT-PRIMAS P/TRATORES IMPLEM AGRIC - DIFERIMENTO - Saída de matérias-primas para implementos agrícolas a estabelecimento industrial, localizado no Estado`,
		RS052036: `AP II,S.I,XXXVIII -TRATORES E IMPLEMENTOS AGRICOL - DIFERIMENTO - Saída dos produtos tratores e implementos agrícolas para uso exclusivo na produção agropecuária`,
		RS052037: `AP II,S.I,XXXIX -PRODUTOR - ATIVO PERMANENTE - DIFERIMENTO - Saída de transformadores,pirômetros,quadros de comando,a produtor, para ativo permanente do seu estab.`,
		RS052038: `AP II,S.I,XL -PECAS,PES E COMPONENTES P/VEICULO - DIFERIMENTO - Saída de peças,partes e compon.a estab.industrial,desde remet./dest. estejam instalados em área industrial`,
		RS052039: `AP II,S.I,XLI -VEICULOS,PECAS,PES E COMP,IMPORT - DIFERIMENTO - Saída, por importador, de veículos relacionados no item X da Seção III deste Apêndice a estab.industrial `,
		RS052041: `AP II,S.I,XLIII -EMBALAGEM DE OVOS FRESCOS - DIFERIMENTO - Saída de material de embalagem utilizado para o acondicionamento de ovos frescos`,
		RS052042: `AP II,S.I,XLIV -INSUMOS PETROQUIMICOS - DIFERIMENTO - Saída de insumos petroquímicos a dest.c/Protocolo visando ramo petroq./plástico; seja benef.do FUNDOPEM/RS`,
		RS052043: `AP II,S.I,XLV -COGUMELOS - DIFERIMENTO - Saída de cogumelos`,
		RS052045: `AP II,S,I,XLVI-ZONAS PROCESSAMENTO EXPORTACAO-ZPE - DIFERIMENTO - Saída de mercadorias destinadas a estabelecimentos situados nas Zonas de Processamento de Exportação - ZPE`,
		RS052046: `AP II,S,I,XLVII-GAS LIQUEFEITO DE PETROLEO E GAS N - DIFERIMENTO - Saída de gás liqüefeito de petróleo e gás natural, a estab.industrial em área industrial`,
		RS052047: `AP II,S,I,XLVIII-SAL PARA PECUARIA - DIFERIMENTO - Saída de sal, exceto sal de mesa, a produtor e a cooperativa de produtores para emprego na pecuária`,
		RS052048: `AP II,S,I,XLIX-GAS NATURAL - DIFERIMENTO - Saída de gás natural na industrialização em usina geradora de energ.elét., do importador/gerador até usina`,
		RS052049: `AP II,S.I,L-MAQ E EQUIP P/COMPLEXO IND LEI FITEC/RS - DIFERIMENTO - Saída de máq./equip. a estabelecimento instalado em complexo industrial previsto FITEC/RS`,
		RS052050: `AP II,S.I,LI-PROTEINAS E GORDURAS VEGETAIS DE SOJA - DIFERIMENTO - Saída de proteína isolada/texturizada e gorduras veget. de soja por estab.beneficiador a estab.industrial`,
		RS052051: `AP II, S. I, LII-RESIDUOS DE MADEIRA E DE CASCA D - DIFERIMENTO - Saída de resíduos de madeira, a centrais geradoras termelétricas,como combust.na prod. de energia elétrica`,
		RS052052: `AP II, S. I, LIII-CARVAO MINERAL P/ INDUSTRIA DE - DIFERIMENTO - Saída de carvão mineral por estab.extrator, e de óleo combustível, destinados à industria de celulose`,
		RS052053: `AP II, S. I, LIV-MERCADORIAS P/ INDUSTRIA DO PROG - DIFERIMENTO - Saída de mercadorias a estabelecimento industrial integrante Programa SLP Cerâmico`,
		RS052054: `AP II, S. I, LV-MATERIAS-PRIMAS E MATERIAIS DE EM - DIFERIMENTO - Saída, de estab.industrial,de matérias-primas e mat.de embalagem,a estab.industrial em área industrial`,
		RS052055: `AP II, S. I, LVI - BENZENO - DIFERIMENTO - Saída de benzeno a destinatário com Protocolo p/ampliação de indústria petroquímica e benef.do FUNDOPEM`,
		RS052056: `AP II, S.I,LVII-MAQUINAS E EQUIPAMENTOS P/A FABRIC - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria de cerveja,refrigerante,suco,envase de água mineral`,
		RS052057: `AP II,S.I,LVIII-ATIVO PERMANENTE P/IND DE NAVIOS - DIFERIMENTO - Saída de máquinas e equipamentos industriais ao ativo permanente de estabelecimento industrial naval`,
		RS052058: `AP II,S.I,LIX-INSUMOS P/IND DE NAVIOS - DIFERIMENTO - Saída de peças, partes e componentes, matérias-primas e materiais de embalagem a estab.industrial naval`,
		RS052059: `AP II,S, I,LX - AVES VIVAS - DIFERIMENTO - Saída de aves vivas, com destino a estabelecimento abatedor`,
		RS052060: `AP II,S, I,LXI - ATIVO PERMANENTE P SET MOV - DIFERIMENTO - Saída de máq./equip.industriais a ativo permanente de estab.industrial moveleiro e coureiro-calçadista`,
		RS052061: `AP II, S. I, LXII - OLEO LUBRIFICANTE BASICO - DIFERIMENTO - Saída de óleo lubrificante básico decorrente de re-refino de óleo lubrificante usado ou contaminado`,
		RS052062: `AP II, S. I, LXIII-MAQU. EQUIP. P/ IND. BIODIESEL - DIFERIMENTO - Saída de máq./equip./peças ao ativo permanente de produtor de biodiesel, que tenha firmado Termo de Acordo`,
		RS052063: `AP II, S. I, LXIV-MAQU.EQUIP.FILME POLIPR.BIORIEN - DIFERIMENTO - Saída de máq./equip.industriais ao ativo perman.de estab.p/produção de filme de polipropileno biorientado`,
		RS052064: `AP II, S. I, LXV-MAQU.EQUIP.P/IND.DERIVADOS LEITE - DIFERIMENTO - Saída de máq./equip.industriais ao ativo perman.de estab.industriais p/fabricação de derivados de leite`,
		RS052065: `AP II,S.I,LXVI-PE-,MAT-PRI E MAT EMB P/PROD CELULO - DIFERIMENTO - Saída de matérias-primas,mat.de embalagem,peças a estab.industrial p/fabr.de celulose p/ fabric.de papel`,
		RS052066: `AP II,S.I,LXVII-ATIVO PERM IND CELULOSE E OUTRAS P - DIFERIMENTO - Saída de máq./equip.industriais a ativo perm.de estab.industrial p/fabr.de celulose p/fabr.icação de papel`,
		RS052067: `AP II,S.I,LXVIII-ATIVO PERM DE ABAT DE GADO VACUM - DIFERIMENTO - Saída de máq./equip.industriais a ativo perm.de estab.abatedor de gado vacum, ovino e bufalino`,
		RS052068: `AP II,S.I,LXIX-ATIVO PERM DE IND DE RESINAS - DIFERIMENTO - Saída de máq./equip.a ativo perm.de estab.industrial p/prod.de resinas uréicas/fenólicas e de formaldeído`,
		RS052069: `AP II,S.I,LXX-ATIVO PERM DE IND DE ALCOOL - DIFERIMENTO - Saída de máq./equip./peças a ativo perm.de destilaria produtora de álcool neutro e de álcool combustível`,
		RS052070: `AP II,S.I,LXXI-OLEO VEG/ANIMAL P/IND.BIODIESEL - DIFERIMENTO - Saída de óleos e gorduras, vegetais ou animais, a estabelecimento industrial produtor de biodiesel`,
		RS052071: `AP II,S.I,LXXII-PEC,MAT-PRI E MAT EMB E ATIVO PERM - DIFERIMENTO - Saída de máq./equip.a ativo perm. e mat.prima/peça a estab.industrial p/fabric.de aerogeradores eólicos`,
		RS052072: `AP.II,S.I,LXXIII.MAQ/EQUIP ATIVO PERM.P/IND.MDP - DIFERIMENTO - Saída de máq./equip.a ativo permamente a estab.industrial p/produção de painéis de MDP`,
		RS052073: `AP II, S. I, LXXIV-GRAOS MAMONA P/IND.BIOD - DIFERIMENTO - Saída de mamona destinada a estabelecimento industrial produtor de biodiesel`,
		RS052074: `AP II, S. I, LXXV. PETROLEO - DIFERIMENTO - Saída de petróleo`,
		RS052075: `AP II, S. I, LXXVI. CASCA DE ARROZ P/ IND. - DIFERIMENTO - Saída de casca de arroz, destinada a estabelecimento industrial`,
		RS052076: `AP.II,S.I,LXXVII.ATIVO PERM.USINA TERM.A CARVAO - DIFERIMENTO - Saída de máq./equip.a ativo permamente de usina termelétrica a carvão mineral, da contratada p/contratante`,
		RS052077: `AP II,S.I,LXXVIII. - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria para encapsulamento e teste de semicondutores`,
		RS052078: `AP II, S. I, LXXIX - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria para a produção de butadieno`,
		RS052079: `AP II, S. I, LXXX - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria para a produção de pneumáticos`,
		RS052080: `AP II, S. I, LXXXI - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria c/Protocolo de Intenções prevendo diferimento `,
		RS052081: `AP II, S. I, LXXXII - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria de guindastes e caminhões-guindaste`,
		RS052082: `AP II, S. I, LXXXIII - DIFERIMENTO - Saída de matérias-primas, material de embalagem, peças a indústria de guindastes e caminhões-guindaste`,
		RS052083: `AP II, S. I, LXXXIV - DIFERIMENTO - Saída de ureia, por importador, a fabricante de resinas ureicas, fenólicas e melamínicas`,
		RS052084: `AP II, S. I, LXXXV - DIFERIMENTO - Saída de matérias-primas, materiais de embalagem a estab.industrial p/fabricação de pneumáticos`,
		RS052085: `AP II,S.I,LXXXVI.TRIGO EM GRAO P/ IND. DE RACAO - DIFERIMENTO - Saída de trigo em grão, produzido neste Estado, com destino à indústria de ração`,
		RS052086: `AP II,S.I,LXXXVII,CONSTR.CONSER.MODER.EMBARCA--ES - DIFERIMENTO - Saída de mercadorias para construção, conservação, modernização e reparo de embarcações`,
		RS052087: `AP II,S.I,LXXXVIII,ATI.PERM.CONSTR.MODER.EMBARCA-- - DIFERIMENTO - Saída de máq./equip.a ativo permamente de indústria de construção/reparo de embarcações`,
		RS052088: `AP II,S.I,LXXXIX.QUEROS.AV/OLEO COMB.P/DISTR.COMB. - DIFERIMENTO - Saída de querosene de aviação e óleo combustível, por refinaria, destinada à distribuidora de combustíveis`,
		RS052089: `AP II, S. I, XC. PROD.ACAB.INFOR.E AUTOM. - DIFERIMENTO - Saída dos produtos acabados de informática e automação relacionados no Apêndice XIII`,
		RS052090: `AP II,S.I,XCI.COSM.PERF.HIG.PES.E TOUCADOR - DIFERIMENTO - Saída de cosméticos, perfumaria, art.de higiene pessoal e de toucador, relacionados no Ap.II, S.III, XXII`,
		RS052091: `AP II,S.I,XCII.RESIDUOS DE MADEIRA - DIFERIMENTO - Saída de resíduos de madeira, destinados à produção industrial ou à comercialização`,
		RS052093: `AP.II,S.I,XCIV. MERC.IMP.C/DIF.P/PROCAM/RS - DIFERIMENTO - Saída de mercadoria importada com diferimento destinada a estab.industrial do PROCAM/RS`,
		RS052094: `AP II,S.I,XCV. MERC.DEST.DISTR.AGUA-POLO PETROQ. - DIFERIMENTO - Saída de mercadorias à distribuidora de água localizada no Polo Petroquímico de Triunfo`,
		RS052095: `AP II,S.I,XCVI.CAL VIVA E DOLOMITA P/TERMELETRICA - DIFERIMENTO - Saída de cal viva e de dolomita calcinada, destinadas à usina termelétrica`,
		RS052096: `AP II,S.I,XCVII.MERC.PARA TRAT.4 RODAS,RET.EMP. - DIFERIMENTO - Saída de merc. p/fabricação de eixos utilizados em tratores agríc.de 4 rodas, retroescav.e empilhadeiras`,
		RS052098: `AP.II,S.I,XXVI.LEITE FRESCO, PASTEURIZADO OU NÃO - DIFERIMENTO - Saída de leite fresco, pasteurizado ou não`,
		RS052099: `AP.II,S.I,XCIX. MILHO - DIFERIMENTO - Saída de milho`,
		RS052113: `LIVRO III, 1A, V - DIFERIMENTO PARCIAL - Saídas internas de insumos para ind.de ônibus,micro-ônibus,miniônibus e furgões,suas carrocerias`,
		RS052114: `AP II,S.IV.SUBS.VI.I.BENS/MERC.DEST.ENV.BEB.AL.PAS - DIFERIMENTO PARCIAL - Livro III, art.1º-A, IV - Saídas internas de mercadorias p/envasar bebidas e alim. líq./pastosos`,
		RS052118: `AP II,S.IV,SUBS.VIII.ITENS I A IV. ACOS/FERROS - DIFERIMENTO PARCIAL - Livro III, art.1º-A, IX - Saídas internas de mercadorias relacionadas no Apêndice do RICMS citado `,
		RS052121: `LIVRO III,  1-A, XIII - DIFERIMENTO PARCIAL - Saídas internas de produtos laminados planos p/fabricação de vagões ou caixas de carga de vagões`,
		RS052122: `LIVRO III,1-A,XIV.MERC.P/FAB.TR.ATR.R.ELETR. - DIFERIMENTO PARCIAL - Saídas internas de mercadorias p/fabr.de transformadores, autotransformadores e reatores elétricos`,
		RS052123: `LIVRO III, 1-D E AP II,S.IV,MERC.P/IND.N.MEC. - DIFERIMENTO PARCIAL - Saídas internas de merc.p/industrializ.de reatores nucleares,caldeiras,máq.,aparelhos mecânicos`,
		RS052129: `LIVRO III, 1A,XVIII.INS.P/FAB.TRANSP.GRAN.;C/D B - DIFERIMENTO PARCIAL - Saídas internas de insumos p/fabr.de transp.de granéis,carregadores/descarreg.de navios e barcaças`,
		RS052130: `LIVRO III,1-A,XIX.LAM.PL.FB.VEIC. FOMENTAR. - DIFERIMENTO PARCIAL - Saídas internas de laminados planos a fabricantes de veículos beneficiário do FOMENTAR/RS`,
		RS052132: `LIVRO III,1-A,XXI. - DIFERIMENTO PARCIAL - Saídas internas de laminados planos a fabricantes de veículos beneficiário do FOMENTAR/RS`,
		RS052137: `L.III,ART.1-F,AP.II,S.V,ITENS I,II,III e V - DIFERIMENTO PARCIAL - Saídas internas de cobre não refinado, eixos motores, bobinas/chapas de aço, e folhas de flandres`,
		RS052139: `LIVRO III,ART. 1-G. MAT.P/FAB.VEICULOS DE TRANSP. - DIFERIMENTO PARCIAL - Operações com mat.-prima,peças,partes p/fabr.de automóveis das posições 8702 e 8704 da NBM/SH-NCM`,
		RS052140: `LIVRO III,1-H.ACOS PLANOS,P/FAB.TUBOS DE ACO - DIFERIMENTO PARCIAL - Saídas internas de aços planos para fabricação de tubos de aço`,
		RS052142: `LIVRO III,1-A, XXVII. EMB. METALICAS - DIFERIMENTO PARCIAL - Saídas internas de embalagens metálicas promovidas por estabelcimento fabricante`,
		RS052143: `LIVRO III, 1-A,XXVIII.MAT.P/FAB.ARMAS E MUN. - DIFERIMENTO PARCIAL - Saídas internas de matéria-prima,peças p/fabr.de armas,revólveres,pistolas,cartuchos e munições`,
		RS052145: `L.III,ART1-I FAB SIST CONSTR E ESTRUT METAL - DIFERIMENTO PARCIAL - Saídas internas de mercadorias a fabricantes de sistemas construtivos e de estruturas metálicas`,
		RS052146: `LIVRO III,ART.1-A,XXX. PROD.LAMIN.PLAN.FERRO/ACO - DIFERIMENTO PARCIAL - Saídas internas de produtos laminados de ferro/aço p/.fabricação de silos e secadores para grãos`,
		RS052147: `LIVRO III,ART.1-C.SAIDAS DE CENTRAL DE NEGOCIOS - DIFERIMENTO PARCIAL - Saídas por contribuinte que exerça a atividade de Central de Negócios`,
		RS052149: `L.III,ART.1-F,AP.II,S.V,IV.ETILENO - DIFERIMENTO PARCIAL - Saídas internas de etileno destinadas a estabelecimento fabricante de estireno, benef. FUNDOPEM`,
		RS052150: `AP.II,S.I,CI. BIOGÁS E BIOMETANO P/ DISTRIBUIDORA - DIFERIMENTO - Saída de biogás e biometano por estab.industrial produtor à empresa concessionária distrib.de gás natural`,
		RS052151: `AP.II,S.I,CII. RESÍDUOS AGROSSILVOPASTORIS - DIFERIMENTO - Saída de resíduos agrossilvopastoris, destinada a estabelecimento industrial produtor de biogás ou de biometano`,
		RS052152: `AP.II,S.I,CIII. CARVÃO MINERAL P/ ESTAB DO POLO CARBOQUÍMICO - DIFERIMENTO - Saída de carvão mineral a estab.industrial localizado no Polo Carboquímico do RS`,
		RS052153: `AP.II,S.I,CIV. "PELLETS" DE MADEIRA NCM 4401.31.00 - DIFERIMENTO - Saída de “pellets” de madeira, classificados na posição 4401.31.00 da NBM/SH-NCM`,
		RS052155: `L.III,ART.1-J,I.LAMINADOS PLANOS DE FERRO/AÇO - DIFERIMENTO PARCIAL - Saídas internas de lam.planos NCMs 7209.1700,7209.1800 e 7225.5090 para fabric.de móveis de aço.`,
		RS052156: `L.III,ART.1-J,II.BOBINAS,CHAPAS DE FERRO/AÇO - DIFERIMENTO PARCIAL - Saídas internas com destino a fabricantes de móveis de aço. Ver tabela de NCMs no inciso II.`,
		RS052157: `L.III,ART.1-J,III,MERC.IMP.ABRIGO L.I,ART.53,VI - DIFERIMENTO PARCIAL - Saídas internas por importador, de merc. importadas ao abrigo do diferimento do L.I,art.53,VI.`,
		RS052158: `L.III,ART.1-K.DIF. PARCIAL NÃO ESPECIFICADO - DIFERIMENTO PARCIAL - Hipóteses em que não se aplica o disposto nos arts.1º-A, 1º-C, 1º-D e 1º-F a 1º-J. `,
		RS052159: `AP.II,S.I,CV. LAM.PLANOS E PERFIS DE ACO/FERRO - DIFERIMENTO - Saídas de laminados e perfis cfe.lista de NCMs na legislação para empresas benef.do Fundopem.`,
		RS052160: `L.III,ART.1-L.MERC.SOB ST IMP.ABRIGO L.I,ART.53,VI - DIFERIMENTO PARCIAL - Saídas internas por importador, de merc. sob ST importadas ao abrigo do difer. do L.I,art.53,VI.`,
		RS052161: `AP.II,S.I,XXXVI.MERC./INSUMO P/PROD.AGROPECUÁRIA   - DIFERIMENTO - Saídas de merc. tais como defensivos agrícolas, vacinas, medicamentos, rações, sementes, dentre outros`,
		RS052162: `AP.II,S.I,XXXVII.RAÇÃO ANIMAL,ADUBO E FERTILIZANTE - DIFERIMENTO - Saídas de milho, farelos e tortas de soja e de canola, e demais itens relacionados no Ap.II, S.I, XXXVII`,
		RS052163: ` L.III,ART.1-M.MERC.IMPORTADA S/SIMILAR-LISTA CAMEX - DIFERIMENTO PARCIAL - Saídas internas por importador de merc.sob ST sem similar nacional definidas em lista Camex`,
		RS052164: `AP.II.S.I,CVI. SAIDA DE GRAOS DE CANOLA - DIFERIMENTO - Saída de grãos de canola`,
		RS052165: `AP.II.S.I,CVII. SAIDA DE AREIA A FAB. EMB. VIDRO - DIFERIMENTO - Saída internas de areia NCM 2505.10.00 a fabricante de embalagens de vidro`,
		RS052166: `AP.II.S.I,CVIII. SAIDA SORO DE LEITE, EXCETO EM PO - DIFERIMENTO - Saídas de soro de leite, exceto em pó, a fabricante de NCM 1702.11.00 ou 3502.20.00`,
		RS052300: `LIVRO I,55,I -CONSERTO, REPARO OU INDUSTRIALIZACAO - SUSPENSÃO - Saídas de mercadorias p/conserto,reparo,industrializ. em estab.situado em outra UF, desde que haja devolução`,
		RS052301: `LIVRO I,55,II -DEVOLUCAO - SUSPENSÃO - Saídas em devolução à origem situado em outra UF,das mercadorias/produtos industrializados delas resultantes`,
		RS052302: `LIVRO I,55,III -EQUINO - SUSPENSÃO - Saídas para outra UF de eqüino para cobertura, participação em prova ou treinamento`,
		RS052303: `LIVRO I,55,V-ALCOOL ETILICO ANIDRO COMBUSTIVEL - SUSPENSÃO - Saídas para outra UF de álcool etílico anidro combustível e biodiesel - B100,à distribuidora de combustíveis`,
		RS052304: `LIVRO I,55,VI - OUTRAS OP.PREV EM PROTOC CELEB - SUSPENSÃO - Outras operações previstas em protocolos celebrados entre as unidades da Federação interessadas`,
		RS052308: `LIVRO I,55,X.MERCADORIA REMETIDA P/DEMONSTRAÇÃO - SUSPENSÃO - Condicionado ao retorno da mercadoria ao estabelecimento de origem em até 60 dias, contados da data da saída`,
		RS052309: `LIVRO I,55,XI.MERCADORIA REMETIDA P/MOSTRUÁRIO - SUSPENSÃO - Condicionado ao retorno da mercadoria ao estabelecimento de origem em até 90 dias, contados da data da saída`,
		RS052401: `LIVRO III,57 -REVENDEDOR AMBULANTE DE OUTRA UF - ICMS JÁ RECOLHIDO POR ST - Por revendedor ambulante de outra unidade da Federação`,
		RS052402: `LIVRO III,73 -MERCAD P/VENDA EM BANCAS JORNAIS/REV - ICMS JÁ RECOLHIDO POR ST - Por editora que destine a distribuidor fitas, discos e similares, integrantes de "kit"`,
		RS052403: `AP II,S.II,I - CARNES/PROD.COMEST. BOVINO, OVINO E BUFALINO - ICMS JÁ RECOLHIDO POR ST - Carne de gado bovino, ovino e bufalino e demais produtos comestíveis`,
		RS052405: `AP II,S.III,XVI -SORVETES - ICMS JÁ RECOLHIDO POR ST - Sorvetes, inclusive sanduíches de sorvetes e preparados para fabricação de sorvete em máquina`,
		RS052407: `AP II,S.II,II -PAPEL PARA CIGARRO - ICMS JÁ RECOLHIDO POR ST - Papel para cigarro, classificação na NBM/SH-NCM 4813.10.00`,
		RS052408: `AP II,S.III,I -BEBIDAS - ICMS JÁ RECOLHIDO POR ST - Bebidas, incluindo água mineral, refrigerantes, energéticos, cerveja e chopp `,
		RS052409: `AP II,S.III,II -CIGARROS - ICMS JÁ RECOLHIDO POR ST - Cigarros e outros produtos derivados do fumo`,
		RS052410: `AP II,S.III,III -CIMENTO - ICMS JÁ RECOLHIDO POR ST - Cimento, classificação na NBM/SH-NCM 2523`,
		RS052411: `AP II,S.III,IV -COMBUSTIVEIS - ICMS JÁ RECOLHIDO POR ST - Combustíves e lubrificantes derivados ou não de petróleo, e outros produtos`,
		RS052412: `AP II,S.III,V -PNEUMAT,CAMARAS AR,PROTET BORRACHA - ICMS JÁ RECOLHIDO POR ST -  Pneumáticos, câmaras de ar e protetores de borracha, exceto os pneus e câmaras de bicicleta`,
		RS052413: `AP II,S.III,VI -PRODUTOS FARMACEUTICOS - ICMS JÁ RECOLHIDO POR ST - Consulte o Apêndice II,Seção III, item VI, do RICMS, para ver a lista completa`,
		RS052415: `AP II,S.III,VIII -TINTAS,VERNIZES,OUTRAS(IND QUIM) - ICMS JÁ RECOLHIDO POR ST - Tintas e vernizes, xadrez e pós assemelhados, e corantes`,
		RS052416: `AP II,S.III,IX -MOTOCICLETAS - ICMS JÁ RECOLHIDO POR ST - Veículos de duas e três rodas motorizados`,
		RS052417: `AP II,S.III,X -AUTOMOVEIS - ICMS JÁ RECOLHIDO POR ST - Veículos automotores novos`,
		RS052420: `AP II,S.III,XIII-LAMINAS BARBEAR,AP.BARBEAR DESC E - ICMS JÁ RECOLHIDO POR ST - Aparelhos e lâminas de barbear, classificação na NBM/SH-NCM 8212.10.20 e 8212.20.10`,
		RS052426: `AP II,S.III,XIX-RACOES TIPO PET P/ANIMAIS DOM. - ICMS JÁ RECOLHIDO POR ST - Rações tipo "pet" para animais domésticos, classificação na NBM/SH-NCM 2309`,
		RS052427: `AP II,S.III,XX-PECAS,COMP.E ACES.P/PROD.AUTOP. - ICMS JÁ RECOLHIDO POR ST - Autopeças, consulte o Apêndice II,Seção III, item XX, do RICMS, para ver a lista completa`,
		RS052429: `AP II,S.III,XXII - COSM.,PERF.,HIG.PESSOAL E TOUC. - ICMS JÁ RECOLHIDO POR ST - Perfumaria, higiene pessoal e cosméticos, lista completa no Ap.II,S.III, XXII, do RICMS`,
		RS052434: `AP II,S.III,XXVI. MAT.CONSTR.,ACAB.,BRIC. E ADORNO - ICMS JÁ RECOLHIDO POR ST - Materiais de construção, acabamento, bricolagem ou adorno`,
		RS052440: `AP II,S.III,XXXII.BEBIDAS QUENTES - ICMS JÁ RECOLHIDO POR ST - Bebidas alcoólicas quentes, lista completa no Apêndice II,Seção III, item XXXII, do RICMS `,
		RS052447: `AP II,S.II,III. CARNES/PROD.COMEST. AVES E SUINOS - ICMS JÁ RECOLHIDO POR ST - Aves inteiras, carnes e demais produtos comestíveis resultantes do abate de aves e de suínos`,
		RS052448: `AP II,S.II,IV. TELHAS E ARTEFATOS P/ CONSTRUCOES - ICMS JÁ RECOLHIDO POR ST - Telhas metálicas e artefatos para apetrechamento de construções, de plástico`,
		RS052901: `LIVRO II, ART. 12 - DECISÕES JUDICIAIS - OUTRAS - Saídas desoneradas por decisões judiciais`,
		RS052902: `IN45 - T.I - CAP. XXVIII - 2.2 - OPERAÇÕES ESPECÍFICAS DA PETROBRAS - OUTRAS - Operações de transferência e das destinadas à comercialização pela Petrobras por navegação de cabotagem`,
		RS052904: `AJUSTE SINIEF 13-2017 - OPERAÇÕES ESPECÍFICAS DA PETROBRAS E TRANSPETRO - OUTRAS - Operações de remessa p/armazenagem e moviment.de petróleo/deriv./gás natural, dutoviário, PETROBRAS/TRANSPETRO`,
		RS052999: `Anexo V.B - OUTRAS - OUTRAS - Utilizar apenas quando não houver código correspondente disponibilizado na Tabela 5.2 do Sped Fiscal`,
	},
	RJ: {
		RJ801001: "Convênio AE 5 de 1972 - Isenção",
		RJ823002: "Convênio AE 15 de 1974 - Suspensão",
		RJ801003: "Convênio do Rio de Janeiro I de 1967 - Isenção",
		RJ801004: "Convênio do Rio de Janeiro V de 1968 - Isenção",
		RJ801005: "Convênio ICM 01 de 1975 - Isenção",
		RJ801006: "Convênio ICM 10 de 1975 - Isenção",
		RJ801007: "Convênio ICM 12 de 1975 - Isenção",
		RJ802008: "Convênio ICM 15 de 1981 - Redução de Base de Cálculo",
		RJ808009: "Convênio ICM 24 de 1975 - Ampliação de prazo de pagamento",
		RJ801010: "Convênio ICM 26 de 1975 - Isenção",
		RJ803010: "Convênio ICM 26 de 1975 - Inexigibilidade de estorno de crédito",
		RJ801011: "Convênio ICM 32 de 1975 - Isenção",
		RJ801012: "Convênio ICM 33 de 1977 - Isenção",
		RJ801013: "Convênio ICM 35 de 1977 - Isenção",
		RJ801014: "Convênio ICM 38 de 1982 - Isenção",
		RJ801015: "Convênio ICM 40 de 1975 - Isenção",
		RJ801016: "Convênio ICM 44 de 1975 - Isenção",
		RJ801017: "Convênio ICM 65 de 1988 - Isenção",
		RJ803017: "Convênio ICM 65 de 1988 - Inexigibilidade de estorno de crédito",
		RJ801018: "Convênio ICMS 1 de 1991 - Isenção",
		RJ801019: "Convênio ICMS 1 de 1999 - Isenção",
		RJ803019: "Convênio ICMS 1 de 1999 - Inexigibilidade de estorno de crédito",
		RJ801020: "Convênio ICMS 1 de 2013 - Isenção",
		RJ802020: "Convênio ICMS 1 de 2013 - Redução de Base de Cálculo",
		RJ801021: "Convênio ICMS 3 de 1990 - Isenção",
		RJ801022: "Convênio ICMS 3 de 2006 - Isenção",
		RJ801023: "Convênio ICMS 3 de 2010 - Isenção",
		RJ801024: "Convênio ICMS 3 de 2018 - Isenção",
		RJ802024: "Convênio ICMS 3 de 2018 - Redução de Base de Cálculo",
		RJ803024: "Convênio ICMS 3 de 2018 - Inexigibilidade de estorno de crédito",
		RJ823024: "Convênio ICMS 3 de 2018 - Suspensão",
		RJ818024: "Convênio ICMS 3 de 2018 - Diferimento",
		RJ801025: "Convênio ICMS 4 de 1997 - Isenção",
		RJ821025: "Convênio ICMS 4 de 1997 - Repasse do Crédito Fiscal",
		RJ801026: "Convênio ICMS 4 de 1998 - Isenção",
		RJ801027: "Convênio ICMS 5 de 1993 - Isenção",
		RJ801028: "Convênio ICMS 5 de 2000 - Isenção",
		RJ801029: "Convênio ICMS 5 de 2008 - Isenção",
		RJ801030: "Convênio ICMS 8 de 1989 - Isenção",
		RJ805031: "Convênio ICMS 8 de 2003 - Crédito Presumido",
		RJ823032: "Convênio ICMS 8 de 2005 - Suspensão",
		RJ823033: "Convênio ICMS 9 de 2005 - Suspensão",
		RJ801033: "Convênio ICMS 9 de 2005 - Isenção",
		RJ803034: "Convênio ICMS 9 de 2006 - Inexigibilidade de estorno de crédito",
		RJ801034: "Convênio ICMS 9 de 2006 - Isenção",
		RJ801035: "Convênio ICMS 10 de 2002 - Isenção",
		RJ801036: "Convênio ICMS 10 de 2007 - Isenção",
		RJ801037: "Convênio ICMS 11 de 1993 - Isenção",
		RJ801038: "Convênio ICMS 12 de 1993 - Isenção",
		RJ802039: "Convênio ICMS 13 de 1994 - Redução de Base de Cálculo",
		RJ801040: "Convênio ICMS 14 de 2003 - Isenção",
		RJ803041: "Convênio ICMS 15 de 2000 - Inexigibilidade de estorno de crédito",
		RJ801041: "Convênio ICMS 15 de 2000 - Isenção",
		RJ801042: "Convênio ICMS 16 de 2015 - Isenção",
		RJ801043: "Convênio ICMS 17 de 2000 - Isenção",
		RJ801044: "Convênio ICMS 17 de 2002 - Isenção",
		RJ801045: "Convênio ICMS 17 de 2013 - Isenção",
		RJ802046: "Convênio ICMS 18 de 1992 - Redução de Base de Cálculo",
		RJ801047: "Convênio ICMS 18 de 1995 - Isenção",
		RJ801048: "Convênio ICMS 18 de 1997 - Isenção",
		RJ801049: "Convênio ICMS 18 de 2003 - Isenção",
		RJ823050: "Convênio ICMS 19 de 1991 - Suspensão",
		RJ801051: "Convênio ICMS 20 de 1989 - Isenção",
		RJ803052: "Convênio ICMS 23 de 2007 - Inexigibilidade de estorno de crédito",
		RJ801052: "Convênio ICMS 23 de 2007 - Isenção",
		RJ801053: "Convênio ICMS 24 de 1989 - Isenção",
		RJ821054: "Convênio ICMS 26 de 2003 - Repasse do Crédito Fiscal",
		RJ803054: "Convênio ICMS 26 de 2003 - Inexigibilidade de estorno de crédito",
		RJ801054: "Convênio ICMS 26 de 2003 - Isenção",
		RJ801055: "Convênio ICMS 27 de 1990 - Isenção",
		RJ803056: "Convênio ICMS 27 de 2005 - Inexigibilidade de estorno de crédito",
		RJ801056: "Convênio ICMS 27 de 2005 - Isenção",
		RJ805057: "Convênio ICMS 27 de 2006 - Crédito Presumido",
		RJ801058: "Convênio ICMS 27 de 2007 - Isenção",
		RJ818059: "Convênio ICMS 28 de 2005 - Diferimento",
		RJ803059: "Convênio ICMS 28 de 2005 - Inexigibilidade de estorno de crédito",
		RJ801059: "Convênio ICMS 28 de 2005 - Isenção",
		RJ801060: "Convênio ICMS 29 de 1990 - Isenção",
		RJ801061: "Convênio ICMS 30 de 1996 - Isenção",
		RJ803062: "Convênio ICMS 30 de 2000 - Inexigibilidade de estorno de crédito",
		RJ801062: "Convênio ICMS 30 de 2000 - Isenção",
		RJ801063: "Convênio ICMS 30 de 2006 - Isenção",
		RJ801064: "Convênio ICMS 32 de 2006 - Isenção",
		RJ802065: "Convênio ICMS 33 de 1993 - Redução de Base de Cálculo",
		RJ802066: "Convênio ICMS 33 de 1996 - Redução de Base de Cálculo",
		RJ801067: "Convênio ICMS 33 de 2001 - Isenção",
		RJ801068: "Convênio ICMS 33 de 2010 - Isenção",
		RJ801069: "Convênio ICMS 34 de 1992 - Isenção",
		RJ803069: "Convênio ICMS 34 de 1992 - Inexigibilidade de estorno de crédito",
		RJ803070: "Convênio ICMS 34 de 2006 - Inexigibilidade de estorno de crédito",
		RJ802070: "Convênio ICMS 34 de 2006 - Redução de Base de Cálculo",
		RJ801071: "Convênio ICMS 35 de 1992 - Isenção",
		RJ801072: "Convênio ICMS 35 de 1993 - Isenção",
		RJ801073: "Convênio ICMS 37 de 1989 - Isenção",
		RJ803074: "Convênio ICMS 38 de 2001 - Inexigibilidade de estorno de crédito",
		RJ801074: "Convênio ICMS 38 de 2001 - Isenção",
		RJ801075: "Convênio ICMS 38 de 2009 - Isenção",
		RJ803075: "Convênio ICMS 38 de 2009 - Inexigibilidade de estorno de crédito",
		RJ801076: "Convênio ICMS 38 de 2012 - Isenção",
		RJ803076: "Convênio ICMS 38 de 2012 - Inexigibilidade de estorno de crédito",
		RJ801077: "Convênio ICMS 41 de 1991 - Isenção",
		RJ802078: "Convênio ICMS 41 de 2005 - Redução de Base de Cálculo",
		RJ801079: "Convênio ICMS 42 de 1995 - Isenção",
		RJ801080: "Convênio ICMS 42 de 2001 - Isenção",
		RJ801081: "Convênio ICMS 42 de 2006 - Isenção",
		RJ801082: "Convênio ICMS 43 de 1999 - Isenção",
		RJ801083: "Convênio ICMS 43 de 2010 - Isenção",
		RJ801084: "Convênio ICMS 47 de 1998 - Isenção",
		RJ801085: "Convênio ICMS 48 de 1993 - Isenção",
		RJ803086: "Convênio ICMS 49 de 1994 - Inexigibilidade de estorno de crédito",
		RJ801086: "Convênio ICMS 49 de 1994 - Isenção",
		RJ801087: "Convênio ICMS 49 de 2001 - Isenção",
		RJ802088: "Convênio ICMS 50 de 1992 - Redução de Base de Cálculo",
		RJ802089: "Convênio ICMS 50 de 1993 - Redução de Base de Cálculo",
		RJ803090: "Convênio ICMS 52 de 1991 - Inexigibilidade de estorno de crédito",
		RJ802090: "Convênio ICMS 52 de 1991 - Redução de Base de Cálculo",
		RJ801091: "Convênio ICMS 52 de 1992 - Isenção",
		RJ801092: "Convênio ICMS 52 de 2003 - Isenção",
		RJ803093: "Convênio ICMS 53 de 2007 - Inexigibilidade de estorno de crédito",
		RJ801093: "Convênio ICMS 53 de 2007 - Isenção",
		RJ801094: "Convênio ICMS 55 de 1989 - Isenção",
		RJ801095: "Convênio ICMS 55 de 1992 - Isenção",
		RJ801096: "Convênio ICMS 55 de 2012 - Isenção",
		RJ803097: "Convênio ICMS 57 de 1998 - Inexigibilidade de estorno de crédito",
		RJ801097: "Convênio ICMS 57 de 1998 - Isenção",
		RJ802098: "Convênio ICMS 57 de 1999 - Redução de Base de Cálculo",
		RJ801099: "Convênio ICMS 58 de 1996 - Isenção",
		RJ802100: "Convênio ICMS 58 de 1999 - Redução de Base de Cálculo",
		RJ801100: "Convênio ICMS 58 de 1999 - Isenção",
		RJ803101: "Convênio ICMS 60 de 1992 - Inexigibilidade de estorno de crédito",
		RJ801101: "Convênio ICMS 60 de 1992 - Isenção",
		RJ801102: "Convênio ICMS 61 de 1997 - Isenção",
		RJ803103: "Convênio ICMS 62 de 2003 - Inexigibilidade de estorno de crédito",
		RJ801103: "Convênio ICMS 62 de 2003 - Isenção",
		RJ818104: "Convênio ICMS 63 de 1998 - Diferimento",
		RJ801105: "Convênio ICMS 63 de 2000 - Isenção",
		RJ801106: "Convênio ICMS 64 de 1995 - Isenção",
		RJ803107: "Convênio ICMS 65 de 2005 - Inexigibilidade de estorno de crédito",
		RJ801107: "Convênio ICMS 65 de 2005 - Isenção",
		RJ801108: "Convênio ICMS 69 de 2001 - Isenção",
		RJ801109: "Convênio ICMS 69 de 2006 - Isenção",
		RJ801110: "Convênio ICMS 70 de 1990 - Isenção",
		RJ801111: "Convênio ICMS 70 de 1992 - Isenção",
		RJ801112: "Convênio ICMS 73 de 2010 - Isenção",
		RJ801113: "Convênio ICMS 74 de 2000 - Isenção",
		RJ823114: "Convênio ICMS 75 de 1990 - Suspensão",
		RJ802114: "Convênio ICMS 75 de 1990 - Redução de Base de Cálculo",
		RJ802115: "Convênio ICMS 75 de 1991 - Redução de Base de Cálculo",
		RJ803116: "Convênio ICMS 75 de 1997 - Inexigibilidade de estorno de crédito",
		RJ801116: "Convênio ICMS 75 de 1997 - Isenção",
		RJ802117: "Convênio ICMS 77 de 1995 - Redução de Base de Cálculo",
		RJ801118: "Convênio ICMS 77 de 2000 - Isenção",
		RJ803119: "Convênio ICMS 78 de 1992 - Inexigibilidade de estorno de crédito",
		RJ801119: "Convênio ICMS 78 de 1992 - Isenção",
		RJ801120: "Convênio ICMS 79 de 2005 - Isenção",
		RJ801121: "Convênio ICMS 80 de 1995 - Isenção",
		RJ801122: "Convênio ICMS 80 de 2005 - Isenção",
		RJ801123: "Convênio ICMS 81 de 2008 - Isenção",
		RJ803124: "Convênio ICMS 82 de 1995 - Inexigibilidade de estorno de crédito",
		RJ801124: "Convênio ICMS 82 de 1995 - Isenção",
		RJ801125: "Convênio ICMS 84 de 1990 - Isenção",
		RJ803126: "Convênio ICMS 84 de 1997 - Inexigibilidade de estorno de crédito",
		RJ801126: "Convênio ICMS 84 de 1997 - Isenção",
		RJ805127: "Convênio ICMS 85 de 2011 - Crédito Presumido",
		RJ802128: "Convênio ICMS 86 de 1999 - Redução de Base de Cálculo",
		RJ803129: "Convênio ICMS 87 de 2002 - Inexigibilidade de estorno de crédito",
		RJ801129: "Convênio ICMS 87 de 2002 - Isenção",
		RJ801130: "Convênio ICMS 88 de 1991 - Isenção",
		RJ823131: "Convênio ICMS 88 de 2012 - Suspensão",
		RJ801131: "Convênio ICMS 88 de 2012 - Isenção",
		RJ802132: "Convênio ICMS 89 de 2005 - Redução de Base de Cálculo",
		RJ801133: "Convênio ICMS 89 de 2010 - Isenção",
		RJ801134: "Convênio ICMS 91 de 1991 - Isenção",
		RJ803134: "Convênio ICMS 91 de 1991 - Inexigibilidade de estorno de crédito",
		RJ802135: "Convênio ICMS 91 de 2012 - Redução de Base de Cálculo",
		RJ801136: "Convênio ICMS 93 de 1998 - Isenção",
		RJ801137: "Convênio ICMS 94 de 2005 - Isenção",
		RJ801138: "Convênio ICMS 94 de 2012 - Isenção",
		RJ803138: "Convênio ICMS 94 de 2012 - Inexigibilidade de estorno de crédito",
		RJ801139: "Convênio ICMS 95 de 1998 - Isenção",
		RJ802140: "Convênio ICMS 95 de 2012 - Redução de Base de Cálculo",
		RJ801141: "Convênio ICMS 98 de 2008 - Isenção",
		RJ801142: "Convênio ICMS 99 de 1989 - Isenção",
		RJ801143: "Convênio ICMS 100 de 1997 - Isenção",
		RJ802143: "Convênio ICMS 100 de 1997 - Redução de Base de Cálculo",
		RJ805143: "Convênio ICMS 100 de 1997 - Crédito Presumido",
		RJ803143: "Convênio ICMS 100 de 1997 - Inexigibilidade de estorno de crédito",
		RJ801144: "Convênio ICMS 101 de 1997 - Isenção",
		RJ803144: "Convênio ICMS 101 de 1997 - Inexigibilidade de estorno de crédito",
		RJ801145: "Convênio ICMS 103 de 2011 - Isenção",
		RJ801146: "Convênio ICMS 104 de 1989 - Isenção",
		RJ801147: "Convênio ICMS 105 de 1995 - Isenção",
		RJ801148: "Convênio ICMS 105 de 2003 - Isenção",
		RJ805149: "Convênio ICMS 106 de 1996 - Crédito Presumido",
		RJ801150: "Convênio ICMS 106 de 2010 - Isenção",
		RJ801151: "Convênio ICMS 107 de 1995 - Isenção",
		RJ818152: "Convênio ICMS 110 de 2007 - Diferimento",
		RJ819152: "CONVÊNIO ICMS 110 de 2007 - não incidência",
		RJ802153: "Convênio ICMS 112 de 1989 - Redução de Base de Cálculo",
		RJ801154: "Convênio ICMS 112 de 1998 - Isenção",
		RJ802155: "Convênio ICMS 113 de 2006 - Redução de Base de Cálculo",
		RJ803156: "Convênio ICMS 114 de 2009 - Inexigibilidade de estorno de crédito",
		RJ802156: "Convênio ICMS 114 de 2009 - Redução de Base de Cálculo",
		RJ801157: "Convênio ICMS 116 de 1998 - Isenção",
		RJ803157: "Convênio ICMS 116 de 1998 - Inexigibilidade de estorno de crédito",
		RJ801158: "Convênio ICMS 120 de 2002 - Isenção",
		RJ801159: "Convênio ICMS 122 de 2003 - Isenção",
		RJ803159: "Convênio ICMS 122 de 2003 - Inexigibilidade de estorno de crédito",
		RJ801160: "Convênio ICMS 123 de 1992 - Isenção",
		RJ801161: "Convênio ICMS 123 de 1997 - Isenção",
		RJ801162: "Convênio ICMS 125 de 2001 - Isenção",
		RJ801163: "Convênio ICMS 126 de 2010 - Isenção",
		RJ803163: "Convênio ICMS 126 de 2010 - Inexigibilidade de estorno de crédito",
		RJ803164: "Convênio ICMS 128 de 1994 - Inexigibilidade de estorno de crédito",
		RJ802164: "Convênio ICMS 128 de 1994 - Redução de Base de Cálculo",
		RJ801165: "Convênio ICMS 129 de 2006 - Isenção",
		RJ801166: "Convênio ICMS 129 de 2012 - Isenção",
		RJ820167: "Convênio ICMS 130 de 1994 - Redução de alíquota",
		RJ801167: "Convênio ICMS 130 de 1994 - Isenção",
		RJ803167: "Convênio ICMS 130 de 1994 - Inexigibilidade de estorno de crédito",
		RJ821168: "Convênio ICMS 130 de 2007 - Transferência de saldo credor acumulado",
		RJ801168: "Convênio ICMS 130 de 2007 - Isenção",
		RJ802168: "Convênio ICMS 130 de 2007 - Redução de Base de Cálculo",
		RJ802169: "Convênio ICMS 132 de 1993 - Redução de Base de Cálculo",
		RJ801170: "Convênio ICMS 132 de 2004 - Isenção",
		RJ803171: "Convênio ICMS 133 de 2002 - Inexigibilidade de estorno de crédito",
		RJ802171: "Convênio ICMS 133 de 2002 - Redução de Base de Cálculo",
		RJ823172: "Convênio ICMS 136 de 1993 - Suspensão",
		RJ801173: "Convênio ICMS 136 de 1994 - Isenção",
		RJ801174: "Convênio ICMS 138 de 2005 - Isenção",
		RJ801175: "Convênio ICMS 140 de 2001 - Isenção",
		RJ803175: "Convênio ICMS 140 de 2001 - Inexigibilidade de estorno de crédito",
		RJ803176: "Convênio ICMS 141 de 2007 - Inexigibilidade de estorno de crédito",
		RJ801176: "Convênio ICMS 141 de 2007 - Isenção",
		RJ805177: "Convênio ICMS 141 de 2011 - Crédito Presumido",
		RJ801178: "Convênio ICMS 142 de 1992 - Isenção",
		RJ801179: "Convênio ICMS 144 de 2006 - Isenção",
		RJ803180: "Convênio ICMS 147 de 2007 - Inexigibilidade de estorno de crédito",
		RJ801180: "Convênio ICMS 147 de 2007 - Isenção",
		RJ801181: "Convênio ICMS 150 de 2013 - Isenção",
		RJ802182: "Convênio ICMS 153 de 2004 - Redução de Base de Cálculo",
		RJ801183: "Convênio ICMS 158 de 1994 - Isenção",
		RJ801184: "Convênio ICMS 162 de 1994 - Isenção",
		RJ801185: "Convênio ICMS 165 de 1992 - Isenção",
		RJ802186: "Convênio ICMS  45 de 2014 - Redução de Base de Cálculo",
		RJ818187: "Decreto 14.236 de 1989 - Diferimento",
		RJ818188: "Decreto 16.358 de 1991 - Diferimento",
		RJ808189: "Decreto 21.550 de 1995 - Ampliação de prazo de pagamento",
		RJ805189: "Decreto 21.550 de 1995 - Crédito Presumido",
		RJ818190: "Decreto 22.266 de 1996 - Diferimento",
		RJ818191: "Decreto 23.082 de 1997 - Diferimento",
		RJ805192: "Decreto 25.404 de 1999 - Crédito Presumido",
		RJ818193: "Decreto 25.665 de 1999 - Diferimento",
		RJ808193: "Decreto 25.665 de 1999 - Ampliação de prazo de pagamento",
		RJ805194: "Decreto 25.666 de 1999 - Crédito Presumido",
		RJ802195: "Decreto 26.004 de 2000 - Redução de Base de Cálculo",
		RJ802196: "Decreto 26.116 de 2000 - Redução de Base de Cálculo",
		RJ802197: "Decreto 26.140 de 2000 - Redução de Base de Cálculo",
		RJ801198: "Decreto 26.260 de 2000 - Isenção",
		RJ808199: "Decreto 26.274 de 2000 - Ampliação de prazo de pagamento",
		RJ818199: "Decreto 26.274 de 2000 - Diferimento",
		RJ805200: "Decreto 26.788 de 2000 - Crédito Presumido",
		RJ821201: "Decreto 27.091 de 2000 - Transferência de saldo credor acumulado",
		RJ801202: "Decreto 27.159 de 2000 - Isenção",
		RJ802203: "Decreto 27.308 de 2000 - Redução de Base de Cálculo",
		RJ801204: "Decreto 27.815 de 2001 - Isenção",
		RJ808205: "Decreto 27.857 de 2001 - Ampliação de prazo de pagamento",
		RJ802206: "Decreto 28.494 de 2001 - Redução de Base de Cálculo",
		RJ802207: "Decreto 28.940 de 2001 - Redução de Base de Cálculo",
		RJ805208: "Decreto 29.042 de 2001 - Crédito Presumido",
		RJ803208: "Decreto 29.042 de 2001 - Inexigibilidade de estorno de crédito",
		RJ802209: "Decreto 29.366 de 2001 - Redução de Base de Cálculo",
		RJ802210: "Decreto 29.722 de 2001 - Redução de Base de Cálculo",
		RJ802211: "Decreto 29.882 de 2001 - Redução de Base de Cálculo",
		RJ818211: "Decreto 29.882 de 2001 - Diferimento",
		RJ818212: "Decreto 30.853 de 2002 - Diferimento",
		RJ805212: "Decreto 30.853 de 2002 - Crédito Presumido",
		RJ818213: "Decreto 31.175 de 2002 - Diferimento",
		RJ808214: "Decreto 32.701 de 2003 - Ampliação de prazo de pagamento",
		RJ818215: "Decreto 33.934 de 2003 - Diferimento",
		RJ801215: "Decreto 33.934 de 2003 - Isenção",
		RJ805216: "Convênio 23 de 1990 - Crédito Presumido",
		RJ818217: "Decreto 34.169 de 2003 - Diferimento",
		RJ818218: "Decreto 34.170 de 2003 - Diferimento",
		RJ818219: "Decreto 34.171 de 2003 - Diferimento",
		RJ801220: "Decreto 35.220 de 2004 - Isenção",
		RJ818220: "Decreto 35.220 de 2004 - Diferimento",
		RJ802221: "Decreto 35.418 de 2004 - Redução de Base de Cálculo",
		RJ818221: "Decreto 35.418 de 2004 - Diferimento",
		RJ821221: "Decreto 35.418 de 2004 - Transferência de saldo credor acumulado",
		RJ805222: "Decreto 35.419 de 2004 - Crédito Presumido",
		RJ818223: "Decreto 35.985 de 2004 - Diferimento",
		RJ805224: "Decreto 36.111 de 2004 - Crédito Presumido",
		RJ818225: "Decreto 36.279 de 2004 - Diferimento",
		RJ803226: "Decreto 36.297 de 2004 - Inexigibilidade de estorno de crédito",
		RJ802226: "Decreto 36.297 de 2004 - Redução de Base de Cálculo",
		RJ821227: "Decreto 36.324 de 2004 - Transferência de saldo credor acumulado",
		RJ818228: "Decreto 36.376 de 2004 - Diferimento",
		RJ805228: "Decreto 36.376 de 2004 - Crédito Presumido",
		RJ803229: "Decreto 36.448 de 2004 - Inexigibilidade de estorno de crédito",
		RJ818229: "Decreto 36.448 de 2004 - Diferimento",
		RJ802229: "Decreto 36.448 de 2004 - Redução de Base de Cálculo",
		RJ818230: "Decreto 36.449 de 2004 - Diferimento",
		RJ805230: "Decreto 36.449 de 2004 - Crédito Presumido",
		RJ803231: "Decreto 36.450 de 2004 - Inexigibilidade de estorno de crédito",
		RJ821231: "Decreto 36.450 de 2004 - Transferência de saldo credor acumulado",
		RJ802231: "Decreto 36.450 de 2004 - Redução de Base de Cálculo",
		RJ805231: "Decreto 36.450 de 2004 - Crédito Presumido",
		RJ818231: "Decreto 36.450 de 2004 - Diferimento",
		RJ802232: "Decreto 36.451 de 2004 - Redução de Base de Cálculo",
		RJ818232: "Decreto 36.451 de 2004 - Diferimento",
		RJ803232: "Decreto 36.451 de 2004 - Inexigibilidade de estorno de crédito",
		RJ805233: "Decreto 36.452 de 2004 - Crédito Presumido",
		RJ818234: "Decreto 36.453 de 2004 - Diferimento",
		RJ802234: "Decreto 36.453 de 2004 - Redução de Base de Cálculo",
		RJ818235: "Decreto 36.458 de 2004 - Diferimento",
		RJ818236: "Decreto 36.460 de 2004 - Diferimento",
		RJ818237: "Decreto 36.461 de 2004 - Diferimento",
		RJ818238: "Decreto 36.463 de 2004 - Diferimento",
		RJ818239: "Decreto 36.468 de 2004 - Diferimento",
		RJ818240: "Decreto 36.474 de 2004 - Diferimento",
		RJ818241: "Decreto 36.478 de 2004 - Diferimento",
		RJ818242: "Decreto 36.489 de 2004 - Diferimento",
		RJ818243: "Decreto 37.149 de 2005 - Diferimento",
		RJ818244: "Decreto 37.152 de 2005 - Diferimento",
		RJ818245: "Decreto 37.154 de 2005 - Diferimento",
		RJ818246: "Decreto 37.159 de 2005 - Diferimento",
		RJ818247: "Decreto 37.168 de 2005 - Diferimento",
		RJ818248: "Decreto 37.170 de 2005 - Diferimento",
		RJ818249: "Decreto 37.172 de 2005 - Diferimento",
		RJ818250: "Decreto 37.177 de 2005 - Diferimento",
		RJ818251: "Decreto 37.179 de 2005 - Diferimento",
		RJ818252: "Decreto 37.198 de 2005 - Diferimento",
		RJ818253: "Decreto 37.207 de 2005 - Diferimento",
		RJ818254: "Decreto 37.210 de 2005 - Diferimento",
		RJ818255: "Decreto 37.256 de 2005 - Diferimento",
		RJ818256: "Decreto 37.257 de 2005 - Diferimento",
		RJ818257: "Decreto 37.260 de 2005 - Diferimento",
		RJ818258: "Decreto 37.261 de 2005 - Diferimento",
		RJ818259: "Decreto 37.263 de 2005 - Diferimento",
		RJ818260: "Decreto 37.590 de 2005 - Diferimento",
		RJ818261: "Decreto 37.598 de 2005 - Diferimento",
		RJ818262: "Decreto 37.599 de 2005 - Diferimento",
		RJ818263: "Decreto 37.600 de 2005 - Diferimento",
		RJ818264: "Decreto 37.888 de 2005 - Diferimento",
		RJ818265: "Decreto 38.231 de 2005 - Diferimento",
		RJ802266: "Decreto 38.938 de 2006 - Redução de Base de Cálculo",
		RJ805266: "Decreto 38.938 de 2006 - Crédito Presumido",
		RJ818266: "Decreto 38.938 de 2006 - Diferimento",
		RJ801266: "Decreto 38.938 de 2006 - Isenção",
		RJ818267: "Decreto 39.116 de 2006 - Diferimento",
		RJ803268: "Decreto 39.565 de 2006 - Inexigibilidade de estorno de crédito",
		RJ803269: "Decreto 39.566 de 2006 - Inexigibilidade de estorno de crédito",
		RJ818270: "Decreto 39.784 de 2006 - Diferimento",
		RJ805270: "Decreto 39.784 de 2006 - Crédito Presumido",
		RJ801271: "Decreto 40.435 de 2006 - Isenção",
		RJ818271: "Decreto 40.435 de 2006 - Diferimento",
		RJ803272: "Decreto 40.442 de 2006 - Inexigibilidade de estorno de crédito",
		RJ821272: "Decreto 40.442 de 2006 - Transferência de saldo credor acumulado",
		RJ802272: "Decreto 40.442 de 2006 - Redução de Base de Cálculo",
		RJ801272: "Decreto 40.442 de 2006 - Isenção",
		RJ820272: "Decreto 40.442 de 2006 - Redução de alíquota",
		RJ818272: "Decreto 40.442 de 2006 - Diferimento",
		RJ818273: "Decreto 40.456 de 2006 - Diferimento",
		RJ805274: "Decreto 40.478 de 2006 - Crédito Presumido",
		RJ818274: "Decreto 40.478 de 2006 - Diferimento",
		RJ808275: "Decreto 40.562 de 2007 - Ampliação de prazo de pagamento",
		RJ818276: "Decreto 41.244 de 2008 - Diferimento",
		RJ802277: "Decreto 41.483 de 2008 - Redução de Base de Cálculo",
		RJ821277: "Decreto 41.483 de 2008 - Transferência de saldo credor acumulado",
		RJ822277: "Decreto 41.483 de 2008 - Tributação sobre saída",
		RJ805277: "Decreto 41.483 de 2008 - Crédito Presumido",
		RJ818277: "Decreto 41.483 de 2008 - Diferimento",
		RJ818278: "Decreto 41.557 de 2008 - Diferimento",
		RJ802278: "Decreto 41.557 de 2008 - Redução de Base de Cálculo",
		RJ805279: "Decreto 41.596 de 2008 - Crédito Presumido",
		RJ805280: "Decreto 41.681 de 2009 - Crédito Presumido",
		RJ818280: "Decreto 41.681 de 2009 - Diferimento",
		RJ821281: "Decreto 41.766 de 2009 - Transferência de saldo credor acumulado",
		RJ818282: "Decreto 41.858 de 2009 - Diferimento",
		RJ805283: "Decreto 41.860 de 2009 - Crédito Presumido",
		RJ802283: "Decreto 41.860 de 2009 - Redução de Base de Cálculo",
		RJ801284: "Decreto 42.042 de 2009 - Isenção",
		RJ801285: "Decreto 42.109 de 2009 - Isenção",
		RJ818286: "Decreto 42.139 de 2009 - Diferimento",
		RJ823287: "Decreto 42.398 de 2010 - Suspensão",
		RJ818288: "Decreto 42.565 de 2010 - Diferimento",
		RJ805289: "Decreto 42.569 de 2010 - Crédito Presumido",
		RJ818289: "Decreto 42.569 de 2010 - Diferimento",
		RJ805290: "Decreto 42.588 de 2010 - Crédito Presumido",
		RJ818290: "Decreto 42.588 de 2010 - Diferimento",
		RJ805291: "Decreto 42.643 de 2010 - Crédito Presumido",
		RJ818292: "Decreto 42.647 de 2010 - Diferimento",
		RJ805293: "Decreto 42.649 de 2010 - Crédito Presumido",
		RJ818293: "Decreto 42.649 de 2010 - Diferimento",
		RJ818294: "Decreto 42.683 de 2010 - Diferimento",
		RJ805295: "Decreto 42.771 de 2010 - Crédito Presumido",
		RJ802296: "Decreto 42.897 de 2011 - Redução de Base de Cálculo",
		RJ820297: "Decreto 43.066 de 2011 - Redução de alíquota",
		RJ818298: "Decreto 43.128 de 2011 - Diferimento",
		RJ801298: "Decreto 43.128 de 2011 - Isenção",
		RJ818299: "Decreto 43.209 de 2011 - Diferimento",
		RJ818300: "Decreto 43.210 de 2011 - Diferimento",
		RJ818301: "Decreto 43.457 de 2012 - Diferimento",
		RJ805301: "Decreto 43.457 de 2012 - Crédito Presumido",
		RJ802302: "Decreto 43.502 de 2012 - Redução de Base de Cálculo",
		RJ805303: "Decreto 43.503 de 2012 - Crédito Presumido",
		RJ818303: "Decreto 43.503 de 2012 - Diferimento",
		RJ821304: "Decreto 43.603 de 2012 - Repasse do Crédito Fiscal",
		RJ818304: "Decreto 43.603 de 2012 - Diferimento",
		RJ805304: "Decreto 43.603 de 2012 - Crédito Presumido",
		RJ818305: "Decreto 43.709 de 2012 - Diferimento",
		RJ805305: "Decreto 43.709 de 2012 - Crédito Presumido",
		RJ818306: "Decreto 43.739 de 2012 - Diferimento",
		RJ822306: "Decreto 43.739 de 2012 - Tributação sobre saída",
		RJ818307: "Decreto 43.751 de 2012 - Diferimento",
		RJ818308: "Decreto 43.771 de 2012 - Diferimento",
		RJ802308: "Decreto 43.771 de 2012 - Redução de Base de Cálculo",
		RJ805308: "Decreto 43.771 de 2012 - Crédito Presumido",
		RJ818309: "Decreto 43.879 de 2012 - Diferimento",
		RJ802310: "Decreto 43.922 de 2012 - Redução de Base de Cálculo",
		RJ802311: "Decreto 44.418 de 2013 - Redução de Base de Cálculo",
		RJ818311: "Decreto 44.418 de 2013 - Diferimento",
		RJ805311: "Decreto 44.418 de 2013 - Crédito Presumido",
		RJ802312: "Decreto 44.498 de 2013 - Redução de Base de Cálculo",
		RJ818312: "Decreto 44.498 de 2013 - Diferimento",
		RJ802313: "Decreto 44.550 de 2014 - Redução de Base de Cálculo",
		RJ818314: "Decreto 44.607 de 2014 - Diferimento",
		RJ805314: "Decreto 44.607 de 2014 - Crédito Presumido",
		RJ803315: "Decreto 44.608 de 2014 - Inexigibilidade de estorno de crédito",
		RJ802316: "Decreto 44.615 de 2014 - Redução de Base de Cálculo",
		RJ805316: "Decreto 44.615 de 2014 - Crédito Presumido",
		RJ818316: "Decreto 44.615 de 2014 - Diferimento",
		RJ818317: "Decreto 44.629 de 2014 - Diferimento",
		RJ802317: "Decreto 44.629 de 2014 - Redução de Base de Cálculo",
		RJ818318: "Decreto 44.636 de 2014 - Diferimento",
		RJ805318: "Decreto 44.636 de 2014 - Crédito Presumido",
		RJ802319: "Decreto 44.677 de 2014 - Redução de Base de Cálculo",
		RJ818320: "Decreto 44.868 de 2014 - Diferiment",
		RJ822320: "Decreto 44.868 de 2014 - Tributação sobre saída",
		RJ818321: "Decreto 44.900 de 2014 - Diferimento",
		RJ818322: "Decreto 44.901 de 2014 - Diferimento",
		RJ802323: "Decreto 44.945 de 2014 - Redução de Base de Cálculo",
		RJ805323: "Decreto 44.945 de 2014 - Crédito Presumido",
		RJ802324: "Decreto 45.047 de 2014 - Redução de Base de Cálculo",
		RJ818324: "Decreto 45.047 de 2014 - Diferimento",
		RJ818325: "Decreto 45.072 de 2014 - Diferimento",
		RJ818326: "Decreto 45.308 de 2015 - Diferimento",
		RJ801326: "Decreto 45.308 de 2015 - Isenção",
		RJ818327: "Decreto 45.339 de 2015 - Diferimento",
		RJ802328: "Decreto 45.417 de 2015 - Redução de Base de Cálculo",
		RJ805328: "Decreto 45.417 de 2015 - Crédito Presumido",
		RJ818328: "Decreto 45.417 de 2015 - Diferimento",
		RJ818329: "Decreto 45.446 de 2015 - Diferimento",
		RJ805330: "Decreto 45.450 de 2015 - Crédito Presumido",
		RJ818331: "Decreto 45.586 de 2016 - Diferimento",
		RJ802332: "Decreto 45.607 de 2016 - Redução de Base de Cálculo",
		RJ805333: "Decreto 45.631 de 2016 - Crédito Presumido",
		RJ818333: "Decreto 45.631 de 2016 - Diferimento",
		RJ805334: "Decreto 45.777 de 2016 - Crédito Presumido",
		RJ803334: "Decreto 45.777 de 2016 - Inexigibilidade de estorno de crédito",
		RJ818335: "Decreto 45.780 de 2016 - Diferimento",
		RJ805335: "Decreto 45.780 de 2016 - Crédito Presumido",
		RJ818336: "Decreto 45.781 de 2016 - Diferimento",
		RJ822337: "Decreto 45782 de 2016 - Tributação sobre saída",
		RJ818337: "Decreto 45782 de 2016 - Diferimento",
		RJ801337: "Decreto 45782 de 2016 - Isenção",
		RJ823338: "Decreto 27.427/00, Livro I, Título IX, Art. 52, I de 2000 - Suspensão",
		RJ823339:
			"Decreto 27.427/00, Livro I, Título IX, Art. 52, inc. II de 2000 - Suspensão",
		RJ818340: "Decreto 27.427/00, Livro IV, Título VIII de 2000 - Diferimento",
		RJ822341: "Decreto 27.427/00, Livro V, Título VA de 2000 - Tributação sobre Receita",
		RJ818342: "Decreto 27.427/00, Livro XII, Título I de 2000 - Diferimento",
		RJ802343:
			"Decreto 27.427/00, Livro XIII, Título I, Capítulo I de 2000 - Redução de Base de Cálculo",
		RJ805344:
			"Decreto 27.427/00, Livro XIII, Título II, Art. 14 de 2000 - Crédito Presumido",
		RJ802344:
			"Decreto 27.427/00, Livro XIII, Título II, Art. 14 de 2000 - Redução de Base de Cálculo",
		RJ818345: "Decreto 27.427/00, Livro XV, Título I, Capítulo I de 2000 - Diferimento",
		RJ818346: "Decreto 27.427/00, Livro XV, Título I, Capítulo II de 2000 - Diferimento",
		RJ801347: "Decreto 27.427/00, Livro XV, Título III de 2000 - Isenção",
		RJ818347: "Decreto 27.427/00, Livro XV, Título III de 2000 - Diferimento",
		RJ821348:
			"Decreto 27.427/00, Livro XV, Título IV, Art. 31 de 2000 - Repasse do Crédito Fiscal",
		RJ823348: "Decreto 27.427/00, Livro XV, Título IV, Art. 31 de 2000 - Suspensão",
		RJ818348: "Decreto 27.427/00, Livro XV, Título IV, Art. 31 de 2000 - Diferimento",
		RJ822349: "Lei 2.804 de 1997 - Tributação sobre saída",
		RJ822350: "Lei 2.869 de 1997 - Tributação sobre saída",
		RJ818351: "Lei 3.578 de 2001 - Diferimento",
		RJ820352: "Lei 3.916 de 2002 - Redução de alíquota",
		RJ818353: "Lei 4.164 de 2003 - Diferimento",
		RJ801354: "Lei 4.166 de 2003 - Isenção",
		RJ820355: "Lei 4.169 de 2003 - Redução de alíquota",
		RJ818355: "Lei 4.169 de 2003 - Diferimento",
		RJ805355: "Lei 4.169 de 2003 - Crédito Presumido",
		RJ808355: "Lei 4.169 de 2003 - Ampliação de prazo de pagamento",
		RJ818356: "Lei 4.170 de 2003 - Diferimento",
		RJ818357: "Lei 4.173 de 2003 - Diferimento",
		RJ805357: "Lei 4.173 de 2003 - Crédito Presumido",
		RJ802358: "Lei 4.174 de 2003 - Redução de Base de Cálculo",
		RJ818358: "Lei 4.174 de 2003 - Diferimento",
		RJ805358: "Lei 4.174 de 2003 - Crédito Presumido",
		RJ818359: "Lei 4.177 de 2003 - Diferimento",
		RJ801359: "Lei 4.177 de 2003 - Isenção",
		RJ802359: "Lei 4.177 de 2003 - Redução de Base de Cálculo",
		RJ805359: "Lei 4.177 de 2003 - Crédito Presumido",
		RJ822359: "Lei 4.177 de 2003 - Tributação sobre Receita",
		RJ802360: "Lei 4.178 de 2003 - Redução de Base de Cálculo",
		RJ818360: "Lei 4.178 de 2003 - Diferimento",
		RJ805360: "Lei 4.178 de 2003 - Crédito Presumido",
		RJ805361: "Lei 4.183 de 2003 - Crédito Presumido",
		RJ818362: "Lei 4.184 de 2003 - Diferimento",
		RJ818363: "Lei 4.189 de 2003 - Diferimento",
		RJ805363: "Lei 4.189 de 2003 - Crédito Presumido",
		RJ802364: "Lei 4.344 de 2004 - Redução de Base de Cálculo",
		RJ801365: "Lei 4.485 de 2004 - Isenção",
		RJ818366: "Lei 4.529 de 2005 - Diferimento",
		RJ803366: "Lei 4.529 de 2005 - Inexigibilidade de estorno de crédito",
		RJ801366: "Lei 4.529 de 2005 - Isenção",
		RJ820366: "Lei 4.529 de 2005 - Redução de alíquota",
		RJ802366: "Lei 4.529 de 2005 - Redução de Base de Cálculo",
		RJ821366: "Lei 4.529 de 2005 - Transferência de saldo credor acumulado",
		RJ818367: "Lei  4.531 de 2005 - Diferimento",
		RJ822367: "Lei  4.531 de 2005 - Tributação sobre saída",
		RJ818368: "Lei 5.592 de 2009 - Diferimento",
		RJ821368: "Lei 5.592 de 2009 - Transferência de saldo credor acumulado",
		RJ821369: "Lei 6.078 de 2011 - Transferência de saldo credor acumulado",
		RJ818369: "Lei 6.078 de 2011 - Diferimento",
		RJ818370: "Lei 6.108 de 2011 - Diferimento",
		RJ821370: "Lei 6.108 de 2011 - Transferência de saldo credor acumulado",
		RJ803371: "Lei 6.331 de 2012 - Inexigibilidade de estorno de crédito",
		RJ822371: "Lei 6.331 de 2012 - Tributação sobre saída",
		RJ818371: "Lei 6.331 de 2012 - Diferimento",
		RJ802372: "Lei 6.648 de 2013 - Redução de Base de Cálculo",
		RJ818373: "Lei 6.662 de 2014 - Diferimento",
		RJ821373: "Lei 6.662 de 2014 - Transferência de saldo credor acumulado",
		RJ802374: "Lei 6.821 de 2014 - Redução de Base de Cálculo",
		RJ803375: "Lei 6.868 de 2014 - Inexigibilidade de estorno de crédito",
		RJ818375: "Lei 6.868 de 2014 - Diferimento",
		RJ822375: "Lei 6.868 de 2014 - Tributação sobre Faturamento",
		RJ821376: "Lei 6.953 de 2015 - Transferência de saldo credor acumulado",
		RJ818376: "Lei 6.953 de 2015 - Diferimento",
		RJ822377: "Lei 6.979 de 2015 - Tributação sobre saída",
		RJ818377: "Lei 6.979 de 2015 - Diferimento",
		RJ823377: "Lei 6.979 de 2015 - Suspensão",
		RJ801377: "Lei 6.979 de 2015 - Isenção",
		RJ820377: "Lei 6.979 de 2015 - Redução de Alíquota",
		RJ801378: "Lei 7.122 de 2015 - Isenção",
		RJ820379: "Lei 2.657/1996, art.14, inciso IX de 1996 - Redução de alíquota",
		RJ803379:
			"Lei 2.657/1996, art.14, inciso IX de 1996 - Inexigibilidade de estorno de crédito",
		RJ820380: "Lei 2.657/1996, art.14, inciso VI, alínea d de 1996 - Redução de alíquota",
		RJ820381:
			"Lei 2.657/1996, art.14, inciso XIII, alínea b de 1996 - Redução de alíquota",
		RJ803382:
			"Lei 2.657/1996, art.14, inciso XVI de 1996 - Inexigibilidade de estorno de crédito",
		RJ820382: "Lei 2.657/1996, art.14, inciso XVI de 1996 - Redução de alíquota",
		RJ820383: "Lei 2.657/1996, art.14, inciso XXV de 1996 - Redução de alíquota",
		RJ802384: "Lei 2.657/96, Art. 4º de 1996 - Redução de Base de Cálculo",
		RJ823385: "Protocolo ICMS 22 de 1999 - Suspensão",
		RJ818386: "Resolução SEEF 2.286 de 1993 - Diferimento",
		RJ818387: "Resolução SEEF 2.397 de 1994 - Diferimento",
		RJ823388: "Resolução SEF 1.606 de 1989 - Suspensão",
		RJ818389: "Resolução SEF 1.607 de 1989 - Diferimento",
		RJ818390: "Resolução SEF 1.610 de 1989 - Diferimento",
		RJ818391: "Resolução SEF 1.649 de 1989 - Diferimento",
		RJ818392: "Resolução SEF 6.484 de 2002 - Diferimento",
		RJ818393: "Resolução SEFAZ 726 de 2014 - Diferimento",
		RJ818394: "Resolução SER 84 de 2004 - Diferimento",
		RJ818395: "Resolução SER 319 de 2006 - Diferimento",
		RJ801396: "Convênio ICMS 63 de 2008 - Isenção",
		RJ818397: "Decreto 43.117 de 2011 - Diferimento",
		RJ801397: "Decreto 43.117 de 2011 - Isenção",
		RJ801398: "Lei 3.266 de 1999 - Isenção",
		RJ802399: "Convênio 5  de 2018 - Redução de Base de Cálculo",
		RJ803399: "Convênio 5  de 2018 - Inexigibilidade de estorno de crédito",
		RJ805400: "Convênio 64 de 2006 - Crédito Presumido",
		RJ819401: "Convênio 83 de 2000 - Não incidência",
		RJ805402: "Lei 8489 de 2019 - Crédito Presumido",
		RJ802402: "Lei 8489 de 2019 - Redução de Base de Cálculo",
		RJ820402: "Lei 8489 de 2019 - Redução de Alíquota",
		RJ818403: "Decreto  38.501/2005, §§ 2º e 3º do art. 3º. de 2005 - Diferimento",
		RJ823404: "Ajuste SINIEF 2 de 2018 - Suspensão",
		RJ818405: "Decreto 46.079 de 2017 - Diferimento",
		RJ805406: "Lei 8.482/2019, art. 1º inciso V de 2019 - Crédito Presumido",
		RJ802406:
			"Lei 8.482/2019, art. 1º incisos I, II, III e IV de 2019 - Redução de Base de Cálculo",
		RJ800000: "Devolução interestadual - benefício fiscal aplicado em outra UF",
		RJ818407: "Decreto  46.629 de 2019 - Diferimento",
		RJ802408: "Decreto  46.680 de 2019 - Redução de Base de Cálculo",
		RJ802409: "Convênio 6 de 2009 - Redução de Base de Cálculo",
		RJ818410: "Ajuste SINIEF 13 de 2017 - Diferimento",
		RJ818411: "Decreto 46793 de 2019 - Diferimento",
		RJ805411: "Decreto 46793 de 2019 - Crédito Presumido",
		RJ802411: "Decreto 46793 de 2019 - Redução de Base de Cálculo",
		RJ818412: "Decreto 46.781 de 2019 - Diferimento",
		RJ818413: "Decreto 46.799 de 2019 - Diferimento",
		RJ802414: "Convênio ICMS 188 de 2017 - Redução de Base de Cálculo",
		RJ801416: "Convênio ICMS 194 de 2019 - Isenção",
		RJ801417: "Convênio ICMS 66 de 2019 - Isenção",
		RJ802419: "Convênio ICMS 78 de 2015 - Redução de Base de Cálculo",
		RJ818418: "Decreto 46.944 de 2020 - Diferimento",
		RJ801424: "Convênio ICM 4 de 1979 - Isenção",
		RJ801425: "Convênio ICM 25 de 1983 - Isenção",
		RJ802425: "Convênio ICM 25 de 1983 - Redução de Base de Cálculo",
		RJ818426: "Decreto 39.729 de 2006 - Diferimento",
		RJ801427: "Convênio ICMS 81 de 2020 - Isenção",
		RJ802428: "Convênio ICMS 51 de 2020 - Redução de Base de Cálculo",
		RJ801429: "Convênio ICMS 26 de 2019 - Isenção",
		RJ801430: "Lei 9.162 de 2020, art. 2º  - Isenção",
		RJ801431: "Convênio ICMS 52 de 2020 - Isenção",
		RJ805432: "Lei 9.025 de 2020 - Crédito Presumido",
		RJ818432: "Lei 9.025 de 2020 - Diferimento",
		RJ820432: "Lei 9.025 de 2020 - Redução de Alíquota",
		RJ801433: "Convênio ICMS 20 de 1992 - Isenção",
		RJ802420: "Lei 8.792 de 2020 - Redução de Base de Cálculo",
		RJ805420: "Lei 8.792 de 2020 - Crédito Presumido",
		RJ805434: "Lei 8.960 de 2020 - Crédito Presumido",
		RJ818434: "Lei 8.960 de 2020 - Diferimento",
		RJ802434: "Lei 8.960 de 2020 - Redução de Base de Cáculo",
		RJ801435: "Convênio ICMS 63 de 2020 - Isenção",
		RJ801436: "Convênio ICMS 15 de 2021 - Isenção",
		RJ801437: "Convênio ICMS 13 de 2021 - Isenção",
		RJ801438: "Convênio ICMS 224 de 2017 - Isenção",
		RJ818439: "Convênio ICMS 17 de 2013 - Diferimento",
		RJ802440: "Lei 5.147 de 2007 - Redução de Base de Cálculo",
		RJ818441: "Lei 9.214 de 2021 - Diferimento",
		RJ801441: "Lei 9.214 de 2021 - Isenção",
		RJ818442: "Lei 9.289 de 2021 - Diferimento",
		RJ805443: "Lei 9.355 de 2021 - Crédito Presumido",
		RJ820444: "Lei 2.657/1996, art.14, inciso VI, alínea a.1 - Redução de alíquota",
		RJ801445: "Convênio ICMS 76/1991- Isenção",
		RJ801446: "Convênio ICMS 12/2022- Isenção",
		RJ808446: "Convênio ICMS 12/2022- Ampliação de prazo de pagamento",
		RJ802447: "Convênio ICMS 112/2013- Redução de Base de Cálculo",
		RJ803428: "Convenio ICMS 12 de 1975 - Inexigibilidade de estorno de crédito",
		RJ820449: "Decreto  45.607 de 2016 - art. 4° - Redução de alíquota",
		RJ802450: "Decreto 45.446, art 4 de 2015 -Redução de Base de Cálculo",
		RJ823451: "Protocolo ICMS 2 de 2014 - Suspensão",
		RJ823452: "Protocolo ICMS 5 de 2014 - Suspensão",
		RJ823453: "Protocolo ICMS 64 de 2015 - Suspensão",
		RJ820454: "Lei 2.657/1996, art. 14, inciso XV de 1996 - Redução de alíquota",
		RJ823455: "Protocolo ICMS 28 de 2008 - Suspensão",
		RJ823456: "Protocolo ICMS 4 de 2011 - Suspensão",
		RJ801457: "Convênio ICMS 88 de 2012 - Isenção",
		RJ820458: "Lei 5.147, art. 2 de 2007 - Redução de alíquota",
		RJ802459: "Convênio ICMS 130 de 1994 - Redução de Base de Cálculo",
	},
	MT: {
		MT001155:
			"Redução de base de cálculo a 41,18% do valor da operação no fornecimento de refeições por bares, restaurantes e similares. Art. 7º Anexo V - RICMS/2014.",
	},
	MG: {
		MG009999: "Apuração do ICMS; Outros débitos para ajuste de apuração ICMS;",
		MG010001:
			"Apuração do ICMS; Estorno de créditos; determinados por ato administrativo ou judicial.",
		MG010002: "Apuração do ICMS; Estorno de créditos por opção pelo Simples Nacional",
		MG019999: "Apuração do ICMS; Estorno de créditos para ajuste de apuração ICMS.",
		MG020001:
			"Apuração do ICMS; Outros créditos; determinados por ato administrativo ou judicial.",
		MG020002:
			"Apuração do ICMS; Outros créditos; referentes ao Valor total do crédito presumido.",
		MG020004:
			"Apuração do ICMS; Outros créditos; referente à apropriação de crédito do CIAP",
		MG020005:
			"Apuração do ICMS; Outros créditos; Crédito do imposto corretamente destacado em documento fiscal, previamente escriturado, e não aproveitado na época própria",
		MG029999: "Apuração do ICMS; Outros créditos para ajuste de apuração ICMS.",
		MG030001:
			"Apuração do ICMS; Estorno de débitos; determinados por ato administrativo ou judicial.",
		MG030002:
			"Apuração do ICMS; Estorno de débitos; referente ao CTRC emitido pelo transportador, com ICMS de responsabilidade do remetente/alienante.",
		MG030003:
			"Apuração do ICMS; Estorno de débitos; lançamento efetuado em decorrência de emissão de documento fiscal relativo à operação também registrada em ECF",
		MG030004: "Apuração do ICMS; Estorno de débitos; Serviço de Comunicação",
		MG039999: "Apuração do ICMS; Estorno de débitos para ajuste de apuração ICMS.",
		MG040001:
			"Apuração do ICMS; Dedução do imposto apurado; Utilização de crédito transferido, limitado a 30% do débito.",
		MG040002:
			"Apuração do ICMS; Dedução do imposto apurado; Transferência entre 2 estabelecimentos do mesmo titular.",
		MG040003: "Apuração do ICMS; Dedução do imposto apurado; Incentivo fiscal à cultura",
		MG040004: "Apuração do ICMS; Dedução do imposto apurado; Incentivo fiscal ao esporte",
		MG040005:
			"Apuração do ICMS; Dedução do imposto apurado; Incentivo à pontualidade do recolhimento de tributos",
		MG040006:
			"Apuração do ICMS; Dedução do imposto apurado; Utilização de crédito acumulado, limitado a 30% do débito - e-commerce.",
		MG040007:
			"Apuração do ICMS; Dedução do imposto apurado; Incentivo fiscal a cultura limitado a 5%. Parágrafo 3º, Art. 118, Decreto nº 48.819/2024",
		MG050000:
			"Apuração do ICMS; Débito Especial; ICMS Diferença de Alíquota apurado em verificação fiscal.",
		MG050001:
			"Apuração do ICMS; Débito Especial; ICMS Diferença de Alíquota apurado após o vencimento.",
		MG050007:
			"Apuração do ICMS; Débito Especial; Fundo de Erradicação da Miséria - FEM - Antecipado",
		MG050008:
			"Apuração do ICMS; Débito Especial; Recolhimento complementar - Regime Especial.",
		MG050099: "Apuração do ICMS; Débito Especial; ICMS Diferença de Alíquota - outros",
		MG050100:
			"Apuração do ICMS; Débito Especial; ICMS de importação apurado por verificação fiscal.",
		MG050101:
			"Apuração do ICMS; Débito Especial; ICMS de importação pago no período, com emissão de Nota de entrada no período posterior.",
		MG050199: "Apuração do ICMS; Débito Especial; ICMS de importação - outros",
		MG050200:
			"Apuração do ICMS; Débito Especial; ICMS referente ao diferimento, apurado por verificação fiscal.",
		MG050201:
			"Apuração do ICMS; Débito Especial; ICMS referente ao diferimento, apurado após o vencimento.",
		MG050299: "Apuração do ICMS; Débito Especial; ICMS referente a diferimento - outros",
		MG050300:
			"Apuração do ICMS; Débito Especial; ICMS referente à Antecipação tributária, apurado por verificação fiscal.",
		MG050301:
			"Apuração do ICMS; Débito Especial; ICMS referente à Antecipação tributária, apurado após o vencimento.",
		MG050399:
			"Apuração do ICMS; Débito Especial; ICMS referente à Antecipação tributária - outros",
		MG050400:
			"Apuração do ICMS; Débito Especial; ICMS destacado a menor, apurado em verificação fiscal.",
		MG050401:
			"Apuração do ICMS; Débito Especial; ICMS destacado a menor, apurado após o vencimento.",
		MG059998: "Apuração do ICMS; Débito Especial; ICMS - Outros.",
		MG091001: "Apropriação de crédito por exportação - detentor original",
		MG091002: "Apropriação de crédito por saída com diferimento",
		MG091003: "Apropriação de crédito por saída com redução de Base de Cálculo",
		MG091004:
			"Apropriação de crédito por saída com alíquota inferior à alíquota de entrada",
		MG091008: "Apropriação de crédito por expansão / instalação industrial",
		MG091009: "Apropriação de crédito por formação de estoque",
		MG091010: "Apropriação de crédito por mudança de forma de tributação",
		MG091011: "Apropriação de crédito – Acúmulo de crédito do CIAP",
		MG091012: "Apropriação de crédito por aquisição de serviços tributados pelo ICMS",
		MG091013: "Apropriação de crédito por recolhimento a maior do ICMS",
		MG091015:
			"Apropriação de crédito por restituição de ICMS ST mediante creditamento ou abatimento, observado o limite de 30% (trinta por cento) do saldo devedor do ICMS OP ou ST apurado.",
		MG091017: "Controle de Crédito Aprovado no DCA – Exportação",
		MG091018:
			"Controle de Crédito Aprovado no DCA – Outros Créditos (Diferimento e Redução de Base de Cálculo)",
		MG092001: "Apropriação de crédito por Incentivo à Cultura",
		MG092002: "Apropriação de crédito por Incentivo ao Esporte",
		MG092003: "Apropriação de crédito extra-apuração por ato administrativo/judicial",
		MG092004:
			"Apropriação de crédito – Saldo credor apurado no período do FEM Operações Próprias",
		MG092005:
			"Apropriação de crédito – Saldo credor apurado no período do FEM Substituição Tributária",
		MG092006:
			"Apropriação de crédito – Saldo credor apurado em período anterior ao mês de início da vigência de Regime Especial",
		MG092007: "Apropriação de crédito – Crédito outorgado",
		MG092008:
			"Apropriação de crédito por Incentivo à Cultura limitado a 5%. Parágrafo 3º, Art. 118, Decreto nº 48.819/2024",
		MG095001: "Crédito recebido por transf. de detentor de crédito de exportação",
		MG095002: "Crédito recebido por transf. em pagamento de cotas / ações",
		MG095003: "Crédito recebido por transf. para instalação /  expansão",
		MG095004:
			"Crédito recebido por transf. em pagamento de aquisição de mercadorias / ativo imobilizado",
		MG095005: "Crédito recebido por transf. para pagamento de crédito tributário",
		MG095006: "Crédito recebido por transf. para pagamento de ICMS de importação",
		MG095007: "Crédito recebido por transf. para abatimento de até 30% do saldo devedor",
		MG095009:
			"Crédito recebido por transf. para abatimento do saldo devedor sem limitação",
		MG109998:
			"Apuração do ICMS ST; Outros débitos para ajuste de apuração ICMS ST; Outros débitos para ajuste de apuração ICMS monofásico.",
		MG109999: "Apuração do ICMS ST; Outros débitos para ajuste de apuração ICMS ST.",
		MG119998:
			"Apuração do ICMS ST; Estorno de créditos para ajuste de apuração ICMS ST; Estorno créditos para ajuste de apuração ICMS monofásico.",
		MG119999: "Apuração do ICMS ST; Estorno de créditos para ajuste de apuração ICMS ST.",
		MG120001: "Apuração do ICMS ST; Outros créditos; Devolução",
		MG120009:
			"Apuração do ICMS ST; Outros créditos; Fundo de Erradicação da Miséria - FEM Extemporâneo",
		MG120010: "Apuração do ICMS ST; Outros créditos; Desfazimento ST CST10 - RE",
		MG120011: "Apuração do ICMS ST; Outros créditos; Desfazimento ST CST60 - RE",
		MG120012: "Apuração do ICMS ST; Outros créditos; Restituição de ST - RPTA",
		MG120013:
			"Apuração do ICMS ST; Outros créditos; Outros créditos; ICMS monofásico. Repasse para outras UFs.",
		MG120014: "Apuração do ICMS ST; Outros créditos; ICMS monofásico. Crédito presumido.",
		MG129998:
			"Apuração do ICMS ST; Outros créditos; Outros créditos para ajuste de apuração ICMS monofásico.",
		MG139998:
			"Apuração do ICMS ST; Estorno de débitos; Estorno débitos para ajuste de apuração ICMS monofásico.",
		MG139999: "Apuração do ICMS ST; Estorno de débitos para ajuste de apuração ICMS ST.",
		MG149999: "Apuração do ICMS ST; Deduções do imposto apurado na apuração ICMS ST.",
		MG150000:
			"Apuração do ICMS ST; Débito Especial; ICMS Diferença de Alíquota apurado em verificação fiscal.",
		MG150001:
			"Apuração do ICMS ST; Débito Especial; ICMS ST de Diferença de Alíquota apurado após o vencimento.",
		MG150099:
			"Apuração do ICMS ST; Débito Especial; ICMS ST de Diferença de Alíquota - outros",
		MG150101:
			"Apuração do ICMS ST; Débito Especial; ICMS ST de importação pago no período, com emissão de Nota de entrada no período posterior.",
		MG150399: "Apuração do ICMS ST; Débito Especial; Recolhimento Antecipado",
		MG150400:
			"Apuração do ICMS ST; Débito Especial; ICMS ST destacado a menor, apurado em verificação fiscal.",
		MG150401:
			"Apuração do ICMS ST; Débito Especial; ICMS ST destacado a menor, apurado após o vencimento.",
		MG150500:
			"Apuração do ICMS ST; Débito Especial; ICMS ST interna, apurado por verificação fiscal.",
		MG150501:
			"Apuração do ICMS ST; Débito Especial; ICMS ST interna, apurado após o vencimento.",
		MG150599: "Apuração do ICMS ST; Débito Especial; ICMS ST interna - outros",
		MG150600:
			"Apuração do ICMS ST; Débito Especial; ICMS ST de transporte, apurado por verificação fiscal.",
		MG150601:
			"Apuração do ICMS ST; Débito Especial; ICMS ST de transporte, apurado após o vencimento.",
		MG150699: "Apuração do ICMS ST; Débito Especial; ICMS ST de transporte - outros",
		MG159998:
			"Apuração do ICMS ST; Débito Especial; Débito especial para ajuste de apuração ICMS monofásico.",
		MG159999: "Apuração do ICMS ST; Débito Especial; ICMS ST - Outros.",
		MG210002: "Estorno de crédito DIFAL Destino MG – devolução – processo de restituição",
		MG230002: "Estorno de Débito DIFAL Destino MG – recolhimento por operação",
		MG250001:
			"Débito Especial - Débito do ICMS Interestadual apurado devido a MG - ECF autorizado por MG em outra UF – Conv.ICMS 84/2001",
		MG250002: "Débito Especial de ICMS DIFAL Destino MG – recolhimento por operação",
		MG309999: "Outros débitos para ajuste de apuração FCP para MG",
		MG310003: "Estorno de crédito FCP MG – devolução – processo de restituição",
		MG319999: "Estorno de créditos para ajuste de apuração FCP para MG",
		MG329999: "Outros créditos para ajuste de apuração FCP para MG",
		MG330003: "Estorno de Débito FCP MG – recolhimento por operação",
		MG339999: "Estorno de débitos para ajuste de apuração FCP para MG",
		MG349999: "Deduções do imposto apurado na apuração FCP para MG",
		MG350003: "Débito Especial de FCP MG – recolhimento por operação",
		MG359999: "Débito especial de FCP para MG",
	},
};

export interface EventInvoiceIssuingHooks {
	setProgress?: (progress: number) => void;
	notifyError?: (message: string) => void;
}

interface EventInvoiceIssuingOptions extends EventInvoiceIssuingHooks {
	eventId: string;
	email?: string;
}

export const issueEventInvoicesDebouced = debounce(issueEventInvoices, 2000, {
	leading: true,
});

async function issueEventInvoices({
	eventId,
	notifyError = () => {},
	setProgress = () => {},
}: EventInvoiceIssuingOptions) {
	setProgress(0);

	const clients = await enterprise.getClientsAtEvent({
		eventId,
		options: { invoicesNotIssued: null },
	});
	let clientCount = 0;

	const errorSet: Record<string, number> = {};

	function registerError(error: string) {
		errorSet[error] = (errorSet[error] || 0) + 1;
	}

	function incrementClients() {
		clientCount++;
		setProgress(clientCount / clients.length);
	}

	const promiseGenerators = clients.map(client => () =>
		issueInvoicesForUser({
			eventId,
			preventEmail: true,
			userId: client.id,
		})
			.then(async res => {
				incrementClients();

				res.errors.forEach(registerError);

				return res;
			})
			.catch(err => {
				incrementClients();
				registerError("A tentativa de emissão não chegou a ser concluída");
				console.error(err);
				return null;
			}),
	);

	const poolSize = 5;
	const results = await parallelLimit(promiseGenerators, poolSize);

	setProgress(1);

	for (const [error, count] of Object.entries(errorSet)) {
		notifyError(`${error} (apareceu ${count} ${count === 1 ? "vez" : "vezes"})`);
	}

	return results.reduce<IssueResult>(
		(acc, el) => {
			if (!el) {
				return acc;
			} else {
				return {
					errors: [...acc.errors, ...el.errors],
					failedInvoiceCount: acc.failedInvoiceCount + el.failedInvoiceCount,
					issuedInvoices: [...acc.issuedInvoices, ...el.issuedInvoices],
				};
			}
		},
		{
			errors: [],
			failedInvoiceCount: 0,
			issuedInvoices: [],
		},
	);
}

async function issueInvoicesForUser({
	eventId,
	userId,
	email,
	preventEmail,
}: {
	eventId: string;
	userId: string;
	email?: string;
	preventEmail?: boolean;
}) {
	const results = await enterprise.issueInvoicesForUser({ eventId, userId });

	if (!preventEmail && results.issuedInvoices.length > 0) {
		await enterprise.sendInvoicesByEmail({
			email: email || null,
			invoiceIds: results.issuedInvoices.map(inv => inv.id),
		});
	}

	return results;
}
