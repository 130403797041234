export const navPT = {
	header: {
		event: "Evento",
		logout: "Sair",
		org: "Empresa",
		place: "Local",
		language: "Linguagem",
	},
	navbar: {
		event: {
			customers: "Clientes",
			barReports: "Relatórios de Bar",
			eventPanel: "Painel do evento",
			reports: "Relatórios",
			crm: "CRM",
			receptive: "Receptivo",
			bookings: "Pacotes",
			cardsGroup: "Grupo de Cartões",
			clients: "Informações de Clientes",
			conference: "Conferência de Evento",
			financialReport: "Relatórios Financeiros",
			guestList: "Listas de Convidados",
			multivendor: "Multi-vendor",
			panel: "Painel",
			devices: "Dispositivos",
			sellChip: "Vendas sem cartão",
			ticketsReport: "Relatórios de Bilheteria",
			zigTag: "Zig Tag",
			operationReport: "Relatório de Operação",
			hawkersReport: "Relatório de Ambulantes",
			tableReports: "Relatórios de Mesa",
			rechargeManagement: "Gestão de Saldo",
			generalConfig: "Configuração Geral",
		},
		default: {
			logout: "Sair",
			changeLanguage: "Alterar idioma",
			changeSystemLanguage: "Alterar idioma do sistema",
		},
		selectLanguageModal: {
			selectLanguage: "Selecione o idioma",
			cancel: "Canelar",
			confirm: "Confirmar",
		},
		org: {
			panel: "Locais",
			employee: "Acessos da equipe",
			crm: "CRM",
			categories: "Categorias",
			reports: "Relatórios",
			recharge: "Recarga",
			stock: "Estoque",
			giftback: "Giftback",
			fiscal: "Fiscal",
			taxRegistration: "Cadastro fiscal",
			productRegistration: "Cadastro de Produto",
			advancedBI: "BI Avançado",
			customersList: "Lista de Clientes",
			stockInventory: "Cadastro Base de Produtos",
			taxData: "Dados Fiscais",
			fiscalSettings: "Dados Fiscais",
			financialReport: "Relatórios",
			satisfactionSurvey: "Pesquisa de Satisfação",
			customers: "Área do Cliente",
			sendingMessages: "Envio de Mensagens",
			loyalty: "Fidelização",
			communication: "Campanhas",
			chartOfAccounts: "Plano de Contas",
			vendors: "Vendor",
			team: "Equipe",
		},
		place: {
			events: "Eventos",
			businessInteligence: "Business Intelligence",
			pdvTeam: "Operadores de PDV",
			products: "Produtos",
			opened: "Em aberto",
			closed: "Em aberto",
			crm: "CRM",
			reports: "Relatórios",
			preChargeLabel: "Pré-carga",
			inventoryControl: "Controle de Estoque",
			receptive: "Receptivo",
			productAndMenu: "Produtos & Cardápios",
			menu: "Cardápio",
			bars: "Bares",
			clients: "Clientes",
			tagGroups: "Grupo de Tags",
			reserve: "Reserva e Fila de Espera",
			tickets: "Entradas",
			guestList: "Lista de Convidados",
			financialReport: "Relatórios Financeiros",
			barReports: "Relatórios de Bar",
			tableReports: "Relatórios de Mesa",
			cashFlow: "Financeiro",
			delivery: "Delivery",
			prechargeReports: "Pré-Carga por planilha",
			ticketsReport: "Relatórios de Bilheteria",
			storage: "Estoque",
			storageReports: "Relatórios de Estoque",
			extract: "Extrato",
			invoices: "Fiscal",
			transferReport: "Relatório de Repasses",
			multivendor: "Multi-vendor",
			precharge: "Sites de Pré-Carga",
			whatsup: "Qual é a Boa?",
			passwordCard: "Cartão de senhas",
			audits: "Auditoria",
			pos: "Vendas POS",
			promotions: "Promoções e Descontos",
			requestDevices: "Minhas Solicitações",
			settings: "Configurações",
			zigAccount: "Zig Conta",
			contracts: "Contratos",
			sellChip: "Vendas sem cartão",
			bookings: "Pacotes",
			devices: "Dispositivos",
			zigTag: "Zig Tag",
			operationReport: "Relatório de Operação",
			hawkersReport: "Relatório de Ambulantes",
			rechargeManagement: "Gestão de Saldo",
			generalConfig: "Configuração Geral",
			config: "Configurações",
		},
	},
};
