import { consolidated } from "./consolidated";
import { financial } from "./financial";
import { withdraws } from "./withdraws";
import { invoices } from "./invoices";
import { devolution } from "./devolution";
import { adjustments } from "./adjustments";
import { accounts } from "./accounts";
import { flow } from "./flow";
import { anticipationDrawer } from "./anticipation-drawer";
import { withdrawRequestDrawer } from "./withdraw-request-drawer";
import { errorsValidations } from "./errors-validations";
import { transferForm } from "./transfer-form";
import { withdrawConfirmation } from "./withdraw-confirmation";
import { withdrawRequestDetails } from "#i18n/translations/pt-BR/place/extract/withdraw-request-details";
import { debitNotes } from "./debit-notes";
import { locationNotes } from "./location-notes";

export const extract = {
	accounts,
	actionLabelAnticipate: "Solicitar antecipação e pagamento de boleto",
	actionLabelBlocked: "Realizar saque / Pagar boleto",
	availableToWithdraw: "Disponível para saque",
	owedToZig: "Saldo devido ao ZIG",
	vendorOwned: "Devido a vendors",
	vendors: "Vendors",
	totalBalance: "Saldo total",
	totalBalanceAnticipation: "Saldo total para antecipação",
	valueAvailableToAnticipate: "Valor disponível para antecipação",
	availableToAnticipate: "Disponível para antecipação",
	actionLabelWithdraw: "Solicitar saque, pagamento de boleto ou transferência",
	adjustments,
	anticipationDrawer,
	availableInMoreThanOneMonth: "Valor a ser liberado em mais de 32 dias",
	availableInTwoDays: "Saldo a ser liberado nos próximos 2 dias",
	availableMoreThanOneMonth: "Valor disponível em mais de 30 dias",
	withdrawRequestDrawer,
	errorsValidations,
	transferForm,
	withdrawConfirmation,
	bankSlipForm: {
		error: "Não foi possível concluir a solicitação.",
		request: "Solicitar",
		success: "Pagamento de boleto solicitado com sucesso!",
		tryAgain: "Tente novamente",
		warningBanner: {
			description:
				"Solicitações realizadas após às 10h30 (horário de Brasília) só serão atendidas no dia seguinte.",
			title: "Atenção",
		},
	},
	bankslipAvailable: "Boleto disponível",
	consolidated,
	devolution,
	dueDate_Abrev: "Venc",
	excelButton: "Exportar para Excel",
	financial,
	flow,
	hooks: {
		accountData: "Informações da conta",
		summary: "Revisão dos dados",
		value: "Valor da antecipação",
	},
	invoices,
	lastUpdated: "atualizado há {{lastUpdated}}",
	paidToZig: "Valor a ser pago ao ZIG",
	paidToZigMoreThanOneMonth: "Valor a ser pago ao ZIG em mais de 30 dias",
	period: "Período",
	tabs: {
		accounts: "Contas salvas",
		adjustments: "Ajustes",
		consolidated: "Consolidado",
		devolution: "Recargas expiradas",
		financial: "Extrato diário",
		flow: "Extrato-Fluxo de caixa",
		withdraws: "Saques, antecipações e boletos",
		invoices: "Notas Fiscais",
		debitNotes: "Notas de débito",
		locationNotes: "Notas de Locação",
		automaticWithdraw: "Saque automático",
	},
	title: "Extrato",
	withdrawRequestDetails,
	withdraws,
	debitNotes,
	locationNotes,
	retainedBalance: "Saldo retido",
	twentyPercentRetention: "Retenção de 20% dos valores dos últimos 7 dias",
	totalRetained: "Total retido",
	relatedPartDataTooltip: "Dados especificados a partir do dia 21/05/2024",
	automaticWithdraw: {
		type: "Tipo",
		vendorName: "Nome do vendor",
		expectedAmount: "Valor do saque programado",
		placeBalance: "Saldo do place",
		actualAmount: "Valor sacado",
		status: "Status",
		vendor: "Vendor",
		place: "Place",
		error: "Erro",
		success: "Sucesso",
		partial: "Parcial",
	},
};
