import React, { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const DEFAULT_STALE_TIME = 1000 * 30; // 30 seconds
const queryClient = new QueryClient({
	defaultOptions: { queries: { staleTime: DEFAULT_STALE_TIME } },
});

interface IReactQueryProvider {
	children: ReactNode;
}

export function ReactQueryProvider({ children }: IReactQueryProvider) {
	return (
		<QueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}
