import React, { ReactNode, useEffect } from "react";
import s from "./menu-item.module.scss";
import classNames from "classnames";
import { Collapse } from "#components/collapse";
import Icon from "#components/icon";
import { TagLabel, Tooltip, TypeTagLabel } from "#components/primitive";
import { Link } from "react-router";
import { MinifiedPlaceEventType } from "#components/nav-bars/use-active-events";
import { RunningEventsBadge } from "../running-events-badge";
import { TagTypeStyle } from "../types";
import { useTranslation } from "react-i18next";

interface SubMenuItem {
	label: string;
	url: string;
	alternativeUrls?: string[];
}

export type MenuItemProps = {
	icon: ReactNode;
	label: string;
	url?: string;
	subMenuItems?: SubMenuItem[];
	showRunningEvents?: boolean;
	runningEvents?: MinifiedPlaceEventType[];
	isOpenSandwichMenu: boolean;
	onSelectMenu?: (url: string | null) => void;
	selectedMenu: string | null;
	disabled?: boolean;
	className?: string;
	tagType?: TagTypeStyle;
	isSubMenuOpen?: boolean;
	onToggleSubMenu?: (forcedIndexValue?: number) => void;
} & Pick<SubMenuItem, "alternativeUrls">;

export const MenuItem = ({
	icon,
	label,
	url,
	subMenuItems,
	runningEvents,
	showRunningEvents,
	isOpenSandwichMenu: menuIsExpanded,
	disabled,
	selectedMenu,
	onSelectMenu,
	className,
	tagType,
	alternativeUrls,
	isSubMenuOpen,
	onToggleSubMenu,
}: MenuItemProps) => {
	const hasSubItems = subMenuItems && subMenuItems.length > 0;
	const runningEventsCount = runningEvents?.length ?? 0;
	const hasRunningEvents = runningEventsCount > 0;
	const hasSomeSubitemSelected = React.useMemo(() => {
		const result = Boolean(
			hasSubItems &&
				subMenuItems.some(
					subItem =>
						subItem.url === selectedMenu ||
						(selectedMenu && subItem.alternativeUrls?.includes(selectedMenu)),
				),
		);

		return result;
	}, [hasSubItems, subMenuItems, selectedMenu]);

	const isCurrentActiveMenu = React.useMemo(() => {
		const result = Boolean(
			hasSomeSubitemSelected ||
				(!hasSubItems &&
					selectedMenu &&
					(url === selectedMenu || alternativeUrls?.includes(selectedMenu))),
		);

		return result;
	}, [
		hasSubItems,
		url,
		selectedMenu,
		alternativeUrls,
		hasSomeSubitemSelected,
		subMenuItems,
	]);

	const { t } = useTranslation("globalKeys");
	const { t: tNav } = useTranslation("nav", { keyPrefix: "navbar.place" });

	const resetSubmenuExpanded = () => {
		onToggleSubMenu?.(-1);
	};

	useEffect(() => {
		resetSubmenuExpanded();
	}, [url]);

	useEffect(() => {
		if (!menuIsExpanded) {
			resetSubmenuExpanded();
		}
	}, [menuIsExpanded]);

	const handleSelectMenu = (menuUrl?: string, isSubmenu?: boolean) => {
		if (!isSubmenu) {
			if (hasSubItems && !isSubMenuOpen) {
				onToggleSubMenu?.();
				return;
			}

			if ((hasSubItems && isSubMenuOpen) || !hasSubItems) {
				onToggleSubMenu?.(-1);
				onSelectMenu?.(menuUrl ?? "");
				return;
			}

			if (!hasSubItems) {
				onSelectMenu?.(menuUrl ?? "");
			}

			return;
		}

		if (!menuUrl) {
			onToggleSubMenu?.();

			return;
		}

		onSelectMenu?.(menuUrl);
		onToggleSubMenu?.();
	};

	const getSubItems = (items: SubMenuItem[], isRunningEvents?: boolean) => {
		return (
			<ul className={s.submenuItemsContainer}>
				{items.map(subItem => {
					return (
						<li
							className={classNames({
								[s.submenuActive]:
									selectedMenu &&
									(subItem.url === selectedMenu ||
										subItem.alternativeUrls?.includes(selectedMenu)),
							})}
							aria-label={subItem.label}
							key={`subItem${label}-${subItem.label}`}
						>
							<Link
								to={subItem.url}
								onClick={() => {
									handleSelectMenu(subItem.url, true);
								}}
							>
								<label
									aria-hidden={(!menuIsExpanded || !isSubMenuOpen).valueOf()}
									title={subItem.label}
									aria-label={subItem.label}
								>
									{subItem.label}
								</label>

								{isRunningEvents && (
									<Icon className={s.submenuChevronIcon} icon="chevron-right" />
								)}
							</Link>
						</li>
					);
				})}
			</ul>
		);
	};

	const showExistingRunningEvents = Boolean(hasRunningEvents && showRunningEvents);

	const tooltipContent = !showRunningEvents ? null : (
		<div className={s.tooltipContainer}>
			<header>
				<label title={label}>{showExistingRunningEvents ? tNav("events") : label}</label>

				{showExistingRunningEvents && <RunningEventsBadge count={runningEventsCount} />}
			</header>

			{showExistingRunningEvents && (
				<>
					<hr />

					{getSubItems(
						(runningEvents ?? []).map(placeEvent => ({
							label: placeEvent.event.name,
							url: `/place/${placeEvent.place.id}/event/${placeEvent.event.id}`,
						})),
						true,
					)}
				</>
			)}
		</div>
	);

	const tagTypeLabel: Record<TagTypeStyle, TypeTagLabel> = {
		new: "success",
		comingSoon: "warning",
		pending: "info",
	};

	const TagComponent = hasSubItems ? "div" : Link;

	return (
		<li
			aria-disabled={disabled}
			aria-selected={isCurrentActiveMenu}
			aria-label={label}
			className={classNames(
				s.menuItem,
				{
					[s.menuIsExpanded]: menuIsExpanded,
					[s.active]: isCurrentActiveMenu,
				},
				className,
			)}
		>
			<Tooltip
				placement="rightTop"
				trigger={"hover"}
				destroyTooltipOnHide
				showArrow={!showRunningEvents}
				content={tooltipContent}
				overlayInnerStyle={{
					maxWidth: 322,
					minWidth: showRunningEvents && hasRunningEvents ? 200 : undefined,
					backgroundColor: "var(--color-dark-d2)",
					color: "var(--color-light-l5)",
					borderRadius: "var(--border-radius-md)",
				}}
			>
				<>
					<TagComponent
						to={!hasSubItems && url ? url : ""}
						onClick={() => {
							handleSelectMenu(url);
						}}
						className={s.menuItemContent}
					>
						{icon}

						<label title={label}>
							{label}

							{tagType && (
								<TagLabel className={s.tagType} type={tagTypeLabel[tagType]}>
									{t(tagType)}
								</TagLabel>
							)}
						</label>

						{hasSubItems && <Icon className={s.collapseIcon} icon="short-arrow-down" />}

						{!hasSubItems && showExistingRunningEvents && (
							<RunningEventsBadge
								className={s.runningEventsBadge}
								count={runningEventsCount}
								hideCounter={!menuIsExpanded}
								hideLabel
							/>
						)}
					</TagComponent>

					{hasSubItems && (
						<Collapse isOpened={Boolean(menuIsExpanded && isSubMenuOpen)}>
							{getSubItems(subMenuItems)}
						</Collapse>
					)}
				</>
			</Tooltip>
		</li>
	);
};
