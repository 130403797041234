import * as React from "react";
import { privateRoutes, Routes } from "#structure/route-management/routes";
import TagManager, { TagManagerArgs } from "react-gtm-module";
import { isMain } from "#configuration/config";
import "@mds/react-components/style";

import * as Sentry from "@sentry/react";
import * as Router from "react-router";

const getCurrentISODate = () => {
	const date = new Date();
	return date.toISOString().split("T")[0];
};

Sentry.init({
	// TODO change this name to SENTRY_DSN
	dsn: process.env.SENTRY_DNS,
	enabled: isMain,
	release: `dash@${__webpack_hash__}@${getCurrentISODate()}`,
	integrations: [
		Sentry.reactRouterV3BrowserTracingIntegration({
			history: Router.browserHistory,
			routes: Router.createRoutes(privateRoutes),
			match: Router.match,
		}),
	],
	ignoreErrors: [
		/ResizeObserver loop completed/i,
		"Script https://dashboard.zigpay.com.br/sw.js load failed",
	],
	tracesSampleRate: 1.0,
	beforeSend(event, hint) {
		const error = hint.originalException;

		if ((error as Error)?.stack?.match(/SdkgenError/i)) {
			return null;
		}

		// Personalizar título do erro
		event.message = `Error in ${
			window.location.pathname + (window.location.hash ?? "")
		}: ${(hint.originalException as any)?.message ?? ""}`;

		return event;
	},
});

export function App() {
	const tagManagerArgs: TagManagerArgs = {
		gtmId: "GTM-WH5FFX3Q",
		dataLayer: {
			originalLocation: document.location.pathname + document.location.search,
		},
	};

	React.useEffect(() => {
		if (isMain) {
			TagManager.initialize(tagManagerArgs);
		}
	}, []);

	return <Routes />;
}
